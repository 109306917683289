@font-face {
    font-family: 'Uni Neue Regular';
    src: local('../fonts/Uni Neue Regular'), local('Uni-Neue-Regular'),
        url('../fonts/UniNeueRegular.woff2') format('woff2'),
        url('../fonts/UniNeueRegular.woff') format('woff'),
        url('../fonts/UniNeueRegular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy Regular';
    src: url('../fonts/Gilroy-Regular.eot');
    src: url('../fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-Regular.woff') format('woff'),
    url('../fonts/Gilroy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy Semibold';
    src: url('../fonts/Gilroy-SemiBold.eot');
    src: url('../fonts/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-SemiBold.woff') format('woff'),
    url('../fonts/Gilroy-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy Bold';
    src: url('../fonts/Gilroy-Bold.eot');
    src: url('../fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-Bold.woff') format('woff'),
    url('../fonts/Gilroy-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Athelas Bold';
    src: url('../fonts/Athelas-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}



