@charset "UTF-8";
*, *::before, *::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }

a {
  color: inherit;
  text-decoration: none; }
  a:hover {
    color: inherit;
    text-decoration: none; }

ul {
  list-style: none; }

h1, h2, p, ul {
  margin-bottom: 0; }

input, button {
  -webkit-appearance: none;
  border: none;
  border-radius: 0; }
  input:focus, button:focus {
    outline: none;
    box-shadow: none !important; }

body.overflow-active {
  overflow: hidden; }

@font-face {
  font-family: 'Uni Neue Regular';
  src: local("../fonts/Uni Neue Regular"), local("Uni-Neue-Regular"), url("../fonts/UniNeueRegular.woff2") format("woff2"), url("../fonts/UniNeueRegular.woff") format("woff"), url("../fonts/UniNeueRegular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Gilroy Regular';
  src: url("../fonts/Gilroy-Regular.eot");
  src: url("../fonts/Gilroy-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Gilroy-Regular.woff") format("woff"), url("../fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Gilroy Semibold';
  src: url("../fonts/Gilroy-SemiBold.eot");
  src: url("../fonts/Gilroy-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Gilroy-SemiBold.woff") format("woff"), url("../fonts/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Gilroy Bold';
  src: url("../fonts/Gilroy-Bold.eot");
  src: url("../fonts/Gilroy-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Gilroy-Bold.woff") format("woff"), url("../fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Athelas Bold';
  src: url("../fonts/Athelas-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

.header__row {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 15px 0; }
  @media only screen and (max-width: 768px) {
    .header__row {
      padding: 11px 0; } }
  @media only screen and (max-width: 1199px) {
    .header__row {
      flex-wrap: wrap; } }
  .header__row .left {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 1199px) {
      .header__row .left {
        margin: 0 auto; } }
  .header__row .right {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 1199px) {
      .header__row .right {
        margin: 15px auto 0; } }
    @media only screen and (max-width: 768px) {
      .header__row .right {
        flex-wrap: wrap;
        justify-content: center;
        margin: 11px auto 0; } }
    @media only screen and (max-width: 576px) {
      .header__row .right {
        display: none; } }
    @media only screen and (max-width: 480px) {
      .header__row .right {
        margin: 0 auto; } }
    .header__row .right .btn {
      margin-left: 50px; }
      @media only screen and (max-width: 768px) {
        .header__row .right .btn {
          margin: 5px auto 0; } }

.header__name-and-description {
  margin-left: 20px; }

.header__name {
  font-family: "Gilroy Bold";
  font-size: 18px;
  line-height: 22px;
  color: #161915; }
  .header__name span {
    color: #366437; }

.header__description {
  font-size: 14px;
  color: #161915;
  font-family: "Gilroy Regular";
  max-width: 265px; }

.header__phones-and-shedule {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
  @media only screen and (max-width: 480px) {
    .header__phones-and-shedule {
      width: 100%;
      flex-direction: column;
      align-items: center; } }

.header__phones {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  font-family: "Gilroy Regular"; }
  @media only screen and (max-width: 480px) {
    .header__phones {
      flex-wrap: wrap;
      justify-content: center; } }
  .header__phones-code {
    font-family: "Gilroy Semibold";
    color: #366437; }
  .header__phones ul {
    display: flex;
    margin-left: 15px; }
    @media only screen and (max-width: 480px) {
      .header__phones ul {
        margin: 0;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        text-align: center; } }
    @media only screen and (max-width: 480px) {
      .header__phones ul li {
        width: 100%; } }
    .header__phones ul li:not(:first-child) {
      margin-left: 25px;
      position: relative; }
      @media only screen and (max-width: 480px) {
        .header__phones ul li:not(:first-child) {
          margin-left: 0; } }
      .header__phones ul li:not(:first-child)::before {
        content: "/";
        position: absolute;
        left: -17px; }
        @media only screen and (max-width: 480px) {
          .header__phones ul li:not(:first-child)::before {
            display: none; } }

.header__shedule {
  text-align: right;
  font-size: 16px;
  line-height: 24px;
  font-family: "Gilroy Regular";
  color: #161915;
  background-repeat: no-repeat;
  background-image: url(../img/elements/icon-clock.png);
  background-position: left center;
  padding-left: 20px; }
  @media only screen and (max-width: 480px) {
    .header__shedule {
      text-align: center; } }
  .header__shedule span {
    font-family: "Gilroy Semibold"; }

.header__menu--overlay {
  background-color: #222321;
  font-family: "Gilroy Bold";
  font-size: 16px;
  text-align: center;
  color: #fff;
  text-transform: uppercase; }
  @media only screen and (max-width: 992px) {
    .header__menu--overlay {
      text-align: left;
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: none;
      z-index: 776;
      text-align: center;
      padding-top: 40px; } }
  .header__menu--overlay.fixed {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 4; }
    @media only screen and (max-width: 992px) {
      .header__menu--overlay.fixed {
        position: initial; } }
  .header__menu--overlay menu {
    display: flex;
    justify-content: center; }
    @media only screen and (max-width: 992px) {
      .header__menu--overlay menu {
        flex-wrap: wrap; } }
    @media only screen and (max-width: 576px) {
      .header__menu--overlay menu {
        display: none; } }
    .header__menu--overlay menu li {
      display: block; }
      @media only screen and (max-width: 992px) {
        .header__menu--overlay menu li {
          width: 100%; } }
      .header__menu--overlay menu li a {
        display: block;
        padding: 23px 31px;
        position: relative;
        transition: background-color .3s; }
        @media only screen and (max-width: 1208px) {
          .header__menu--overlay menu li a {
            padding: 23px 11px; } }
        @media only screen and (max-width: 992px) {
          .header__menu--overlay menu li a {
            display: inline-block; } }
        @media only screen and (max-width: 992px) {
          .header__menu--overlay menu li a {
            padding: 15px 22px; } }
        .header__menu--overlay menu li a:hover {
          background-color: #6CC81E; }
        .header__menu--overlay menu li a::before {
          content: " ";
          display: block;
          width: 1px;
          height: 100%;
          background-color: #3e3f3e;
          position: absolute;
          top: 0;
          left: 0; }
          @media only screen and (max-width: 992px) {
            .header__menu--overlay menu li a::before {
              display: none; } }
      .header__menu--overlay menu li:last-child a::after {
        content: " ";
        display: block;
        width: 1px;
        height: 100%;
        background-color: #3e3f3e;
        position: absolute;
        top: 0;
        right: 0; }
        @media only screen and (max-width: 992px) {
          .header__menu--overlay menu li:last-child a::after {
            display: none; } }
    .header__menu--overlay menu.mobile {
      display: none; }
      @media only screen and (max-width: 576px) {
        .header__menu--overlay menu.mobile {
          display: block; } }
      .header__menu--overlay menu.mobile .header__shedule {
        color: #fff; }
      .header__menu--overlay menu.mobile .header__phones-code {
        color: #fff; }
      .header__menu--overlay menu.mobile .btn {
        display: inline-block;
        margin: 10px 0; }
      .header__menu--overlay menu.mobile .header__phones-and-shedule .header__phones {
        margin: 65px 0 10px; }
      .header__menu--overlay menu.mobile .header__phones-and-shedule a {
        padding: 4px 15px; }

.header__content {
  background-image: url(../img/elements/background-header.png);
  background-size: cover;
  height: calc(100vh - 166px);
  position: relative;
  padding-top: 115px; }
  @media only screen and (max-width: 1100px) {
    .header__content {
      padding-top: 25px; } }
  @media only screen and (max-width: 992px) {
    .header__content {
      height: initial;
      padding-top: 81px; } }
  @media only screen and (max-width: 576px) {
    .header__content {
      padding-top: 57px;
      background-image: url(../img/elements/background-catalog.png);
      background-repeat: no-repeat;
      background-position: left top; } }
  @media only screen and (max-height: 820px) {
    .header__content {
      padding-top: 25px;
      height: initial;
      min-height: 500px; } }
  .header__content::after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0; }
    @media only screen and (max-width: 992px) {
      .header__content::after {
        background-color: rgba(0, 0, 0, 0.5); } }
    @media only screen and (max-width: 576px) {
      .header__content::after {
        display: none; } }
  .header__content .container {
    position: relative;
    z-index: 1;
    height: 100%; }

.header__title {
  color: #fff;
  font-family: "Gilroy Bold";
  font-size: 48px;
  line-height: 60px;
  position: relative; }
  @media only screen and (max-width: 1100px) {
    .header__title {
      font-size: 41px; } }
  @media only screen and (max-width: 992px) {
    .header__title {
      font-size: 34px;
      line-height: 42px; } }
  @media only screen and (max-width: 576px) {
    .header__title {
      font-size: 24px;
      line-height: 29px;
      text-align: center; } }
  @media only screen and (max-width: 576px) {
    .header__title span:first-child {
      display: block;
      margin-bottom: 5px; } }
  .header__title span:nth-child(2) {
    display: inline-block;
    background-color: #6CC81E;
    padding: 0 15px; }
    @media only screen and (max-width: 1199px) {
      .header__title span:nth-child(2) {
        display: initial; } }
    @media only screen and (max-width: 576px) {
      .header__title span:nth-child(2) {
        padding: 0 2px; } }
  .header__title::before {
    content: " ";
    display: block;
    width: 3px;
    height: 38px;
    background-color: #6CC81E;
    position: absolute;
    bottom: -80px;
    right: 268px;
    z-index: 1; }
    @media only screen and (max-width: 1199px) {
      .header__title::before {
        bottom: -60px; } }
  .header__title::after {
    content: '*Обладатель премии "Бренд Года 2018"';
    display: block;
    max-width: 165px;
    position: absolute;
    bottom: -80px;
    right: 83px;
    font-family: "Gilroy Semibold";
    font-size: 16px;
    line-height: 20px; }
    @media only screen and (max-width: 1199px) {
      .header__title::after {
        bottom: -60px; } }

.header__bottom {
  position: absolute;
  bottom: -75px;
  left: 0;
  width: 100%;
  padding: 0 15px; }
  @media only screen and (max-width: 992px) {
    .header__bottom {
      position: initial;
      transform: translateY(80px); } }
  @media only screen and (max-width: 576px) {
    .header__bottom {
      padding: 0; } }
  @media only screen and (max-height: 820px) {
    .header__bottom {
      position: initial;
      padding: 0; } }
  .header__bottom-row {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 992px) {
      .header__bottom-row {
        flex-wrap: wrap;
        flex-direction: column-reverse; } }
    .header__bottom-row .left {
      width: 578px;
      max-width: 100%;
      background-color: #fff;
      padding: 65px 70px 75px;
      box-shadow: 0px 15px 150px 0px rgba(54, 100, 55, 0.15); }
      @media only screen and (max-width: 768px) {
        .header__bottom-row .left {
          padding: 46px 49px 53px; } }
      @media only screen and (max-width: 576px) {
        .header__bottom-row .left {
          padding: 15px; } }
      .header__bottom-row .left p {
        font-family: "Gilroy Regular";
        font-size: 16px;
        line-height: 24px;
        color: #222321;
        margin-bottom: 30px; }
        @media only screen and (max-width: 576px) {
          .header__bottom-row .left p {
            margin-bottom: 21px; } }
        .header__bottom-row .left p:nth-child(3) {
          margin-bottom: 0; }
        .header__bottom-row .left p.color {
          background-color: #6CC81E;
          color: #fff;
          font-family: "Gilroy Bold";
          display: inline-block;
          margin-top: 4px;
          padding: 0 5px; }
    .header__bottom-row .right {
      margin-left: 45px;
      font-family: "Gilroy Semibold";
      font-size: 18px;
      color: #fff; }
      @media only screen and (max-width: 992px) {
        .header__bottom-row .right {
          margin-left: 0; } }
      @media only screen and (max-width: 576px) {
        .header__bottom-row .right {
          font-size: 14px; } }
      @media only screen and (max-width: 576px) {
        .header__bottom-row .right ul {
          margin-bottom: 15px; } }
      .header__bottom-row .right ul li {
        padding: 15px 0 15px 60px;
        background-repeat: no-repeat;
        background-position: left center; }
        @media only screen and (max-width: 992px) {
          .header__bottom-row .right ul li {
            padding: 11px 0 11px 42px; } }
        .header__bottom-row .right ul li.delivery {
          background-image: url(../img/elements/icon-delivery-truck.png);
          background-size: 38px; }
        .header__bottom-row .right ul li.garancy {
          background-image: url(../img/elements/icon-garancy.png);
          background-position-x: 7px; }
        .header__bottom-row .right ul li.storage {
          background-image: url(../img/elements/icon-warehouse.png);
          background-position-x: 6px;
          background-size: 29px; }
        .header__bottom-row .right ul li.installment {
          background-image: url(../img/elements/icon-installment.png);
          background-position-x: 7px;
          background-size: 29px; }
        .header__bottom-row .right ul li.price {
          background-image: url(../img/elements/icon-price.png);
          background-position-x: 7px;
          background-size: 33px;
          background-color: transparent; }
  .header__bottom-title {
    font-family: "Gilroy Bold" !important;
    font-size: 26px !important;
    color: #222321; }

.header__form {
  display: block; }
  .header__form label {
    display: inline-block;
    position: relative;
    max-width: 100%; }
    .header__form label span {
      position: absolute;
      top: -50%;
      transform: translateY(calc(50% - -3px));
      left: 35px;
      display: inline-block;
      font-family: "Gilroy Regular";
      font-size: 14px;
      line-height: 24px;
      color: #222321;
      background-color: #fff;
      padding: 0 10px; }
      @media only screen and (max-width: 576px) {
        .header__form label span {
          left: 25px;
          line-height: 17px;
          padding: 0 7px;
          transform: initial;
          top: -8px; } }
    .header__form label input:not([type="submit"]) {
      display: block;
      width: 418px;
      max-width: 100%;
      height: 56px;
      border-radius: 28px;
      border: 1px solid #c8c8c7;
      font-family: "Gilroy Bold";
      font-size: 18px;
      line-height: 24px;
      color: #222321;
      padding: 0 40px;
      background-image: url(../img/elements/icon-telephone.png);
      background-repeat: no-repeat;
      background-position: 20px center; }
      @media only screen and (max-width: 576px) {
        .header__form label input:not([type="submit"]) {
          height: 39px;
          padding: 0 28px;
          background-position: 14px center;
          font-size: 15px; } }
    .header__form label input[type="submit"] {
      display: block; }
  .header__form-input {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 6px; }
    @media only screen and (max-width: 576px) {
      .header__form-input {
        position: relative;
        top: initial;
        right: initial;
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;
        margin: 10px auto 0; } }
    .header__form-input::after {
      content: " ";
      display: block;
      width: 10px;
      height: 10px;
      background-image: url(../img/elements/icon-arrow.png);
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%); }

.advantages {
  background-color: #ffffff;
  padding-top: 205px;
  padding: 205px 0 145px;
  overflow: hidden; }
  @media only screen and (max-width: 768px) {
    .advantages {
      padding-top: 140px; } }
  @media only screen and (max-width: 576px) {
    .advantages {
      padding-top: 100px; } }
  .advantages .section-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative; }
    @media only screen and (max-width: 992px) {
      .advantages .section-title {
        display: block;
        position: initial; } }
    @media only screen and (max-width: 576px) {
      .advantages .section-title {
        line-height: 30px; } }
    .advantages .section-title::after {
      content: " ";
      display: block;
      width: 58px;
      height: 4px;
      background-color: #6CC81E;
      position: absolute;
      left: 375px;
      bottom: 26px; }
      @media only screen and (max-width: 992px) {
        .advantages .section-title::after {
          display: none; } }
    @media only screen and (max-width: 992px) {
      .advantages .section-title span {
        display: inline; } }
  .advantages__quote {
    font-family: "Gilroy Bold";
    font-size: 25px;
    color: #222321;
    margin: 40px 0 0 70px;
    position: relative;
    z-index: 1; }
    @media only screen and (max-width: 576px) {
      .advantages__quote {
        margin: 28px 0 0 49px;
        font-size: 18px; } }
    .advantages__quote::before {
      content: " ";
      display: block;
      width: 100px;
      height: 100px;
      background-color: #e6eece;
      border-radius: 50%;
      position: absolute;
      bottom: -50px;
      left: -60px;
      z-index: -1; }
      @media only screen and (max-width: 576px) {
        .advantages__quote::before {
          width: 70px;
          height: 70px;
          bottom: -35px;
          left: -42px; } }
    .advantages__quote::after {
      content: "’’";
      position: absolute;
      bottom: -100px;
      left: -73px;
      color: #222321;
      font-family: "Athelas Bold";
      font-size: 165px;
      line-height: 32px;
      letter-spacing: -.08em; }
      @media only screen and (max-width: 576px) {
        .advantages__quote::after {
          bottom: -70px;
          left: -51px;
          font-size: 116px;
          line-height: 22px; } }
  .advantages__slider--overlay {
    position: relative; }
  .advantages__slider {
    margin-top: 70px; }
    @media only screen and (max-width: 992px) {
      .advantages__slider {
        margin-top: 100px; } }
    @media only screen and (max-width: 576px) {
      .advantages__slider {
        margin-top: 35px; } }
    .advantages__slider .slick-arrow {
      width: 105px;
      height: 105px;
      cursor: pointer;
      border-radius: 50%;
      background: linear-gradient(to bottom, #30825a 0%, #0a6337 100%);
      box-shadow: inset 0 -1px 5px 0 rgba(0, 0, 0, 0.23);
      z-index: 1; }
      @media only screen and (max-width: 576px) {
        .advantages__slider .slick-arrow {
          width: 74px;
          height: 74px; } }
      .advantages__slider .slick-arrow::after {
        content: " ";
        display: block;
        width: 12px;
        height: 20px;
        background-size: cover;
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
      .advantages__slider .slick-arrow.slick-prev {
        left: -50px; }
        .advantages__slider .slick-arrow.slick-prev::after {
          background-image: url(../img/elements/arrow-left-bold.png);
          right: 32px; }
          @media only screen and (max-width: 576px) {
            .advantages__slider .slick-arrow.slick-prev::after {
              right: 8px; } }
      .advantages__slider .slick-arrow.slick-next {
        right: -50px; }
        .advantages__slider .slick-arrow.slick-next::after {
          background-image: url(../img/elements/arrow-right-bold.png);
          left: 32px; }
          @media only screen and (max-width: 576px) {
            .advantages__slider .slick-arrow.slick-next::after {
              left: 8px; } }
      .advantages__slider .slick-arrow.slick-next::before, .advantages__slider .slick-arrow.slick-prev::before {
        display: none; }
  .advantages .slick-track {
    display: flex;
    align-items: flex-end; }
    @media only screen and (max-width: 992px) {
      .advantages .slick-track {
        align-items: center; } }
  .advantages__slide--first {
    position: relative; }
    @media only screen and (max-width: 1505px) {
      .advantages__slide--first {
        transform: translateX(-150px); } }
    @media only screen and (max-width: 1399px) {
      .advantages__slide--first {
        transform: translateX(0); } }
    @media only screen and (max-width: 992px) {
      .advantages__slide--first {
        padding-bottom: 30px; } }
    .advantages__slide--first::before {
      content: " ";
      display: block;
      width: 222px;
      height: 100%;
      background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1; }
      @media only screen and (max-width: 992px) {
        .advantages__slide--first::before {
          display: none; } }
    .advantages__slide--first .advantages__slide-row {
      display: flex;
      align-items: flex-end; }
      @media only screen and (max-width: 992px) {
        .advantages__slide--first .advantages__slide-row {
          display: block; } }
      .advantages__slide--first .advantages__slide-row .right {
        width: 700px;
        max-width: 100%;
        padding-left: 220px; }
        @media only screen and (max-width: 1505px) {
          .advantages__slide--first .advantages__slide-row .right {
            padding-bottom: 30px;
            padding-left: 170px; } }
        @media only screen and (max-width: 1399px) {
          .advantages__slide--first .advantages__slide-row .right {
            padding-right: 66px; } }
        @media only screen and (max-width: 992px) {
          .advantages__slide--first .advantages__slide-row .right {
            width: 100%;
            margin-top: 15px;
            padding: 0 15px; } }
      .advantages__slide--first .advantages__slide-row .left {
        min-height: 628px; }
        @media only screen and (max-width: 1399px) {
          .advantages__slide--first .advantages__slide-row .left {
            width: 490px; } }
        @media only screen and (max-width: 992px) {
          .advantages__slide--first .advantages__slide-row .left {
            min-height: initial;
            height: 500px;
            width: 100%;
            overflow: hidden; } }
        @media only screen and (max-width: 576px) {
          .advantages__slide--first .advantages__slide-row .left {
            height: 300px; } }
        .advantages__slide--first .advantages__slide-row .left .image-block {
          position: relative;
          min-height: 628px; }
          @media only screen and (max-width: 992px) {
            .advantages__slide--first .advantages__slide-row .left .image-block {
              min-height: initial; } }
          @media only screen and (max-width: 992px) {
            .advantages__slide--first .advantages__slide-row .left .image-block {
              max-height: 400px; } }
          @media only screen and (max-width: 576px) {
            .advantages__slide--first .advantages__slide-row .left .image-block {
              max-height: 300px; } }
          .advantages__slide--first .advantages__slide-row .left .image-block img {
            max-width: 100%;
            min-height: 628px;
            object-fit: cover; }
            @media only screen and (max-width: 1399px) {
              .advantages__slide--first .advantages__slide-row .left .image-block img {
                width: 100%; } }
            @media only screen and (max-width: 992px) {
              .advantages__slide--first .advantages__slide-row .left .image-block img {
                min-height: initial; } }
            .advantages__slide--first .advantages__slide-row .left .image-block img.cropped {
              display: none; }
              @media only screen and (max-width: 1399px) {
                .advantages__slide--first .advantages__slide-row .left .image-block img.cropped {
                  display: block; } }
              @media only screen and (max-width: 992px) {
                .advantages__slide--first .advantages__slide-row .left .image-block img.cropped {
                  display: none; } }
              @media only screen and (max-width: 576px) {
                .advantages__slide--first .advantages__slide-row .left .image-block img.cropped {
                  display: block; } }
            @media only screen and (max-width: 1399px) {
              .advantages__slide--first .advantages__slide-row .left .image-block img.full {
                display: none; } }
            @media only screen and (max-width: 992px) {
              .advantages__slide--first .advantages__slide-row .left .image-block img.full {
                display: block; } }
            @media only screen and (max-width: 576px) {
              .advantages__slide--first .advantages__slide-row .left .image-block img.full {
                display: none; } }
          .advantages__slide--first .advantages__slide-row .left .image-block #video-play-btn {
            display: block;
            width: 296px;
            height: 296px;
            border-radius: 50%;
            background-size: cover;
            background-image: url(../img/elements/background-play-video-btn.png);
            position: absolute;
            right: -148px;
            top: 0;
            cursor: pointer;
            transition: background-color .3s; }
            @media only screen and (max-width: 992px) {
              .advantages__slide--first .advantages__slide-row .left .image-block #video-play-btn {
                left: 0; } }
            @media only screen and (max-width: 576px) {
              .advantages__slide--first .advantages__slide-row .left .image-block #video-play-btn {
                width: 100px;
                height: 100px;
                bottom: 0;
                left: 0;
                top: initial; } }
            .advantages__slide--first .advantages__slide-row .left .image-block #video-play-btn::before {
              content: " ";
              display: block;
              width: 177px;
              height: 177px;
              border-radius: 50%;
              border: 10px solid #366437;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
              transition: border-color .3s; }
              @media only screen and (max-width: 576px) {
                .advantages__slide--first .advantages__slide-row .left .image-block #video-play-btn::before {
                  width: 60px;
                  height: 60px;
                  border-width: 4px; } }
            .advantages__slide--first .advantages__slide-row .left .image-block #video-play-btn::after {
              content: " ";
              display: block;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 16px 0 16px 24px;
              border-color: transparent transparent transparent #366437;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(calc(-50% + 4px)) translateY(-50%);
              transition: border-color .3s; }
            .advantages__slide--first .advantages__slide-row .left .image-block #video-play-btn:hover {
              background-color: #366437; }
              .advantages__slide--first .advantages__slide-row .left .image-block #video-play-btn:hover::before {
                border-color: #fff; }
              .advantages__slide--first .advantages__slide-row .left .image-block #video-play-btn:hover::after {
                border-color: transparent transparent transparent #fff; }
          .advantages__slide--first .advantages__slide-row .left .image-block .whois {
            position: absolute;
            right: 20px;
            transform: translateX(100%);
            bottom: 140px;
            width: 175px; }
            @media only screen and (max-width: 992px) {
              .advantages__slide--first .advantages__slide-row .left .image-block .whois {
                right: initial;
                left: -100px;
                bottom: -55px; } }
            @media only screen and (max-width: 576px) {
              .advantages__slide--first .advantages__slide-row .left .image-block .whois {
                display: flex;
                flex-direction: column;
                right: 0;
                bottom: 15px;
                text-align: center;
                left: initial;
                transform: initial; } }
            .advantages__slide--first .advantages__slide-row .left .image-block .whois .title {
              font-family: "Gilroy Regular";
              font-size: 16px;
              line-height: 24px;
              color: #222321;
              padding-left: 45px; }
              @media only screen and (max-width: 576px) {
                .advantages__slide--first .advantages__slide-row .left .image-block .whois .title {
                  font-size: 14px;
                  line-height: 17px;
                  padding-left: initial; } }
            .advantages__slide--first .advantages__slide-row .left .image-block .whois .name {
              display: inline-block;
              text-align: center;
              position: relative;
              left: 50%;
              transform: translateX(-50%);
              margin: 25px 0;
              font-family: "Gilroy Bold";
              font-size: 21px;
              line-height: 42px;
              background-color: #366437;
              color: #fff;
              padding: 0 15px; }
              @media only screen and (max-width: 576px) {
                .advantages__slide--first .advantages__slide-row .left .image-block .whois .name {
                  margin: 10px 0;
                  font-size: 15px;
                  line-height: 29px;
                  padding: 0 11px; } }
            .advantages__slide--first .advantages__slide-row .left .image-block .whois .position {
              font-family: "Gilroy Bold";
              font-size: 21px;
              color: #366437;
              text-align: center; }
              @media only screen and (max-width: 576px) {
                .advantages__slide--first .advantages__slide-row .left .image-block .whois .position {
                  font-size: 15px; } }
    .advantages__slide--first .advantages__slide-title {
      display: flex;
      flex-direction: column;
      align-items: baseline; }
      @media only screen and (max-width: 992px) {
        .advantages__slide--first .advantages__slide-title {
          align-items: center; } }
      .advantages__slide--first .advantages__slide-title span {
        display: block;
        font-family: "Gilroy Bold";
        font-size: 24px;
        line-height: 45px;
        color: #fff;
        background-color: #6CC81E;
        line-height: 40px;
        padding: 0 10px; }
        @media only screen and (max-width: 576px) {
          .advantages__slide--first .advantages__slide-title span {
            font-size: 17px;
            line-height: 32px;
            line-height: 28px; } }
        .advantages__slide--first .advantages__slide-title span:first-child {
          margin-bottom: 6px; }
          @media only screen and (max-width: 576px) {
            .advantages__slide--first .advantages__slide-title span:first-child {
              margin-bottom: 5px; } }
    .advantages__slide--first .advantages__slide-item {
      padding-left: 35px; }
      @media only screen and (max-width: 1199px) {
        .advantages__slide--first .advantages__slide-item {
          padding-left: initial; } }
      .advantages__slide--first .advantages__slide-item p {
        font-family: "Gilroy Regular";
        font-size: 17px;
        line-height: 24px;
        color: #222321;
        margin-top: 25px; }
        @media only screen and (max-width: 576px) {
          .advantages__slide--first .advantages__slide-item p {
            font-size: 14px;
            line-height: 17px;
            margin-top: 18px; } }
        .advantages__slide--first .advantages__slide-item p.number {
          font-family: "Gilroy Bold";
          font-size: 60px;
          line-height: 60px;
          color: #89ae3b;
          text-shadow: 0px 3px 1px rgba(108, 135, 46, 0.23);
          position: relative;
          margin-top: 85px; }
          @media only screen and (max-width: 576px) {
            .advantages__slide--first .advantages__slide-item p.number {
              font-size: 42px;
              line-height: 42px;
              margin-top: 60px; } }
          .advantages__slide--first .advantages__slide-item p.number .big {
            font-size: 100px; }
            @media only screen and (max-width: 576px) {
              .advantages__slide--first .advantages__slide-item p.number .big {
                font-size: 70px; } }
          .advantages__slide--first .advantages__slide-item p.number .absolute {
            color: #222321;
            font-family: "Gilroy Semibold";
            font-size: 21px;
            line-height: 30px;
            position: absolute;
            top: -15px;
            left: 73px;
            text-shadow: none; }
            @media only screen and (max-width: 576px) {
              .advantages__slide--first .advantages__slide-item p.number .absolute {
                font-size: 15px;
                line-height: 21px;
                top: -11px;
                left: 51px; } }
      .advantages__slide--first .advantages__slide-item--second .absolute {
        left: 120px !important; }
        @media only screen and (max-width: 576px) {
          .advantages__slide--first .advantages__slide-item--second .absolute {
            left: 84px !important; } }
  .advantages__slide--second--overlay {
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 75px 0 105px; }
    @media only screen and (max-width: 1199px) {
      .advantages__slide--second--overlay {
        transform: scale(0.8); } }
    @media only screen and (max-width: 992px) {
      .advantages__slide--second--overlay {
        transform: scale(0.6); } }
    @media only screen and (max-width: 768px) {
      .advantages__slide--second--overlay {
        transform: initial; } }
    .advantages__slide--second--overlay .main-image {
      position: relative; }
      @media only screen and (max-width: 768px) {
        .advantages__slide--second--overlay .main-image {
          position: initial;
          max-width: 100%; } }
      .advantages__slide--second--overlay .main-image img {
        display: inline-block;
        max-width: 100%; }
        @media only screen and (max-width: 768px) {
          .advantages__slide--second--overlay .main-image img {
            max-width: 100%; } }
    .advantages__slide--second--overlay .content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      @media only screen and (max-width: 768px) {
        .advantages__slide--second--overlay .content {
          position: initial; } }
      .advantages__slide--second--overlay .content .item {
        position: absolute; }
        @media only screen and (max-width: 768px) {
          .advantages__slide--second--overlay .content .item {
            position: initial;
            width: 100% !important;
            text-align: left !important; } }
        .advantages__slide--second--overlay .content .item::before {
          content: " ";
          display: block;
          width: 72px;
          height: 72px;
          border-radius: 50%;
          background-color: #d1e39e;
          position: absolute; }
          @media only screen and (max-width: 768px) {
            .advantages__slide--second--overlay .content .item::before {
              display: none; } }
        .advantages__slide--second--overlay .content .item::after {
          display: block;
          width: 44px;
          height: 44px;
          background-color: #8aab30;
          border-radius: 50%;
          position: absolute;
          color: #fff;
          font-family: "Gilroy Bold";
          font-size: 24px;
          line-height: 44px;
          text-align: center; }
          @media only screen and (max-width: 768px) {
            .advantages__slide--second--overlay .content .item::after {
              display: none; } }
        .advantages__slide--second--overlay .content .item p {
          font-family: "Gilroy Regular";
          color: #222321;
          font-size: 18px;
          position: relative; }
          @media only screen and (max-width: 576px) {
            .advantages__slide--second--overlay .content .item p {
              font-size: 14px; } }
          .advantages__slide--second--overlay .content .item p::before, .advantages__slide--second--overlay .content .item p::after {
            content: " ";
            background-color: #d1e39e;
            position: absolute; }
            @media only screen and (max-width: 768px) {
              .advantages__slide--second--overlay .content .item p::before, .advantages__slide--second--overlay .content .item p::after {
                display: none; } }
          .advantages__slide--second--overlay .content .item p span {
            display: block;
            font-family: "Gilroy Bold";
            color: #366437; }
            @media only screen and (max-width: 768px) {
              .advantages__slide--second--overlay .content .item p span {
                display: initial; } }
        .advantages__slide--second--overlay .content .item:first-child {
          width: 105px;
          left: 230px;
          top: -47px;
          text-align: left; }
          .advantages__slide--second--overlay .content .item:first-child::after {
            left: -139px;
            bottom: -62px;
            content: "1"; }
          .advantages__slide--second--overlay .content .item:first-child::before {
            left: -153px;
            bottom: -76px; }
          .advantages__slide--second--overlay .content .item:first-child p::before {
            top: 11px;
            left: -70px;
            width: 50px;
            height: 1px; }
          .advantages__slide--second--overlay .content .item:first-child p::after {
            width: 60px;
            height: 1px;
            top: 35px;
            left: -118px;
            transform: rotate(-53deg); }
        .advantages__slide--second--overlay .content .item:nth-child(2) {
          width: 255px;
          left: 420px;
          top: -79px; }
          .advantages__slide--second--overlay .content .item:nth-child(2)::before {
            left: 88px;
            bottom: -132px; }
          .advantages__slide--second--overlay .content .item:nth-child(2)::after {
            content: "2";
            left: 102px;
            bottom: -118px; }
          .advantages__slide--second--overlay .content .item:nth-child(2) p::before {
            height: 44px;
            width: 1px;
            left: calc(50% - 3px);
            transform: translateX(-50%);
            bottom: -61px; }
        .advantages__slide--second--overlay .content .item:nth-child(3) {
          width: 170px;
          right: -100px;
          top: -20px;
          text-align: right; }
          .advantages__slide--second--overlay .content .item:nth-child(3)::before {
            left: -141px;
            bottom: -72px; }
          .advantages__slide--second--overlay .content .item:nth-child(3)::after {
            content: "3";
            left: -127px;
            bottom: -58px; }
          .advantages__slide--second--overlay .content .item:nth-child(3) p::before {
            top: 11px;
            left: -50px;
            width: 50px;
            height: 1px; }
          .advantages__slide--second--overlay .content .item:nth-child(3) p::after {
            width: 60px;
            height: 1px;
            top: 32px;
            left: -101px;
            transform: rotate(-45deg); }
        .advantages__slide--second--overlay .content .item:nth-child(4) {
          width: 215px;
          text-align: left;
          right: -110px;
          bottom: -42px; }
          .advantages__slide--second--overlay .content .item:nth-child(4)::before {
            left: 33px;
            top: -154px; }
          .advantages__slide--second--overlay .content .item:nth-child(4)::after {
            content: "4";
            left: 47px;
            top: -140px; }
          .advantages__slide--second--overlay .content .item:nth-child(4) p::before {
            top: -40px;
            left: 5px;
            width: 1px;
            height: 25px; }
          .advantages__slide--second--overlay .content .item:nth-child(4) p::after {
            width: 67px;
            height: 1px;
            top: -65px;
            left: -7px;
            transform: rotate(-50deg); }
        .advantages__slide--second--overlay .content .item:nth-child(5) {
          bottom: -22px;
          right: 155px;
          width: 335px; }
          .advantages__slide--second--overlay .content .item:nth-child(5)::before {
            left: 125px;
            top: -158px; }
          .advantages__slide--second--overlay .content .item:nth-child(5)::after {
            content: "5";
            left: 139px;
            top: -144px; }
          .advantages__slide--second--overlay .content .item:nth-child(5) p::before {
            height: 80px;
            width: 1px;
            left: calc(50% - 5px);
            transform: translateX(-50%);
            top: -90px; }
        .advantages__slide--second--overlay .content .item:nth-child(6) {
          width: 305px;
          left: -30px;
          bottom: -30px;
          text-align: right; }
          .advantages__slide--second--overlay .content .item:nth-child(6)::before {
            right: -105px;
            top: -63px; }
          .advantages__slide--second--overlay .content .item:nth-child(6)::after {
            content: "6";
            right: -91px;
            top: -49px; }
          .advantages__slide--second--overlay .content .item:nth-child(6) p::before {
            height: 1px;
            width: 14px;
            right: -25px;
            top: 11px; }
          .advantages__slide--second--overlay .content .item:nth-child(6) p::after {
            width: 35px;
            height: 1px;
            top: -2px;
            right: -55px;
            transform: rotate(-45deg); }
        .advantages__slide--second--overlay .content .item:nth-child(7) {
          width: 100px;
          left: -95px;
          top: 105px;
          text-align: left; }
          .advantages__slide--second--overlay .content .item:nth-child(7)::before {
            right: -57px;
            bottom: -90px; }
          .advantages__slide--second--overlay .content .item:nth-child(7)::after {
            content: "7";
            right: -43px;
            bottom: -76px; }
          .advantages__slide--second--overlay .content .item:nth-child(7) p::before {
            height: 1px;
            width: 30px;
            right: 15px;
            bottom: -55px; }
          .advantages__slide--second--overlay .content .item:nth-child(7) p::after {
            width: 50px;
            height: 1px;
            bottom: -32px;
            right: 30px;
            transform: rotate(66deg); }
  .advantages__slide--third {
    padding-bottom: 205px; }
    @media only screen and (max-width: 768px) {
      .advantages__slide--third {
        padding-bottom: initial; } }
    @media only screen and (max-width: 576px) {
      .advantages__slide--third {
        padding-bottom: 15px; } }
    .advantages__slide--third .advantages__slide--third-row {
      display: flex; }
      @media only screen and (max-width: 768px) {
        .advantages__slide--third .advantages__slide--third-row {
          flex-wrap: wrap; } }
    .advantages__slide--third .advantages__slide--third-item {
      width: 33.3334%;
      padding: 37px 15px 0;
      text-align: center;
      color: #222321; }
      @media only screen and (max-width: 768px) {
        .advantages__slide--third .advantages__slide--third-item {
          width: 100%; } }
      @media only screen and (max-width: 768px) {
        .advantages__slide--third .advantages__slide--third-item:not(:first-child) {
          margin-top: 20px; } }
      @media only screen and (max-width: 576px) {
        .advantages__slide--third .advantages__slide--third-item:not(:first-child) {
          margin-top: 14px; } }
      .advantages__slide--third .advantages__slide--third-item .image-block {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        background: linear-gradient(to bottom, #acc95d 0%, #88aa38 100%);
        box-shadow: inset 0px -1px 1px 0px rgba(0, 0, 0, 0.23);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative; }
        @media only screen and (max-width: 768px) {
          .advantages__slide--third .advantages__slide--third-item .image-block {
            width: 90px;
            height: 90px; } }
        .advantages__slide--third .advantages__slide--third-item .image-block::before {
          content: " ";
          display: block;
          width: 203px;
          height: 102px;
          background-image: url(../img/elements/duga-slider.png);
          background-repeat: no-repeat;
          position: absolute;
          top: -37px;
          left: -37.5px; }
          @media only screen and (max-width: 768px) {
            .advantages__slide--third .advantages__slide--third-item .image-block::before {
              width: 140px;
              height: 70px;
              background-size: contain;
              top: -26px;
              left: -26px; } }
      .advantages__slide--third .advantages__slide--third-item .title {
        font-size: 30px;
        font-family: "Gilroy Semibold";
        margin-top: 35px; }
        @media only screen and (max-width: 768px) {
          .advantages__slide--third .advantages__slide--third-item .title {
            font-size: 21px;
            margin-top: 25px; } }
      .advantages__slide--third .advantages__slide--third-item .description {
        margin-top: 5px;
        font-family: "Gilroy Regular";
        font-size: 18px;
        line-height: 30px; }
        @media only screen and (max-width: 768px) {
          .advantages__slide--third .advantages__slide--third-item .description {
            font-size: 14px;
            line-height: 21px; } }
        .advantages__slide--third .advantages__slide--third-item .description span {
          font-family: "Gilroy Bold";
          display: block; }
  .advantages__slide--fifth {
    position: relative;
    padding: 40px 0 35px; }
    .advantages__slide--fifth::before {
      content: " ";
      display: block;
      width: 100%;
      height: 123px;
      background-color: #6CC81E;
      position: absolute;
      bottom: 0;
      left: 0; }
    .advantages__slide--fifth-row {
      display: flex;
      justify-content: space-between; }
      @media only screen and (max-width: 1199px) {
        .advantages__slide--fifth-row {
          flex-wrap: wrap; } }
      .advantages__slide--fifth-row .left {
        width: 550px; }
        @media only screen and (max-width: 1199px) {
          .advantages__slide--fifth-row .left {
            width: 100%; } }
        .advantages__slide--fifth-row .left .title {
          display: flex;
          flex-direction: column;
          align-items: flex-start; }
          @media only screen and (max-width: 1199px) {
            .advantages__slide--fifth-row .left .title {
              display: block; } }
          .advantages__slide--fifth-row .left .title span {
            background-color: #6CC81E;
            color: #fff;
            font-family: "Gilroy Bold";
            font-size: 24px;
            line-height: 41px;
            display: block;
            padding: 0 13px; }
            @media only screen and (max-width: 1199px) {
              .advantages__slide--fifth-row .left .title span {
                line-height: 29px;
                padding: 0;
                display: initial; } }
            @media only screen and (max-width: 576px) {
              .advantages__slide--fifth-row .left .title span {
                font-size: 20px;
                line-height: 25px; } }
            .advantages__slide--fifth-row .left .title span:first-child {
              margin-bottom: 6px; }
        .advantages__slide--fifth-row .left ul {
          margin: 25px 0;
          font-family: "Gilroy Semibold";
          font-size: 18px;
          color: #222321; }
          @media only screen and (max-width: 576px) {
            .advantages__slide--fifth-row .left ul {
              margin: 18px 0;
              font-size: 15px; } }
          .advantages__slide--fifth-row .left ul span {
            color: #6CC81E; }
        .advantages__slide--fifth-row .left .kits {
          display: flex;
          flex-wrap: wrap; }
          .advantages__slide--fifth-row .left .kits .image-block {
            margin: 0 30px 30px 0;
            width: 150px;
            height: 178px;
            border: 10px solid #fff;
            box-shadow: 0px 10px 25px 0px rgba(54, 100, 55, 0.25);
            background-color: #e4e9e4;
            position: relative;
            cursor: pointer; }
            @media only screen and (max-width: 1199px) {
              .advantages__slide--fifth-row .left .kits .image-block {
                margin: 0 5px 30px 0; } }
            @media only screen and (max-width: 576px) {
              .advantages__slide--fifth-row .left .kits .image-block {
                margin: 0 5px 10px 0;
                border-width: 10px; } }
            @media only screen and (max-width: 509px) {
              .advantages__slide--fifth-row .left .kits .image-block {
                width: 100px;
                height: 100px; } }
            .advantages__slide--fifth-row .left .kits .image-block::after {
              content: " ";
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background-color: #366437;
              background-image: url(../img/elements/icon-zoom-in.png);
              background-position: center;
              background-repeat: no-repeat;
              opacity: 0;
              transition: opacity .3s; }
            .advantages__slide--fifth-row .left .kits .image-block:hover::after {
              opacity: 1; }
            .advantages__slide--fifth-row .left .kits .image-block:nth-child(3n) {
              margin-right: 0; }
              @media only screen and (max-width: 1199px) {
                .advantages__slide--fifth-row .left .kits .image-block:nth-child(3n) {
                  margin-right: 5px; } }
            .advantages__slide--fifth-row .left .kits .image-block img {
              width: 100%;
              height: 100%;
              object-fit: cover; }
      .advantages__slide--fifth-row .right {
        width: calc(100% - 550px); }
        @media only screen and (max-width: 1199px) {
          .advantages__slide--fifth-row .right {
            width: 100%;
            margin-top: 20px; } }
        @media only screen and (max-width: 576px) {
          .advantages__slide--fifth-row .right {
            margin-top: 14px; } }
        .advantages__slide--fifth-row .right p {
          max-width: 475px;
          font-family: "Gilroy Bold";
          font-size: 30px;
          line-height: 48px; }
          @media only screen and (max-width: 1199px) {
            .advantages__slide--fifth-row .right p {
              max-width: initial;
              font-size: 21px;
              line-height: 34px; } }
          @media only screen and (max-width: 576px) {
            .advantages__slide--fifth-row .right p {
              font-size: 15px;
              line-height: 20px; } }
          .advantages__slide--fifth-row .right p span {
            color: #366437; }
        .advantages__slide--fifth-row .right .certificates {
          display: flex;
          margin-top: 39px; }
          @media only screen and (max-width: 1199px) {
            .advantages__slide--fifth-row .right .certificates {
              margin-top: 20px; } }
          @media only screen and (max-width: 576px) {
            .advantages__slide--fifth-row .right .certificates {
              margin-top: 15px; } }
          .advantages__slide--fifth-row .right .certificates .image-block {
            width: 300px;
            height: 425px;
            border: 10px solid #fff;
            box-shadow: 0px 10px 25px 0px rgba(54, 100, 55, 0.25);
            background-color: #e4e9e4;
            position: relative;
            cursor: pointer; }
            @media only screen and (max-width: 576px) {
              .advantages__slide--fifth-row .right .certificates .image-block {
                height: 150px;
                border-width: 7px; } }
            .advantages__slide--fifth-row .right .certificates .image-block::after {
              content: " ";
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background-color: #366437;
              background-image: url(../img/elements/icon-zoom-in.png);
              background-position: center;
              background-repeat: no-repeat;
              opacity: 0;
              transition: opacity .3s; }
            .advantages__slide--fifth-row .right .certificates .image-block:hover::after {
              opacity: 1; }
            .advantages__slide--fifth-row .right .certificates .image-block:first-child {
              margin-right: 15px; }
            .advantages__slide--fifth-row .right .certificates .image-block img {
              width: 100%;
              height: 100%;
              object-fit: cover; }
  .advantages__slide--sixth {
    padding-bottom: 60px; }
    .advantages__slide--sixth__row {
      display: flex; }
      @media only screen and (max-width: 768px) {
        .advantages__slide--sixth__row {
          flex-wrap: wrap; } }
    .advantages__slide--sixth .title {
      text-align: center;
      font-family: "Gilroy Bold";
      font-size: 24px;
      color: #222321;
      margin-bottom: 40px; }
      @media only screen and (max-width: 576px) {
        .advantages__slide--sixth .title {
          font-size: 18px;
          margin-bottom: 28px; } }
    .advantages__slide--sixth .video {
      display: block;
      cursor: pointer;
      position: relative;
      width: 420px;
      height: 500px; }
      @media only screen and (max-width: 1199px) {
        .advantages__slide--sixth .video {
          width: 300px; } }
      @media only screen and (max-width: 992px) {
        .advantages__slide--sixth .video {
          width: 250px; } }
      @media only screen and (max-width: 768px) {
        .advantages__slide--sixth .video {
          width: 500px;
          max-width: 100%;
          margin: 0 auto;
          height: 350px; } }
      @media only screen and (max-width: 576px) {
        .advantages__slide--sixth .video {
          height: 250px; } }
      .advantages__slide--sixth .video::before {
        content: " ";
        display: block;
        width: 100%;
        height: 100%;
        background-color: #366437;
        opacity: 0.4;
        transition: opacity .3s;
        position: absolute;
        top: 0;
        left: 0; }
      .advantages__slide--sixth .video:hover::before {
        opacity: 0.75; }
      .advantages__slide--sixth .video::after {
        content: " ";
        display: block;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        background-image: url(../img/elements/icon-play-button.png);
        background-size: cover; }
      .advantages__slide--sixth .video img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .advantages__slide--sixth .photos {
      width: calc(70% - 30px);
      display: flex;
      flex-wrap: wrap;
      margin-left: 30px; }
      @media only screen and (max-width: 1199px) {
        .advantages__slide--sixth .photos {
          width: calc(100% - 320px);
          margin-left: 20px; } }
      @media only screen and (max-width: 992px) {
        .advantages__slide--sixth .photos {
          width: calc(100% - 270px); } }
      @media only screen and (max-width: 768px) {
        .advantages__slide--sixth .photos {
          width: 100%;
          margin-left: initial;
          margin-top: 20px; } }
      .advantages__slide--sixth .photos .image-block {
        width: calc(33.3334% - 6.6667px);
        margin-bottom: 10px;
        height: 245px;
        position: relative;
        cursor: pointer; }
        @media only screen and (max-width: 576px) {
          .advantages__slide--sixth .photos .image-block {
            height: 150px; } }
        @media only screen and (max-width: 350px) {
          .advantages__slide--sixth .photos .image-block {
            height: 100px; } }
        .advantages__slide--sixth .photos .image-block::after {
          content: " ";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: #366437;
          background-image: url(../img/elements/icon-zoom-in.png);
          background-position: center;
          background-repeat: no-repeat;
          opacity: 0;
          transition: opacity .3s; }
        .advantages__slide--sixth .photos .image-block:hover::after {
          opacity: 1; }
        .advantages__slide--sixth .photos .image-block:first-child, .advantages__slide--sixth .photos .image-block:nth-child(2), .advantages__slide--sixth .photos .image-block:nth-child(4), .advantages__slide--sixth .photos .image-block:nth-child(5) {
          margin-right: 10px; }
        .advantages__slide--sixth .photos .image-block:nth-child(4), .advantages__slide--sixth .photos .image-block:nth-child(5), .advantages__slide--sixth .photos .image-block:nth-child(6) {
          margin-bottom: 0; }
        .advantages__slide--sixth .photos .image-block img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
  .advantages .slick-dots {
    background-color: #366437;
    bottom: -175px;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media only screen and (max-width: 992px) {
      .advantages .slick-dots {
        display: block; } }
    .advantages .slick-dots li {
      width: initial;
      height: initial;
      max-width: 190px;
      height: 175px;
      margin: initial;
      padding: 55px 25px;
      position: relative;
      display: flex; }
      @media only screen and (max-width: 992px) {
        .advantages .slick-dots li {
          width: 100%;
          max-width: initial;
          padding: 5px 15px;
          height: initial;
          display: flex;
          justify-content: center; } }
      .advantages .slick-dots li::before {
        content: " ";
        display: block;
        height: 100%;
        width: 1px;
        background-color: #477149;
        position: absolute;
        top: 0;
        right: 0; }
        @media only screen and (max-width: 992px) {
          .advantages .slick-dots li::before {
            display: none; } }
      .advantages .slick-dots li:first-child::after {
        content: " ";
        display: block;
        height: 100%;
        width: 1px;
        background-color: #477149;
        position: absolute;
        top: 0;
        left: 0; }
        @media only screen and (max-width: 992px) {
          .advantages .slick-dots li:first-child::after {
            display: none; } }
      .advantages .slick-dots li button {
        width: initial;
        height: initial;
        padding: initial; }
        .advantages .slick-dots li button::before {
          text-align: center;
          position: static;
          width: initial;
          height: initial;
          font-family: "Gilroy Bold";
          font-size: 18px;
          color: #fff; }
          @media only screen and (max-width: 576px) {
            .advantages .slick-dots li button::before {
              font-size: 17px; } }
      .advantages .slick-dots li.slick-active button::before {
        opacity: 1; }
      .advantages .slick-dots li:first-child button::before {
        content: "Расширенная гарантия качества"; }
      .advantages .slick-dots li:nth-child(2) button::before {
        content: "Лучшие комплектующие"; }
      .advantages .slick-dots li:nth-child(3) button::before {
        content: "Честные цены и сезонные скидки"; }
      .advantages .slick-dots li:nth-child(4) button::before {
        content: "Доставка, установка, хранение"; }
      .advantages .slick-dots li:nth-child(5) button::before {
        content: "Все официальные документы"; }
      .advantages .slick-dots li:nth-child(6) button::before {
        content: "Собственное производство"; }

.price {
  padding: 140px 0 0;
  background-color: #f3f8f0;
  overflow: hidden; }
  .price .section-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .price .section-title span {
      position: relative; }
      .price .section-title span::after {
        content: " ";
        display: block;
        width: 58px;
        height: 4px;
        background-color: #6CC81E;
        position: absolute;
        right: -88px;
        bottom: 28px; }
  .price .discount {
    color: #fff;
    line-height: 30px;
    position: relative;
    padding: 85px 110px 140px 0;
    max-width: 470px; }
    @media only screen and (max-width: 992px) {
      .price .discount {
        width: 100%;
        padding: 30px 0 30px 0;
        color: #222321; } }
    @media only screen and (max-width: 576px) {
      .price .discount {
        padding: 21px 0; } }
    .price .discount::before {
      content: " ";
      display: block;
      width: 5469px;
      height: 100%;
      background-image: url(../img/elements/background-price-smoke.png);
      position: absolute;
      left: -5000px;
      top: 0;
      background-color: #222321;
      background-position-x: right; }
      @media only screen and (max-width: 1199px) {
        .price .discount::before {
          width: 5385px; } }
      @media only screen and (max-width: 992px) {
        .price .discount::before {
          display: none; } }
    @media only screen and (max-width: 992px) {
      .price .discount__value {
        display: none; } }
    .price .discount__value.mobile {
      display: none;
      margin: 15px 0;
      padding: 15px 0;
      width: 100%;
      position: relative;
      color: #fff; }
      .price .discount__value.mobile::before {
        content: " ";
        display: block;
        width: 10000px;
        height: 100%;
        position: absolute;
        left: -5000px;
        top: 0;
        background-color: #222321; }
      @media only screen and (max-width: 992px) {
        .price .discount__value.mobile {
          display: block; } }
      .price .discount__value.mobile .title {
        font-family: "Gilroy Semibold";
        font-size: 18px;
        margin-top: 105px;
        position: relative;
        display: inline-block; }
        @media only screen and (max-width: 992px) {
          .price .discount__value.mobile .title {
            margin-top: initial; } }
        @media only screen and (max-width: 576px) {
          .price .discount__value.mobile .title {
            font-size: 14px; } }
        .price .discount__value.mobile .title::before {
          content: " ";
          display: block;
          width: 100px;
          height: 1px;
          background-color: #5b5d59;
          position: absolute;
          top: -50px;
          left: 0; }
          @media only screen and (max-width: 992px) {
            .price .discount__value.mobile .title::before {
              display: none; } }
      .price .discount__value.mobile .value {
        display: inline-block;
        font-family: "Gilroy Bold";
        font-size: 55px;
        line-height: 42px;
        color: #89ae3b;
        position: relative;
        text-shadow: 0 0 15px #9abd3a; }
        @media only screen and (max-width: 576px) {
          .price .discount__value.mobile .value {
            font-size: 39px;
            line-height: 29px; } }
        .price .discount__value.mobile .value span {
          font-size: 42px; }
          @media only screen and (max-width: 576px) {
            .price .discount__value.mobile .value span {
              font-size: 29px; } }
    .price .discount__title {
      font-family: "Gilroy Bold";
      font-size: 30px;
      line-height: 60px;
      position: relative; }
      @media only screen and (max-width: 576px) {
        .price .discount__title {
          font-size: 21px;
          line-height: 42px; } }
    .price .discount__description {
      font-family: "Gilroy Regular";
      font-size: 18px;
      position: relative; }
      @media only screen and (max-width: 576px) {
        .price .discount__description {
          font-size: 14px; } }
    .price .discount .title {
      font-family: "Gilroy Semibold";
      font-size: 18px;
      margin-top: 105px;
      position: relative; }
      @media only screen and (max-width: 992px) {
        .price .discount .title {
          margin-top: initial; } }
      @media only screen and (max-width: 576px) {
        .price .discount .title {
          font-size: 14px;
          margin-top: 74px; } }
      .price .discount .title::before {
        content: " ";
        display: block;
        width: 100px;
        height: 1px;
        background-color: #5b5d59;
        position: absolute;
        top: -50px;
        left: 0; }
        @media only screen and (max-width: 992px) {
          .price .discount .title::before {
            display: none; } }
    .price .discount .value {
      margin-top: 40px;
      font-family: "Gilroy Bold";
      font-size: 100px;
      line-height: 60px;
      color: #89ae3b;
      position: relative;
      text-shadow: 0 0 15px #9abd3a; }
      @media only screen and (max-width: 576px) {
        .price .discount .value {
          margin-top: 28px;
          font-size: 70px;
          line-height: 42px; } }
      .price .discount .value span {
        font-size: 60px; }
        @media only screen and (max-width: 576px) {
          .price .discount .value span {
            font-size: 42px; } }
  .price__row {
    margin-top: 100px;
    display: flex; }
    @media only screen and (max-width: 992px) {
      .price__row {
        flex-wrap: wrap; } }
    .price__row .quiz {
      width: 700px;
      padding: 50px 0px 250px 50px;
      position: relative; }
      @media only screen and (max-width: 992px) {
        .price__row .quiz {
          width: 100%;
          padding: 35px 0 250px; } }
      @media only screen and (max-width: 576px) {
        .price__row .quiz {
          padding: 25px 0 175px; } }
      .price__row .quiz__description {
        font-family: "Gilroy Regular";
        font-size: 21px;
        line-height: 30px; }
        @media only screen and (max-width: 576px) {
          .price__row .quiz__description {
            font-size: 14px;
            line-height: 21px; } }
        .price__row .quiz__description span {
          display: block; }
        .price__row .quiz__description.bold {
          margin-top: 15px;
          font-family: "Gilroy Bold"; }
          @media only screen and (max-width: 576px) {
            .price__row .quiz__description.bold {
              margin-top: 11px; } }
      .price__row .quiz__question {
        margin-top: 20px;
        display: none; }
        @media only screen and (max-width: 576px) {
          .price__row .quiz__question {
            margin-top: 14px; } }
        .price__row .quiz__question.active {
          display: block; }
        .price__row .quiz__question span {
          display: block; }
        .price__row .quiz__question-text {
          font-size: 26px;
          line-height: 30px;
          font-family: "Gilroy Bold";
          color: #366437; }
          @media only screen and (max-width: 576px) {
            .price__row .quiz__question-text {
              font-size: 18px;
              line-height: 21px; } }
      .price__row .quiz__answers {
        font-family: "Gilroy Regular";
        font-size: 18px;
        color: #222321;
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px; }
        @media only screen and (max-width: 576px) {
          .price__row .quiz__answers {
            font-size: 14px;
            margin-top: 28px; } }
        .price__row .quiz__answers.multiple {
          position: relative;
          width: calc(100% + 80px); }
          .price__row .quiz__answers.multiple li {
            width: 355px; }
        .price__row .quiz__answers li {
          padding: 5px 20px 5px 40px;
          min-width: 143px;
          margin: 0 10px 10px 0;
          cursor: pointer;
          position: relative;
          display: flex;
          align-items: center;
          flex-wrap: wrap; }
          @media only screen and (max-width: 576px) {
            .price__row .quiz__answers li {
              padding: 5px 14px 5px 28px; } }
          .price__row .quiz__answers li::before, .price__row .quiz__answers li::after {
            content: "";
            display: block;
            width: 24px;
            height: 24px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%); }
            @media only screen and (max-width: 576px) {
              .price__row .quiz__answers li::before, .price__row .quiz__answers li::after {
                width: 17px;
                height: 17px; } }
          .price__row .quiz__answers li::before {
            border: 2px solid #bdccbd;
            transition: border-color .3s; }
          .price__row .quiz__answers li::after {
            background-repeat: no-repeat;
            background-image: url(../img/elements/icon-check.png);
            background-position: center;
            opacity: 0;
            transition: opacity .3s; }
          .price__row .quiz__answers li.active::before {
            border-color: #366437; }
          .price__row .quiz__answers li.active::after {
            opacity: 1; }
          .price__row .quiz__answers li:hover::before {
            border-color: #366437; }
          .price__row .quiz__answers li .what {
            background-color: #a5b6a5;
            font-family: "Athelas Bold";
            font-size: 18px;
            line-height: 22px;
            width: 22px;
            text-align: center;
            border-radius: 50%;
            display: inline-block;
            color: #fff;
            margin-left: 5px; }
            @media only screen and (max-width: 576px) {
              .price__row .quiz__answers li .what {
                font-size: 14px;
                line-height: 15px;
                width: 15px; } }
      .price__row .quiz__next {
        display: inline-block;
        padding-left: 30px;
        text-align: left;
        position: relative;
        font-size: 16px;
        border-radius: 25px; }
        @media only screen and (max-width: 576px) {
          .price__row .quiz__next {
            padding-left: 21px;
            font-size: 14px;
            border-radius: 18px; } }
        .price__row .quiz__next::after {
          content: " ";
          display: block;
          width: 10px;
          height: 10px;
          background-image: url(../img/elements/icon-arrow.png);
          position: absolute;
          right: 25px;
          top: 50%;
          transform: translateY(-50%); }
          @media only screen and (max-width: 576px) {
            .price__row .quiz__next::after {
              width: 10px;
              height: 10px;
              right: 18px; } }
      .price__row .quiz__bottom {
        padding: 20px 15px 20px 60px;
        width: 100%;
        position: absolute;
        bottom: 145px;
        left: 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap; }
        @media only screen and (max-width: 992px) {
          .price__row .quiz__bottom {
            padding: 14px 11px 14px 42px;
            bottom: 80px; } }
        @media only screen and (max-width: 576px) {
          .price__row .quiz__bottom {
            padding: 11px 11px 11px 29px;
            bottom: 30px; } }
      .price__row .quiz__current {
        display: flex;
        align-items: center; }
        .price__row .quiz__current span {
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #bdccbd;
          margin: 0 9px; }
          .price__row .quiz__current span.current {
            width: 50px;
            height: 50px;
            background-size: cover;
            background-color: transparent;
            position: relative; }
            @media only screen and (max-width: 576px) {
              .price__row .quiz__current span.current {
                width: 35px;
                height: 35px; } }
            .price__row .quiz__current span.current::after {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              font-family: "Gilroy Bold";
              font-size: 18px;
              line-height: 24px;
              color: #366437; }
              @media only screen and (max-width: 576px) {
                .price__row .quiz__current span.current::after {
                  font-size: 14px;
                  line-height: 17px; } }
          .price__row .quiz__current span.current:first-child {
            background-image: url(../img/elements/quiz-progress1.png); }
            .price__row .quiz__current span.current:first-child::after {
              content: "01"; }
          .price__row .quiz__current span.current:nth-child(2) {
            background-image: url(../img/elements/quiz-progress2.png); }
            .price__row .quiz__current span.current:nth-child(2)::after {
              content: "02"; }
          .price__row .quiz__current span.current:nth-child(3) {
            background-image: url(../img/elements/quiz-progress3.png); }
            .price__row .quiz__current span.current:nth-child(3)::after {
              content: "03"; }
      .price__row .quiz__form-text {
        font-family: "Gilroy Regular";
        font-size: 21px;
        line-height: 30px;
        margin: 45px 0; }
      .price__row .quiz .quiz__form--overlay {
        display: flex; }
        @media only screen and (max-width: 576px) {
          .price__row .quiz .quiz__form--overlay {
            flex-wrap: wrap; } }
        .price__row .quiz .quiz__form--overlay label {
          display: inline-block;
          position: relative; }
          @media only screen and (max-width: 1199px) {
            .price__row .quiz .quiz__form--overlay label {
              width: 50% !important; } }
          @media only screen and (max-width: 576px) {
            .price__row .quiz .quiz__form--overlay label {
              width: 100% !important; } }
          .price__row .quiz .quiz__form--overlay label span {
            position: absolute;
            top: -50%;
            transform: translateY(calc(50% - -6px));
            left: 35px;
            display: inline-block;
            font-family: "Gilroy Regular";
            font-size: 14px;
            line-height: 24px;
            color: #222321;
            background-color: #f3f8f0;
            padding: 0 10px; }
            @media only screen and (max-width: 576px) {
              .price__row .quiz .quiz__form--overlay label span {
                transform: translateY(50%); } }
          .price__row .quiz .quiz__form--overlay label input:not([type="submit"]) {
            display: block;
            width: 418px;
            max-width: 100%;
            height: 64px;
            border-radius: 32px;
            border: 1px solid #c8c8c7;
            font-family: "Gilroy Bold";
            font-size: 18px;
            line-height: 24px;
            color: #222321;
            padding: 0 40px;
            background-repeat: no-repeat;
            background-position: 20px center;
            background-color: #f3f8f0; }
            @media only screen and (max-width: 576px) {
              .price__row .quiz .quiz__form--overlay label input:not([type="submit"]) {
                height: 45px;
                width: 100%;
                font-size: 15px;
                line-height: 17px;
                padding: 0 33px;
                background-position: 14px center; } }
          .price__row .quiz .quiz__form--overlay label:first-child {
            width: 285px;
            max-width: 100%; }
            .price__row .quiz .quiz__form--overlay label:first-child input {
              background-image: url(../img/elements/icon-user.png);
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              border-right: none; }
              @media only screen and (max-width: 576px) {
                .price__row .quiz .quiz__form--overlay label:first-child input {
                  border-radius: 22.5px; } }
          .price__row .quiz .quiz__form--overlay label:nth-child(2) {
            width: 355px; }
            @media only screen and (max-width: 576px) {
              .price__row .quiz .quiz__form--overlay label:nth-child(2) {
                margin-top: 20px; } }
            .price__row .quiz .quiz__form--overlay label:nth-child(2) input:not([type="submit"]) {
              background-image: url(../img/elements/icon-telephone.png);
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              width: 100%; }
              @media only screen and (max-width: 576px) {
                .price__row .quiz .quiz__form--overlay label:nth-child(2) input:not([type="submit"]) {
                  border-radius: 22.5px; } }
          .price__row .quiz .quiz__form--overlay label input[type="submit"] {
            display: block; }
      .price__row .quiz .quiz__form-input {
        display: inline-block;
        position: relative;
        margin-top: 33px; }
        @media only screen and (max-width: 576px) {
          .price__row .quiz .quiz__form-input {
            margin-top: 23px; } }
        .price__row .quiz .quiz__form-input::after {
          content: " ";
          display: block;
          width: 10px;
          height: 10px;
          background-image: url(../img/elements/icon-arrow.png);
          position: absolute;
          right: 25px;
          top: 50%;
          transform: translateY(-50%); }
        .price__row .quiz .quiz__form-input input {
          display: block;
          font-size: 16px;
          height: 48px;
          width: 268px;
          border-radius: 24px;
          text-align: left;
          padding-left: 40px; }

.catalog {
  background-image: url(../img/elements/background-catalog.png);
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;
  padding: 135px 0 150px; }
  @media only screen and (max-width: 992px) {
    .catalog {
      padding: 95px 0 105px; } }
  @media only screen and (max-width: 576px) {
    .catalog {
      padding: 30px 0 50px; } }
  .catalog::before {
    content: " ";
    display: block;
    width: 100%;
    height: 633px;
    position: absolute;
    background: linear-gradient(to bottom, #618562 0%, #385f39 100%);
    top: 0;
    left: 0;
    z-index: -1; }
  .catalog__title--overlay {
    display: flex;
    justify-content: center; }
  .catalog__title {
    font-family: "Gilroy Bold";
    font-size: 36px;
    line-height: 48px;
    color: #fff;
    position: relative; }
    .catalog__title::before {
      content: " ";
      display: block;
      width: 58px;
      height: 4px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -85px; }
      @media only screen and (max-width: 576px) {
        .catalog__title::before {
          display: none; } }
    .catalog__title::after {
      content: " ";
      display: block;
      width: 58px;
      height: 4px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -85px; }
      @media only screen and (max-width: 576px) {
        .catalog__title::after {
          display: none; } }
    @media only screen and (max-width: 576px) {
      .catalog__title {
        font-size: 25px;
        text-align: center; } }
    .catalog__title span {
      padding: 0 9px;
      background-color: #fff;
      color: #366437; }
  .catalog__row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 56px; }
    @media only screen and (max-width: 992px) {
      .catalog__row {
        flex-wrap: wrap; } }
    @media only screen and (max-width: 576px) {
      .catalog__row {
        margin-top: initial; } }
  .catalog__item {
    width: calc(50% - 15px);
    background-color: #fff;
    box-shadow: 0px 15px 125px 0px rgba(30, 31, 34, 0.1);
    font-family: "Gilroy Bold";
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 40px;
    margin-top: 30px; }
    @media only screen and (max-width: 992px) {
      .catalog__item {
        width: 100%; } }
    @media only screen and (max-width: 576px) {
      .catalog__item {
        font-size: 14px;
        line-height: 17px;
        padding-bottom: 28px;
        margin-top: 21px; } }
    .catalog__item:nth-child(2n) {
      margin-left: 30px; }
      @media only screen and (max-width: 992px) {
        .catalog__item:nth-child(2n) {
          margin-left: initial; } }
    .catalog__item.top-sale {
      position: relative; }
      .catalog__item.top-sale::before {
        content: "Топ продаж";
        position: absolute;
        top: 15px;
        left: 15px;
        padding: 0 20px;
        font-size: 24px;
        line-height: 43px;
        font-family: "Gilroy Bold";
        background-color: #6CC81E;
        color: #fff;
        z-index: 1; }
        @media only screen and (max-width: 576px) {
          .catalog__item.top-sale::before {
            top: 11px;
            left: 11px;
            padding: 0 14px;
            font-size: 17px;
            line-height: 30px; } }
    .catalog__item.brand_of_year {
      position: relative; }
      .catalog__item.brand_of_year::after {
        content: " ";
        display: block;
        width: 79px;
        height: 79px;
        background-color: #fff;
        border-radius: 0 0 3px 39px;
        position: absolute;
        top: 0;
        right: 0;
        background-image: url(../img/elements/brand_year.png);
        background-size: 60px;
        background-repeat: no-repeat;
        background-position: center center; }
        @media only screen and (max-width: 576px) {
          .catalog__item.brand_of_year::after {
            width: 55px;
            height: 55px;
            background-size: 42px;
            border-radius: 0 0 3px 27px; } }
    .catalog__item-slider {
      margin: 15px 15px 0 15px !important;
      height: 350px;
      overflow: hidden;
      cursor: pointer; }
      @media only screen and (max-width: 576px) {
        .catalog__item-slider {
          margin: 11px 11px 0 11px !important;
          height: 245px; } }
      .catalog__item-slider .slick-list, .catalog__item-slider .slick-track {
        height: 100%; }
    .catalog__item-slide {
      display: block;
      width: 100%;
      height: 100%; }
      .catalog__item-slide img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .catalog__item .slick-arrow {
      background-size: cover;
      width: 15px;
      height: 28px;
      z-index: 1;
      opacity: 0.75;
      transition: opacity .3s; }
      @media only screen and (max-width: 576px) {
        .catalog__item .slick-arrow {
          width: 11px;
          height: 20px; } }
      .catalog__item .slick-arrow::before {
        content: " ";
        display: block;
        width: 117px;
        height: 180px;
        border-radius: 50%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
        position: absolute;
        top: -80px;
        left: -30px; }
        @media only screen and (max-width: 576px) {
          .catalog__item .slick-arrow::before {
            width: 82px;
            height: 126px;
            top: -56px;
            left: -21px; } }
      .catalog__item .slick-arrow:hover {
        opacity: 1; }
        .catalog__item .slick-arrow:hover::before {
          opacity: 0.75; }
      .catalog__item .slick-arrow.slick-prev {
        left: 20px;
        background-image: url(../img/elements/slider-prev.png); }
        @media only screen and (max-width: 576px) {
          .catalog__item .slick-arrow.slick-prev {
            left: 14px; } }
        .catalog__item .slick-arrow.slick-prev::before {
          left: initial;
          right: -30px;
          background: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%); }
          @media only screen and (max-width: 576px) {
            .catalog__item .slick-arrow.slick-prev::before {
              right: -21px; } }
      .catalog__item .slick-arrow:focus::before {
        opacity: 0.75; }
      .catalog__item .slick-arrow.slick-next {
        right: 20px;
        background-image: url(../img/elements/slider-next.png); }
        @media only screen and (max-width: 576px) {
          .catalog__item .slick-arrow.slick-next {
            right: 14px; } }
    .catalog__item .slick-dots {
      bottom: 13px; }
      @media only screen and (max-width: 576px) {
        .catalog__item .slick-dots {
          bottom: 11px; } }
      .catalog__item .slick-dots::before {
        content: " ";
        display: block;
        height: 117px;
        width: 209px;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
        border-radius: 50%;
        position: absolute;
        top: -15px;
        left: 50%;
        transform: translateX(-50%); }
        @media only screen and (max-width: 576px) {
          .catalog__item .slick-dots::before {
            height: 82px;
            width: 146px;
            top: -11px; } }
      .catalog__item .slick-dots li {
        margin: 0 1px; }
        .catalog__item .slick-dots li button::before {
          color: #fff;
          opacity: 1; }
        .catalog__item .slick-dots li.slick-active button::before {
          opacity: 1;
          color: #48916f; }
    .catalog__item-title {
      font-size: 26px;
      line-height: 33px;
      padding: 0 40px;
      margin-top: 20px; }
      @media only screen and (max-width: 576px) {
        .catalog__item-title {
          font-size: 18px;
          line-height: 23px;
          padding: 0 28px;
          margin-top: 14px; } }
      .catalog__item-title span {
        color: #366437; }
    .catalog__item-characteristics {
      margin-top: 15px;
      display: flex; }
      @media only screen and (max-width: 576px) {
        .catalog__item-characteristics {
          margin-top: 11px;
          display: block; } }
      .catalog__item-characteristics span {
        color: #366437; }
      .catalog__item-characteristics .left {
        width: 215px;
        padding-left: 70px;
        background-repeat: no-repeat;
        background-image: url(../img/elements/background-sizes.png);
        background-position: 25px 5px; }
        @media only screen and (max-width: 576px) {
          .catalog__item-characteristics .left {
            width: 151px;
            padding-left: 49px;
            background-position: 15px 5px; } }
      .catalog__item-characteristics .right {
        margin-left: 30px; }
        @media only screen and (max-width: 576px) {
          .catalog__item-characteristics .right {
            margin-left: initial;
            padding-left: 49px; } }
    .catalog__item ul.catalog__item-characteristics {
      margin-top: 20px;
      display: block;
      padding: 0 35px 0 70px;
      background-repeat: no-repeat;
      background-image: url(../img/elements/background-sizes.png);
      background-position: 25px 5px; }
      @media only screen and (max-width: 576px) {
        .catalog__item ul.catalog__item-characteristics {
          margin-top: 14px;
          padding: 0 25px 0 49px;
          background-position: 15px 5px; } }
      .catalog__item ul.catalog__item-characteristics span {
        color: #366437; }
    .catalog__item-delivery-and-installment {
      margin-top: 30px;
      display: flex;
      color: #fff;
      font-family: "Gilroy Semibold";
      font-size: 16px; }
      @media only screen and (max-width: 576px) {
        .catalog__item-delivery-and-installment {
          margin-top: 21px;
          font-size: 14px;
          flex-wrap: wrap; } }
    .catalog__item-delivery, .catalog__item-installment {
      padding: 20px 85px 20px 100px;
      background-repeat: no-repeat;
      position: relative;
      width: 50%; }
      @media only screen and (max-width: 1199px) {
        .catalog__item-delivery, .catalog__item-installment {
          padding: 20px 36px 20px 100px; } }
      @media only screen and (max-width: 576px) {
        .catalog__item-delivery, .catalog__item-installment {
          padding: 14px 60px 14px 70px;
          width: 100%; } }
      .catalog__item-delivery::after, .catalog__item-installment::after {
        content: " ";
        display: block;
        position: absolute;
        top: 50%;
        left: 40px;
        transform: translateY(-50%);
        background-size: cover; }
        @media only screen and (max-width: 576px) {
          .catalog__item-delivery::after, .catalog__item-installment::after {
            left: 28px; } }
    .catalog__item-delivery {
      background: linear-gradient(to bottom, #30825a 0%, #0a6337 100%);
      box-shadow: inset 0px -1px 5px 0px rgba(0, 0, 0, 0.23); }
      .catalog__item-delivery::after {
        background-image: url(../img/elements/icon-delivery-truck.png);
        width: 41px;
        height: 28px; }
        @media only screen and (max-width: 576px) {
          .catalog__item-delivery::after {
            width: 29px;
            height: 20px; } }
    .catalog__item-installment {
      background-color: #6CC81E; }
      .catalog__item-installment::after {
        background-image: url(../img/elements/icon-installment.png);
        width: 30px;
        height: 29px; }
        @media only screen and (max-width: 576px) {
          .catalog__item-installment::after {
            width: 21px;
            height: 20px; } }
    .catalog__item-form .title {
      display: inline-block;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      background-color: #6CC81E;
      color: #fff;
      font-family: "Gilroy Bold";
      font-size: 18px;
      line-height: 30px;
      padding: 0 8px;
      margin: 40px 0 20px; }
      @media only screen and (max-width: 576px) {
        .catalog__item-form .title {
          font-size: 14px;
          line-height: 21px;
          margin: 28px 0 14px;
          text-align: center; } }
    .catalog__item-form form {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center; }
      .catalog__item-form form ul {
        display: flex;
        margin-top: 15px; }
        @media only screen and (max-width: 576px) {
          .catalog__item-form form ul {
            margin-top: 11px; } }
        .catalog__item-form form ul li {
          cursor: pointer;
          width: 32px;
          height: 32px;
          border: 2px solid #b8cab8;
          text-align: center;
          line-height: 28px;
          border-radius: 50%;
          margin: 0 6px; }
          @media only screen and (max-width: 576px) {
            .catalog__item-form form ul li {
              width: 22px;
              height: 22px;
              line-height: 20px; } }
          .catalog__item-form form ul li.active {
            color: #fff;
            background: linear-gradient(to bottom, #5e825f 0%, #325b33 100%);
            border: 2px solid transparent; }
          .catalog__item-form form ul li:hover {
            border: 2px solid #366437; }
      .catalog__item-form form label {
        margin-top: 45px;
        display: inline-block;
        position: relative; }
        @media only screen and (max-width: 576px) {
          .catalog__item-form form label {
            width: 100%;
            padding: 0 15px;
            margin: 20px 0 0; } }
        .catalog__item-form form label span {
          position: absolute;
          top: -50%;
          transform: translateY(calc(50% - -3px));
          left: 35px;
          display: inline-block;
          font-family: "Gilroy Regular";
          font-size: 14px;
          line-height: 24px;
          color: #222321;
          background-color: #fff;
          padding: 0 10px; }
          @media only screen and (max-width: 576px) {
            .catalog__item-form form label span {
              left: 25px;
              line-height: 17px;
              padding: 0 7px;
              transform: translateY(calc(50% - -30px)); } }
        .catalog__item-form form label input:not([type="submit"]) {
          display: block;
          width: 418px;
          max-width: 100%;
          height: 56px;
          border-radius: 28px;
          border: 1px solid #c8c8c7;
          font-family: "Gilroy Bold";
          font-size: 18px;
          line-height: 24px;
          color: #222321;
          padding: 0 40px;
          background-image: url(../img/elements/icon-telephone.png);
          background-repeat: no-repeat;
          background-position: 20px center; }
          @media only screen and (max-width: 576px) {
            .catalog__item-form form label input:not([type="submit"]) {
              height: 39px;
              font-size: 14px;
              line-height: 17px;
              padding: 0 28px;
              background-position: 14px center; } }
        .catalog__item-form form label .catalog__item-input {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 6px; }
          @media only screen and (max-width: 576px) {
            .catalog__item-form form label .catalog__item-input {
              position: relative;
              top: initial;
              right: initial;
              margin-top: 15px;
              display: inline-block;
              transform: initial; } }
          .catalog__item-form form label .catalog__item-input::after {
            content: " ";
            display: block;
            width: 10px;
            height: 10px;
            background-image: url(../img/elements/icon-arrow.png);
            position: absolute;
            right: 25px;
            top: 50%;
            transform: translateY(-50%); }
          .catalog__item-form form label .catalog__item-input input {
            display: block;
            font-size: 14px;
            height: 42px;
            width: 176px;
            border-radius: 22px;
            text-align: left;
            padding: 0 0 0 25px; }
            @media only screen and (max-width: 576px) {
              .catalog__item-form form label .catalog__item-input input {
                margin: 0 auto; } }

.consultation {
  background-color: #f3f8f0;
  padding: 120px 0 150px;
  position: relative; }
  @media only screen and (max-width: 576px) {
    .consultation {
      padding: 50px 0; } }
  .consultation::after {
    content: " ";
    display: block;
    width: 616px;
    height: 630px;
    position: absolute;
    background-image: url(../img/elements/background-consultation.png);
    background-size: cover;
    position: absolute;
    right: 0;
    bottom: 0; }
    @media only screen and (max-width: 1699px) {
      .consultation::after {
        right: -100px; } }
    @media only screen and (max-width: 1599px) {
      .consultation::after {
        right: -150px; } }
    @media only screen and (max-width: 1499px) {
      .consultation::after {
        right: -200px; } }
    @media only screen and (max-width: 1399px) {
      .consultation::after {
        right: -222px; } }
    @media only screen and (max-width: 1199px) {
      .consultation::after {
        display: none; } }
  .consultation .section-title {
    max-width: 470px;
    line-height: 48px;
    position: relative; }
    @media only screen and (max-width: 576px) {
      .consultation .section-title {
        line-height: 34px; } }
    .consultation .section-title::after {
      content: " ";
      display: block;
      width: 58px;
      height: 4px;
      background-color: #6CC81E;
      position: absolute;
      left: 367px;
      bottom: 22px; }
      @media only screen and (max-width: 576px) {
        .consultation .section-title::after {
          display: none; } }
  .consultation .container {
    position: relative; }
    .consultation .container .quote {
      position: absolute;
      right: 98px;
      top: 0;
      text-align: right;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      z-index: 1; }
      @media only screen and (max-width: 1350px) {
        .consultation .container .quote {
          right: 150px; } }
      @media only screen and (max-width: 1250px) {
        .consultation .container .quote {
          right: 200px; } }
      @media only screen and (max-width: 1199px) {
        .consultation .container .quote {
          right: 0;
          position: initial;
          margin-bottom: 40px; } }
      @media only screen and (max-width: 576px) {
        .consultation .container .quote {
          display: block; } }
      .consultation .container .quote span {
        display: block;
        font-family: "Gilroy Bold";
        font-size: 18px;
        line-height: 36px;
        color: #121621;
        padding: 0 15px;
        background-color: #fff; }
        @media only screen and (max-width: 576px) {
          .consultation .container .quote span {
            font-size: 14px;
            line-height: 21px;
            text-align: left;
            background-color: transparent;
            padding: 0; } }
        .consultation .container .quote span:first-child {
          margin-bottom: 5px; }
          @media only screen and (max-width: 576px) {
            .consultation .container .quote span:first-child {
              margin-bottom: initial; } }
  .consultation__description {
    font-size: 21px;
    line-height: 30px;
    font-family: "Gilroy Regular";
    color: #222321;
    margin: 50px 0; }
    @media only screen and (max-width: 576px) {
      .consultation__description {
        font-size: 14px;
        line-height: 21px;
        margin: 35px 0; } }
  .consultation__form {
    display: flex; }
    @media only screen and (max-width: 768px) {
      .consultation__form {
        flex-wrap: wrap; } }
    .consultation__form label {
      display: inline-block;
      position: relative; }
      @media only screen and (max-width: 768px) {
        .consultation__form label {
          width: 100%; } }
      .consultation__form label span {
        position: absolute;
        top: -50%;
        transform: translateY(calc(50% - -3px));
        left: 35px;
        display: inline-block;
        font-family: "Gilroy Regular";
        font-size: 14px;
        line-height: 24px;
        color: #222321;
        background-color: #f3f8f0;
        padding: 0 10px; }
      .consultation__form label input:not([type="submit"]) {
        display: block;
        width: 418px;
        max-width: 100%;
        height: 64px;
        border-radius: 32px;
        border: 1px solid #c8c8c7;
        font-family: "Gilroy Bold";
        font-size: 18px;
        line-height: 24px;
        color: #222321;
        padding: 0 40px;
        background-repeat: no-repeat;
        background-position: 20px center;
        background-color: #f3f8f0; }
        @media only screen and (max-width: 768px) {
          .consultation__form label input:not([type="submit"]) {
            height: 45px;
            font-size: 15px;
            line-height: 17px;
            background-position: 14px center;
            border-radius: 22.5px;
            width: 100%; } }
      .consultation__form label:first-child {
        width: 225px;
        max-width: 100%; }
        @media only screen and (max-width: 768px) {
          .consultation__form label:first-child {
            width: 100%; } }
        .consultation__form label:first-child input {
          background-image: url(../img/elements/icon-user.png);
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: none; }
          @media only screen and (max-width: 768px) {
            .consultation__form label:first-child input {
              border-right: 1px solid #c8c8c7;
              border-radius: 22.5px; } }
        @media only screen and (max-width: 768px) {
          .consultation__form label:first-child span {
            transform: translateY(calc(50% - 2px)); } }
      .consultation__form label:nth-child(2) {
        width: 570px;
        max-width: 100%; }
        @media only screen and (max-width: 768px) {
          .consultation__form label:nth-child(2) {
            margin-top: 15px; } }
        .consultation__form label:nth-child(2) input:not([type="submit"]) {
          background-image: url(../img/elements/icon-telephone.png);
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          width: 100%; }
          @media only screen and (max-width: 768px) {
            .consultation__form label:nth-child(2) input:not([type="submit"]) {
              border-radius: 22.5px; } }
        @media only screen and (max-width: 768px) {
          .consultation__form label:nth-child(2) span {
            top: -12px;
            transform: initial; } }
      .consultation__form label input[type="submit"] {
        display: block; }
    .consultation__form-input {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px; }
      @media only screen and (max-width: 768px) {
        .consultation__form-input {
          position: relative;
          top: initial;
          right: initial;
          left: 50%;
          transform: translateX(-50%);
          margin-top: 15px;
          display: inline-block; } }
      .consultation__form-input::after {
        content: " ";
        display: block;
        width: 10px;
        height: 10px;
        background-image: url(../img/elements/icon-arrow.png);
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%); }
      .consultation__form-input input {
        display: block;
        font-size: 16px;
        height: 48px;
        width: 268px;
        border-radius: 24px;
        text-align: left;
        padding: 0 0 0 40px; }

.reviews {
  padding: 120px 0 150px;
  background-image: url(../img/elements/background-reviews.png);
  background-size: cover;
  background-color: #fdfdfd;
  overflow: hidden; }
  @media only screen and (max-width: 576px) {
    .reviews {
      padding: 60px 0 75px; } }
  .reviews .section-title {
    max-width: 320px;
    line-height: 48px;
    position: relative; }
    @media only screen and (max-width: 576px) {
      .reviews .section-title {
        line-height: 34px; } }
    .reviews .section-title::after {
      content: " ";
      display: block;
      width: 58px;
      height: 4px;
      background-color: #6CC81E;
      position: relative;
      bottom: -39px;
      left: 0; }
  .reviews__slider--overlay {
    margin-top: 105px;
    width: 470px;
    margin-left: auto;
    position: relative;
    display: flex;
    align-items: flex-end; }
    @media only screen and (max-width: 1199px) {
      .reviews__slider--overlay {
        margin-top: 275px; } }
    @media only screen and (max-width: 992px) {
      .reviews__slider--overlay {
        transform: translateX(32px);
        margin-top: 325px; } }
    @media only screen and (max-width: 768px) {
      .reviews__slider--overlay {
        margin-top: 75px;
        transform: initial;
        display: block;
        width: initial;
        margin-left: 40px; } }
    .reviews__slider--overlay .reviews__slider-content {
      position: absolute;
      width: 670px;
      left: -302px;
      top: -260px; }
      @media only screen and (max-width: 992px) {
        .reviews__slider--overlay .reviews__slider-content {
          left: -282px; } }
      @media only screen and (max-width: 768px) {
        .reviews__slider--overlay .reviews__slider-content {
          position: static;
          width: 100%; } }
      @media only screen and (max-width: 768px) {
        .reviews__slider--overlay .reviews__slider-content .slick-list {
          overflow: unset; } }
      @media only screen and (max-width: 768px) {
        .reviews__slider--overlay .reviews__slider-content-slide {
          box-shadow: 0px 10px 150px 0px rgba(54, 100, 55, 0.05); } }
      .reviews__slider--overlay .reviews__slider-content .text {
        background-color: #fff;
        font-family: "Gilroy Semibold";
        font-size: 16px;
        line-height: 27px;
        color: #222321;
        box-shadow: 0px 10px 150px 0px rgba(54, 100, 55, 0.05);
        padding: 45px 60px 60px;
        height: 240px; }
        @media only screen and (max-width: 992px) {
          .reviews__slider--overlay .reviews__slider-content .text {
            padding: 30px; } }
        @media only screen and (max-width: 768px) {
          .reviews__slider--overlay .reviews__slider-content .text {
            height: initial;
            width: 100%;
            box-shadow: none; } }
        @media only screen and (max-width: 576px) {
          .reviews__slider--overlay .reviews__slider-content .text {
            font-size: 14px;
            line-height: 19px;
            padding: 21px; } }
      .reviews__slider--overlay .reviews__slider-content .author {
        background-color: #fff;
        line-height: 26px;
        display: flex;
        align-items: center;
        box-shadow: 0px 10px 150px 0px rgba(54, 100, 55, 0.05);
        position: relative;
        padding: 0 0 60px 60px;
        max-width: 337px; }
        @media only screen and (max-width: 992px) {
          .reviews__slider--overlay .reviews__slider-content .author {
            padding: 0 0 30px 30px;
            max-width: 315px; } }
        @media only screen and (max-width: 768px) {
          .reviews__slider--overlay .reviews__slider-content .author {
            max-width: initial;
            box-shadow: none; } }
        @media only screen and (max-width: 576px) {
          .reviews__slider--overlay .reviews__slider-content .author {
            line-height: 18px;
            padding: 0 0 21px 21px; } }
        .reviews__slider--overlay .reviews__slider-content .author::after {
          content: " ";
          display: block;
          width: 100%;
          height: 40px;
          background-color: #fff;
          position: absolute;
          top: -40px;
          left: 0; }
          @media only screen and (max-width: 768px) {
            .reviews__slider--overlay .reviews__slider-content .author::after {
              display: none; } }
        .reviews__slider--overlay .reviews__slider-content .author .image-block {
          width: 63px;
          height: 63px;
          border-radius: 50%;
          border: 1px solid #ccd8cc;
          padding: 8px;
          background-color: #fff;
          margin-right: 20px; }
          .reviews__slider--overlay .reviews__slider-content .author .image-block img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            object-fit: cover; }
        .reviews__slider--overlay .reviews__slider-content .author .name {
          font-family: "Gilroy Bold";
          font-size: 14px;
          color: #9abc3a;
          text-transform: uppercase; }
        .reviews__slider--overlay .reviews__slider-content .author .where {
          font-family: "Gilroy Semibold";
          font-size: 14px;
          color: #b7c4b7; }
  .reviews__slider {
    position: relative;
    width: 415px;
    height: 245px;
    z-index: 1; }
  .reviews__slider-buttons {
    position: relative; }
    @media only screen and (max-width: 768px) {
      .reviews__slider-buttons {
        position: absolute;
        bottom: 0;
        left: -55px; } }
    .reviews__slider-buttons::after {
      content: " ";
      display: block;
      width: 35px;
      height: 1px;
      background-color: #595a59;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      transition: opacity .3s; }
    .reviews__slider-buttons:hover::after {
      opacity: 0; }
    .reviews__slider-buttons .reviews__slider-prev, .reviews__slider-buttons .reviews__slider-next {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 7px 11px;
      width: 55px;
      height: 60px;
      background-color: #222321;
      cursor: pointer;
      transition: background-color .3s;
      display: block; }
      .reviews__slider-buttons .reviews__slider-prev:hover, .reviews__slider-buttons .reviews__slider-next:hover {
        background-color: #6CC81E; }
    .reviews__slider-buttons .reviews__slider-prev {
      background-image: url(../img/elements/slider-prev.png); }
    .reviews__slider-buttons .reviews__slider-next {
      background-image: url(../img/elements/slider-next.png); }
  .reviews__slide {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative; }
    .reviews__slide::before {
      content: " ";
      display: block;
      width: 100%;
      height: 100%;
      background-color: #366437;
      opacity: 0.4;
      transition: opacity .3s;
      position: absolute;
      top: 0;
      left: 0; }
    .reviews__slide:hover::before {
      opacity: 0.75; }
    .reviews__slide::after {
      content: " ";
      display: block;
      width: 50px;
      height: 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      background-image: url(../img/elements/icon-play-button.png);
      background-size: cover; }
    .reviews__slide img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .reviews .left {
    position: relative; }
    @media only screen and (max-width: 992px) {
      .reviews .left {
        padding-right: 75px; } }
    .reviews .left::after {
      content: "’’";
      position: absolute;
      bottom: -265px;
      left: -7px;
      color: #222321;
      font-family: "Athelas Bold";
      font-size: 165px;
      line-height: 32px;
      letter-spacing: -.08em; }
      @media only screen and (max-width: 992px) {
        .reviews .left::after {
          bottom: 50%;
          right: 29px;
          left: initial;
          transform: translateY(calc(50% + 60px)); } }
    .reviews .left::before {
      content: " ";
      display: block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: #9abc3a;
      position: absolute;
      bottom: -215px;
      left: 10px; }
      @media only screen and (max-width: 992px) {
        .reviews .left::before {
          bottom: 50%;
          left: initial;
          right: 0;
          transform: translateY(50%); } }
  .reviews .slick-dots {
    bottom: -24px;
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 768px) {
      .reviews .slick-dots {
        bottom: 250px; } }
    .reviews .slick-dots li {
      margin: 0 1px; }
      .reviews .slick-dots li button::before {
        color: #a7b8a7;
        opacity: 1; }
      .reviews .slick-dots li.slick-active button::before {
        opacity: 1;
        color: #366437;
        font-size: 10px;
        position: relative;
        top: -4px; }

.photo {
  background-color: #fff;
  padding: 120px 0 215px;
  overflow: hidden; }
  @media only screen and (max-width: 576px) {
    .photo {
      padding: 60px 0 100px; } }
  .photo .section-title {
    line-height: 48px;
    max-width: 405px;
    text-align: center;
    margin: 0 auto;
    position: relative; }
    @media only screen and (max-width: 576px) {
      .photo .section-title {
        line-height: 34px; } }
    .photo .section-title::after {
      content: " ";
      display: block;
      width: 58px;
      height: 4px;
      background-color: #6CC81E;
      position: relative;
      bottom: -35px;
      left: 50%;
      transform: translateX(-50%); }
      @media only screen and (max-width: 576px) {
        .photo .section-title::after {
          bottom: -25px; } }
  .photo__slider--overlay {
    position: relative; }
    .photo__slider--overlay::before, .photo__slider--overlay::after {
      content: " ";
      display: block;
      width: 275px;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 1; }
      @media only screen and (max-width: 1199px) {
        .photo__slider--overlay::before, .photo__slider--overlay::after {
          display: none; } }
    .photo__slider--overlay::before {
      left: 0;
      background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%); }
    .photo__slider--overlay::after {
      right: 0;
      background: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0) 100%); }
  .photo__slider-buttons {
    position: absolute;
    top: 50%;
    left: -50px;
    transform: translateY(-50%);
    z-index: 2;
    display: flex;
    justify-content: space-between;
    width: calc(100% + 100px); }
    .photo__slider-buttons .photo__slider-prev, .photo__slider-buttons .photo__slider-next {
      width: 105px;
      height: 105px;
      cursor: pointer;
      border-radius: 50%;
      background: linear-gradient(to bottom, #30825a 0%, #0a6337 100%);
      box-shadow: inset 0 -1px 5px 0 rgba(0, 0, 0, 0.23);
      position: relative; }
      @media only screen and (max-width: 576px) {
        .photo__slider-buttons .photo__slider-prev, .photo__slider-buttons .photo__slider-next {
          width: 74px;
          height: 74px; } }
      .photo__slider-buttons .photo__slider-prev::after, .photo__slider-buttons .photo__slider-next::after {
        content: " ";
        display: block;
        width: 12px;
        height: 20px;
        background-size: cover;
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
    .photo__slider-buttons .photo__slider-prev::after {
      background-image: url(../img/elements/arrow-left-bold.png);
      right: 32px; }
      @media only screen and (max-width: 576px) {
        .photo__slider-buttons .photo__slider-prev::after {
          right: 8px; } }
    .photo__slider-buttons .photo__slider-next::after {
      background-image: url(../img/elements/arrow-right-bold.png);
      left: 32px; }
      @media only screen and (max-width: 576px) {
        .photo__slider-buttons .photo__slider-next::after {
          left: 8px; } }
  .photo__slider {
    margin: 135px auto 0;
    max-width: 970px; }
    @media only screen and (max-width: 1199px) {
      .photo__slider {
        margin-left: 15px;
        margin-right: 15px; } }
    @media only screen and (max-width: 576px) {
      .photo__slider {
        margin: 95px auto 0; } }
    .photo__slider .slick-list {
      margin: 0 -14px;
      overflow: unset; }
      @media only screen and (max-width: 576px) {
        .photo__slider .slick-list {
          margin: 0 -10px; } }
  .photo__slide {
    display: block;
    margin: 0 14px;
    height: 325px; }
    @media only screen and (max-width: 576px) {
      .photo__slide {
        height: 250px;
        margin: 0 10px; } }
    .photo__slide img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .photo .slick-dots {
    bottom: -72px; }
    .photo .slick-dots li {
      margin: 0 1px; }
      .photo .slick-dots li button::before {
        color: #a7b8a7;
        opacity: 1; }
      .photo .slick-dots li.slick-active button::before {
        opacity: 1;
        color: #366437;
        font-size: 10px;
        position: relative;
        top: 5px; }

.map {
  position: relative;
  background-color: #fff; }
  @media only screen and (max-width: 1800px) {
    .map {
      display: flex; } }
  @media only screen and (max-width: 768px) {
    .map {
      flex-direction: column-reverse;
      flex-wrap: wrap; } }
  .map__block {
    position: absolute;
    top: 0;
    left: 100px;
    width: 870px;
    height: 100%; }
    @media only screen and (max-width: 1800px) {
      .map__block {
        left: 40px;
        position: static;
        width: 50%; } }
    @media only screen and (max-width: 768px) {
      .map__block {
        width: 100%; } }
    .map__block::before, .map__block::after {
      content: " ";
      display: block;
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0; }
      @media only screen and (max-width: 1800px) {
        .map__block::before, .map__block::after {
          display: none; } }
    .map__block::before {
      left: 0;
      background-color: #fff; }
      @media only screen and (max-width: 1800px) {
        .map__block::before {
          display: none; } }
    .map__block::after {
      right: 0;
      background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%); }
      @media only screen and (max-width: 1800px) {
        .map__block::after {
          display: none; } }
    .map__block-content {
      position: relative;
      z-index: 1;
      max-width: 625px;
      padding: 145px 65px 150px; }
      @media only screen and (max-width: 992px) {
        .map__block-content {
          padding: 102px 65px 105px; } }
      @media only screen and (max-width: 768px) {
        .map__block-content {
          padding: 71px 46px 74px 75px; } }
      @media only screen and (max-width: 576px) {
        .map__block-content {
          padding: 25px 15px 35px 45px; } }
  .map iframe {
    width: 100%;
    height: 1040px; }
    @media only screen and (max-width: 1800px) {
      .map iframe {
        width: 50%; } }
    @media only screen and (max-width: 768px) {
      .map iframe {
        width: 100%;
        height: 400px; } }
  .map__title {
    font-family: "Gilroy Bold";
    font-size: 36px;
    line-height: 60px;
    color: #222321; }
    @media only screen and (max-width: 576px) {
      .map__title {
        font-size: 25px;
        line-height: 42px; } }
    .map__title span {
      position: relative; }
      .map__title span::after {
        content: " ";
        display: block;
        width: 58px;
        height: 4px;
        background-color: #6CC81E;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -80px; }
        @media only screen and (max-width: 576px) {
          .map__title span::after {
            display: none; } }
  .map__description {
    margin-top: 25px;
    font-family: "Gilroy Semibold";
    color: #1e1f22;
    font-size: 25px; }
    @media only screen and (max-width: 576px) {
      .map__description {
        margin-top: 18px;
        font-size: 18px; } }
  .map__item-title {
    font-family: "Gilroy Bold";
    font-size: 24px;
    line-height: 48px;
    color: #222;
    margin-top: 50px;
    position: relative; }
    @media only screen and (max-width: 576px) {
      .map__item-title {
        font-size: 17px;
        line-height: 34px;
        margin-top: 35px; } }
    .map__item-title::before, .map__item-title::after {
      content: " ";
      display: block;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      background: linear-gradient(to bottom, #30825a 0%, #0a6337 100%);
      box-shadow: 0px 0px 119px 0px rgba(54, 100, 55, 0.25);
      position: absolute;
      top: -8px;
      left: -97px;
      background-repeat: no-repeat;
      background-position: center center; }
      @media only screen and (max-width: 768px) {
        .map__item-title::before, .map__item-title::after {
          width: 46px;
          height: 46px;
          top: -11px;
          left: -68px; } }
      @media only screen and (max-width: 576px) {
        .map__item-title::before, .map__item-title::after {
          width: 32px;
          height: 32px;
          left: -40px;
          top: 0; } }
    @media only screen and (max-width: 576px) {
      .map__item-title::after {
        background-size: 12px; } }
    .map__item-title.locate::after {
      background-image: url(../img/elements/icon-locate.png); }
    .map__item-title.phone::after {
      background-image: url(../img/elements/icon-phone-white.png); }
    .map__item-title span {
      display: block;
      font-family: "Gilroy Semibold";
      font-size: 21px; }
      @media only screen and (max-width: 576px) {
        .map__item-title span {
          font-size: 15px; } }
  .map__phones {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    font-family: "Gilroy Semibold"; }
    @media only screen and (max-width: 576px) {
      .map__phones {
        font-size: 15px;
        flex-wrap: wrap;
        justify-content: center; } }
    .map__phones-code {
      color: #366437; }
    .map__phones ul {
      display: flex;
      margin-left: 15px; }
      @media only screen and (max-width: 576px) {
        .map__phones ul {
          margin-left: initial;
          display: block;
          width: 100%;
          text-align: center; } }
      .map__phones ul li:not(:first-child) {
        margin-left: 25px;
        position: relative; }
        @media only screen and (max-width: 576px) {
          .map__phones ul li:not(:first-child) {
            margin-left: initial; } }
        .map__phones ul li:not(:first-child)::before {
          content: "/";
          position: absolute;
          left: -17px; }
          @media only screen and (max-width: 576px) {
            .map__phones ul li:not(:first-child)::before {
              display: none; } }
  .map__shedule {
    font-size: 18px;
    line-height: 24px;
    font-family: "Gilroy Regular";
    color: #161915;
    background-repeat: no-repeat;
    background-image: url(../img/elements/icon-clock.png);
    background-position: left center;
    padding-left: 20px; }
    @media only screen and (max-width: 576px) {
      .map__shedule {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        display: inline-block; } }
    .map__shedule span {
      font-family: "Gilroy Semibold";
      display: inline; }
  .map .btn {
    display: inline-block;
    margin-top: 45px; }
    @media only screen and (max-width: 576px) {
      .map .btn {
        margin-top: 32px;
        left: 50%;
        transform: translateX(-50%); } }

.footer {
  background-color: #fff;
  padding: 35px 0; }
  .footer__row {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media only screen and (max-width: 1199px) {
      .footer__row {
        flex-wrap: wrap;
        justify-content: center; } }
  .footer__logo {
    max-width: 83px; }
    .footer__logo img {
      max-width: 100%; }
  .footer__name-and-description {
    margin-left: 20px; }
    @media only screen and (max-width: 1199px) {
      .footer__name-and-description {
        margin: 5px 20px; } }
  .footer__name {
    font-family: "Gilroy Bold";
    font-size: 18px;
    line-height: 22px;
    color: #161915; }
    .footer__name span {
      color: #366437; }
  .footer__description {
    font-size: 14px;
    color: #161915;
    font-family: "Gilroy Regular";
    max-width: 265px; }
  @media only screen and (max-width: 1199px) {
    .footer .btn {
      margin: 0 15px; } }
  .footer .btn:hover {
    color: #fff; }
  .footer__address {
    max-width: 200px;
    font-family: "Gilroy Regular";
    font-size: 14px;
    color: #161915; }
    @media only screen and (max-width: 1199px) {
      .footer__address {
        padding: 0 15px;
        margin: 5px 0; } }
  .footer__politics {
    color: #366437;
    font-size: 14px;
    font-family: "Gilroy Regular";
    position: relative; }
    @media only screen and (max-width: 1199px) {
      .footer__politics {
        padding: 0 15px;
        margin: 5px 0; } }
    .footer__politics::after {
      content: " ";
      display: block;
      width: 100%;
      height: 1px;
      border-top: 1px dotted #366437;
      position: absolute;
      bottom: -5px;
      left: 0; }
    .footer__politics:hover::after {
      border-top: 1px solid #366437; }

body.active, html.active {
  overflow-y: hidden; }

body.menu-active {
  padding-top: 65px; }

body {
  overflow-x: hidden; }

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px; }
  @media only screen and (max-width: 1200px) {
    .container {
      max-width: 960px; } }
  @media only screen and (max-width: 992px) {
    .container {
      max-width: 720px; } }
  @media only screen and (max-width: 768px) {
    .container {
      max-width: 540px; } }
  @media only screen and (max-width: 576px) {
    .container {
      max-width: 100%; } }

.btn {
  display: block;
  cursor: pointer;
  box-shadow: inset 0px -1px 1px 0px rgba(0, 0, 0, 0.23);
  background: linear-gradient(to bottom, #5a805b 0%, #346135 100%);
  font-family: "Gilroy Semibold";
  color: #fff;
  transition: box-shadow .3s;
  position: relative;
  font-size: 16px;
  padding: 18px 30px 18px 60px;
  border-radius: 27.5px; }
  @media only screen and (max-width: 576px) {
    .btn {
      padding: 13px 21px 13px 42px;
      border-radius: 22.5px;
      font-size: 14px; } }
  .btn span {
    position: relative;
    z-index: 1; }
  .btn:hover {
    box-shadow: none; }
    .btn:hover::before {
      opacity: 1; }
    .btn:hover span::after {
      opacity: 1;
      transform: translateY(0); }
  .btn::before {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 27.5px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #2a502b;
    opacity: 0;
    box-shadow: 0px 5px 10px 0px rgba(42, 80, 43, 0.4);
    transition: opacity .3s; }
  .btn--call {
    position: relative; }
    .btn--call::before {
      border-radius: 27.5px; }
    .btn--call::after {
      content: " ";
      display: block;
      width: 36px;
      height: 36px;
      background-color: #2a502b;
      background-image: url(../img/elements/icon-call.png);
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px; }
      @media only screen and (max-width: 576px) {
        .btn--call::after {
          width: 25px;
          height: 25px;
          left: 7px; } }
  .btn--watch {
    position: relative; }
    .btn--watch::before {
      border-radius: 27.5px; }
    .btn--watch::after {
      content: " ";
      display: block;
      width: 36px;
      height: 36px;
      background-color: #2a502b;
      background-image: url(../img/elements/icon-video-play-button.png);
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px; }
      @media only screen and (max-width: 576px) {
        .btn--watch::after {
          width: 25px;
          height: 25px;
          left: 7px; } }
  .btn--arrow {
    padding: 12.5px 43px;
    font-size: 14px;
    border-radius: 21px; }

.section-title {
  font-family: "Gilroy Bold";
  font-size: 36px;
  line-height: 60px;
  color: #222321; }
  @media only screen and (max-width: 576px) {
    .section-title {
      font-size: 25px;
      line-height: 42px; } }
  .section-title span {
    background-color: #6CC81E;
    color: #fff;
    padding: 0 12px;
    display: block; }
    @media only screen and (max-width: 576px) {
      .section-title span {
        padding: 0 8px; } }

.cookieAlert {
  position: fixed;
  bottom: 0px;
  z-index: 1000;
  background: white;
  width: 100%;
  padding: 10px;
  text-align: center;
  border-top: 3px solid #03af50; }

.cookieAlert a {
  text-decoration: underline !important; }

.cookieAlert button {
  border: none;
  border-radius: 15px;
  padding: 7px;
  background-color: #f52928;
  color: white;
  cursor: pointer;
  margin-left: 10px;
  -webkit-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out; }

.cookieAlert button:hover {
  background-color: red;
  -webkit-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out; }

.cookieAlert a:hover {
  cursor: pointer; }

.cookieAlert.disabled {
  display: none; }

.swal-title {
  font-family: "Gilroy Bold"; }

.swal-text {
  font-family: "Gilroy Regular"; }

.modal--overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  display: none;
  z-index: 2;
  overflow: auto; }
  .modal--overlay::before {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    background-image: url(../img/elements/background-price-smoke.png);
    background-size: cover;
    z-index: -1; }
  .modal--overlay.callModal .section-title {
    font-size: 24px;
    line-height: 30px;
    text-align: center; }
    @media only screen and (max-width: 992px) {
      .modal--overlay.callModal .section-title {
        font-size: 17px;
        line-height: 21px; } }
  .modal--overlay.whatModal .whatModal__row {
    display: flex;
    justify-content: space-between; }
    @media only screen and (max-width: 768px) {
      .modal--overlay.whatModal .whatModal__row {
        flex-wrap: wrap; } }
    .modal--overlay.whatModal .whatModal__row .left, .modal--overlay.whatModal .whatModal__row .right {
      width: calc(50% - 40px); }
      @media only screen and (max-width: 768px) {
        .modal--overlay.whatModal .whatModal__row .left, .modal--overlay.whatModal .whatModal__row .right {
          width: 100%; } }
    @media only screen and (max-width: 768px) {
      .modal--overlay.whatModal .whatModal__row .left {
        margin-top: 15px; } }
    .modal--overlay.whatModal .whatModal__row .left img {
      max-width: 100%; }
    @media only screen and (max-width: 768px) {
      .modal--overlay.whatModal .whatModal__row .right {
        margin-top: 15px; } }
    .modal--overlay.whatModal .whatModal__row .right .whatModal__title {
      font-family: "Gilroy Bold";
      font-size: 24px;
      color: #1e1f22; }
      @media only screen and (max-width: 768px) {
        .modal--overlay.whatModal .whatModal__row .right .whatModal__title {
          text-align: center; } }
      @media only screen and (max-width: 576px) {
        .modal--overlay.whatModal .whatModal__row .right .whatModal__title {
          font-size: 17px; } }
    .modal--overlay.whatModal .whatModal__row .right .whatModal__text {
      font-family: "Gilroy Regular";
      font-size: 18px;
      line-height: 30px;
      color: #222321;
      margin-top: 20px; }
      @media only screen and (max-width: 576px) {
        .modal--overlay.whatModal .whatModal__row .right .whatModal__text {
          margin-top: 14px;
          font-size: 14px;
          line-height: 21px; } }
  .modal--overlay.whatModal .modal {
    width: 1070px;
    max-width: calc(100% - 30px); }
  .modal--overlay .modal--wrapper {
    overflow: auto;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    max-height: calc(100vh - 200px);
    overflow: auto;
    max-width: calc(100% - 20px); }
  .modal--overlay .modal {
    background-color: #fff;
    padding: 60px;
    max-width: 605px;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%); }
    @media only screen and (max-width: 992px) {
      .modal--overlay .modal {
        width: 440px;
        max-width: calc(100% - 20px); } }
    @media only screen and (max-width: 992px) {
      .modal--overlay .modal {
        padding: 42px; } }
    @media only screen and (max-width: 576px) {
      .modal--overlay .modal {
        padding: 29px; } }
    @media only screen and (max-width: 480px) {
      .modal--overlay .modal {
        padding: 20px; } }
    .modal--overlay .modal__close {
      background-image: url(../img/elements/icon-close.svg);
      background-size: cover;
      position: absolute;
      right: 30px;
      top: 30px;
      width: 15px;
      height: 15px;
      transition: transform .3s;
      cursor: pointer; }
      @media only screen and (max-width: 576px) {
        .modal--overlay .modal__close {
          right: 15px;
          top: 15px; } }
      .modal--overlay .modal__close:hover {
        transform: rotate(180deg); }
    .modal--overlay .modal label {
      position: relative;
      display: block;
      margin-top: 29px; }
      @media only screen and (max-width: 992px) {
        .modal--overlay .modal label {
          margin-top: 20px; } }
      .modal--overlay .modal label span {
        font-family: "Gilroy Bold";
        font-size: 18px;
        line-height: 30px;
        color: #231d1f;
        padding: 0 13px;
        background-color: #fff;
        position: absolute;
        top: -15px;
        left: 11px;
        display: block;
        z-index: 1; }
        @media only screen and (max-width: 992px) {
          .modal--overlay .modal label span {
            font-size: 14px;
            line-height: 21px;
            padding: 0 9px;
            top: -11px;
            left: 8px; } }
    .modal--overlay .modal input {
      display: block;
      width: 100%; }
      .modal--overlay .modal input:not([type="submit"]) {
        height: 55px;
        padding: 0 24px;
        font-size: 18px;
        font-family: "Gilroy Regular";
        color: #231d1f;
        border: 1px solid #bdbcbd; }
        @media only screen and (max-width: 992px) {
          .modal--overlay .modal input:not([type="submit"]) {
            height: 39px;
            padding: 0 17px;
            font-size: 14px; } }
      .modal--overlay .modal input[type="submit"] {
        margin-top: 35px;
        text-align: center;
        padding-left: 15px;
        padding-right: 15px; }
        @media only screen and (max-width: 992px) {
          .modal--overlay .modal input[type="submit"] {
            margin-top: 25px;
            padding-left: 11px;
            padding-right: 11px; } }

#menu-btn {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  z-index: 777;
  display: none;
  align-items: center;
  cursor: pointer; }
  @media only screen and (max-width: 992px) {
    #menu-btn {
      display: flex; } }
  #menu-btn span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #6CC81E;
    position: relative;
    transition: background-color .3s; }
    #menu-btn span::before {
      content: " ";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: -8px;
      left: 0;
      background-color: #6CC81E;
      transition: transform .3s, top .3s; }
    #menu-btn span::after {
      content: " ";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: -8px;
      left: 0;
      background-color: #6CC81E;
      transition: transform .3s, bottom .3s; }
  #menu-btn.active span {
    background-color: transparent; }
    #menu-btn.active span::before {
      transform: rotate(45deg);
      top: -0px; }
    #menu-btn.active span::after {
      transform: rotate(-45deg);
      bottom: -0px; }
