@import 'normalize';

@import 'variebles';

@import 'fonts';
@import 'responce';
@import 'header';
@import 'home';
@import 'footer';

body.active, html.active {
    overflow-y: hidden;
}

body.menu-active {
    padding-top: 65px;
}

body {
    overflow-x: hidden;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    @include mediaw(1200px){
        max-width: 960px;
    }
    @include mediaw(992px){
        max-width: 720px;
    }
    @include mediaw(768px){
        max-width: 540px;
    }
    @include mediaw(576px){
        max-width: 100%;
    }
} 

.btn {
    display: block;
    cursor: pointer;
    box-shadow: inset 0px -1px 1px 0px rgba(0,0,0,0.23);
    background: linear-gradient(to bottom, rgba(90,128,91,1) 0%, rgba(52,97,53,1) 100%);
    font-family: $fontGilroySemibold;
    color: #fff;
    transition: box-shadow .3s;
    position: relative;
    font-size: 16px;
    padding: 18px 30px 18px 60px;
    border-radius: 27.5px;
    @include mediaw(576px) {
        padding: 13px 21px 13px 42px;
        border-radius: 22.5px;
        font-size: 14px;
    }
    span {
        position: relative;
        z-index: 1;
    }
    &:hover {
        box-shadow: none;
        &::before {
            opacity: 1;
        }
        span::after {
            opacity: 1;
            transform: translateY(0);
        }
    }
    &::before {
        content: " ";
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 27.5px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #2a502b;
        opacity: 0;
        box-shadow: 0px 5px 10px 0px rgba(42,80,43,0.4);
        transition: opacity .3s;
    }
    &--call {
        position: relative;
        &::before {
            border-radius: 27.5px;
        }
        &::after {
            content: " ";
            display: block;
            width: 36px;
            height: 36px;
            background-color: #2a502b;
            background-image: url(../img/elements/icon-call.png);
            background-repeat: no-repeat;
            background-position: center center;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 10px;
            @include mediaw(576px) {
                width: 25px;
                height: 25px;
                left: 7px;
            }
        }
    }
    &--watch {
        position: relative;
        &::before {
            border-radius: 27.5px;
        }
        &::after {
            content: " ";
            display: block;
            width: 36px;
            height: 36px;
            background-color: #2a502b;
            background-image: url(../img/elements/icon-video-play-button.png);
            background-repeat: no-repeat;
            background-position: center center;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 10px;
            @include mediaw(576px) {
                width: 25px;
                height: 25px;
                left: 7px;
            }
        }
    }
    &--arrow {
        padding: 12.5px 43px;
        font-size: 14px;
        border-radius: 21px;
    }
}

.section-title {
    font-family: $fontGilroyBold;
    font-size: 36px;
    line-height: 60px;
    color: #222321;
    @include mediaw(576px) {
        font-size: 25px;
        line-height: 42px;
    }
    span {
        background-color: $colorLightGreen;
        color: #fff;
        padding: 0 12px;
        display: block;
        @include mediaw(576px) {
            padding: 0 8px;
        }
    }
}

.cookieAlert {
    position: fixed;
    bottom: 0px;
    z-index: 1000;
    background: white;
    width: 100%;
    padding: 10px;
    text-align: center;
    border-top: 3px solid #03af50;
  }
  
  .cookieAlert a {
    text-decoration: underline !important;
  }
  
  .cookieAlert button {
    border: none;
    border-radius: 15px;
    padding: 7px;
    background-color: #f52928;
    color: white;
    cursor: pointer;
    margin-left: 10px;
    -webkit-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
  }
  
  .cookieAlert button:hover {
    background-color: red;
    -webkit-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
  }
  
  .cookieAlert a:hover {
    cursor: pointer;
  }
  
  .cookieAlert.disabled {
    display: none;
  }

  .swal-title {
      font-family: $fontGilroyBold;
  }
  .swal-text {
      font-family: $fontGilroyRegular;
  }

  .modal--overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    display: none;
    z-index: 2;
    overflow: auto;
    &::before {
        content: " ";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0, 0.8);
        background-image: url(../img/elements/background-price-smoke.png);
        background-size: cover;
        z-index: -1;
    }
    &.callModal {
        .section-title {
            font-size: 24px;
            line-height: 30px;
            text-align: center;
            @include mediaw(992px) {
                font-size: 17px;
                line-height: 21px;
            }
        }
    }
    &.whatModal {
        .whatModal__row {
            display: flex;
            justify-content: space-between;
            @include mediaw(768px) {
                flex-wrap: wrap;
            }
            .left, .right {
                width: calc(50% - 40px);
                @include mediaw(768px) {
                    width: 100%;
                }
            }
            .left {
                @include mediaw(768px) {
                    margin-top: 15px;
                }
                img {
                    max-width: 100%;
                }
            }
            .right {
                @include mediaw(768px) {
                    margin-top: 15px;
                }
                .whatModal__title {
                    font-family: $fontGilroyBold;
                    font-size: 24px;
                    color: #1e1f22;
                    @include mediaw(768px) {
                        text-align: center;
                    }
                    @include mediaw(576px) {
                        font-size: 17px;
                    }
                }
                .whatModal__text {
                    font-family: $fontGilroyRegular;
                    font-size: 18px;
                    line-height: 30px;
                    color: #222321;
                    margin-top: 20px;
                    @include mediaw(576px) {
                        margin-top: 14px;
                        font-size: 14px;
                        line-height: 21px;
                    }
                }
            }
        }
        .modal {
            width: 1070px;
            max-width: calc(100% - 30px);
        }
    }
    .modal--wrapper {
        overflow: auto;
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
        max-height: calc(100vh - 200px);
        overflow: auto;
        max-width: calc(100% - 20px);
    }
    .modal {
        background-color: #fff;
        padding: 60px;
        max-width: 605px;
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
        @include mediaw(992px) {
            width: 440px;
            max-width: calc(100% - 20px);
        }
        @include mediaw(992px) {
            padding: 42px;
        }
        @include mediaw(576px) {
            padding: 29px;
        }
        @include mediaw(480px) {
            padding: 20px;
        }
        &__close {
            background-image: url(../img/elements/icon-close.svg);
            background-size: cover;
            position: absolute;
            right: 30px;
            top: 30px;
            width: 15px;
            height: 15px;
            transition: transform .3s;
            cursor: pointer;
            @include mediaw(576px) {
                right: 15px;
                top: 15px;
            }
            &:hover {
                transform: rotate(180deg);
            }
        }
        label {
            position: relative;
            display: block;
            margin-top: 29px;
            @include mediaw(992px) {
                margin-top: 20px;
            }
            span {
                font-family: $fontGilroyBold;
                font-size: 18px;
                line-height: 30px;
                color: #231d1f;
                padding: 0 13px;
                background-color: #fff;
                position: absolute;
                top: -15px;
                left: 11px;
                display: block;
                z-index: 1;
                @include mediaw(992px) {
                    font-size: 14px;
                    line-height: 21px;
                    padding: 0 9px;
                    top: -11px;
                    left: 8px;
                }
            }
        }
        input {
            display: block;
            width: 100%;
            &:not([type="submit"]) {
                height: 55px;
                padding: 0 24px;
                font-size: 18px;
                font-family: $fontGilroyRegular;
                color: #231d1f;
                border: 1px solid #bdbcbd;
                @include mediaw(992px) {
                    height: 39px;
                    padding: 0 17px;
                    font-size: 14px;
                }
            }
            &[type="submit"] {
                margin-top: 35px;
                text-align: center;
                padding-left: 15px;
                padding-right: 15px;
                @include mediaw(992px) {
                    margin-top: 25px;
                    padding-left: 11px;
                    padding-right: 11px;
                }
            }
        }
    }
}

#menu-btn {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    z-index: 777;
    display: none;
    align-items: center;
    cursor: pointer;
    @include mediaw(992px) {
        display: flex;
    }
    span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: $colorLightGreen;
        position: relative;
        transition: background-color .3s;
        &::before {
            content: " ";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: -8px;
            left: 0;
            background-color: $colorLightGreen;
            transition: transform .3s, top .3s;
        }
        &::after {
            content: " ";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: -8px;
            left: 0;
            background-color: $colorLightGreen;
            transition: transform .3s, bottom .3s;
        }
    }
    &.active span {
        background-color: transparent;
        &::before {
            transform: rotate(45deg);
            top: -0px;
        }
        &::after {
            transform: rotate(-45deg);
            bottom: -0px;
        }
    }
}