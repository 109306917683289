.header {
    &__row {
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        padding: 15px 0;
        @include mediaw(768px) {
            padding: 11px 0;
        }
        @include mediaw(1199px) {
            flex-wrap: wrap;
        }
        .left {
            display: flex;
            align-items: center;
            @include mediaw(1199px) {
                margin: 0 auto;
            }
        }
        .right {
            display: flex;
            align-items: center;
            @include mediaw(1199px) {
                margin: 15px auto 0;
            }
            @include mediaw(768px) {
                flex-wrap: wrap;
                justify-content: center;
                margin: 11px auto 0;
            }
            @include mediaw(576px) {
                display: none;
            }
            @include mediaw(480px) {
                margin: 0 auto;
            }
            .btn {
                margin-left: 50px;
                @include mediaw(768px) {
                    margin: 5px auto 0;
                }
            }
        }
    }
    &__name-and-description {
        margin-left: 20px;
    }
    &__name {
        font-family: $fontGilroyBold;
        font-size: 18px;
        line-height: 22px;
        color: #161915;
        span {
            color: $colorGreen;
        }
    }
    &__description {
        font-size: 14px;
        color: #161915;
        font-family: $fontGilroyRegular;
        max-width: 265px;
    }
    &__phones-and-shedule {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @include mediaw(480px) {
            width: 100%;
            flex-direction: column;
            align-items: center;
        }
    }
    &__phones {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 18px;
        font-family: $fontGilroyRegular;
        @include mediaw(480px) {
            flex-wrap: wrap;
            justify-content: center;
        }
        &-code {
            font-family: $fontGilroySemibold;
            color: $colorGreen;
        }
        ul {
            display: flex;
            margin-left: 15px;
            @include mediaw(480px) {
                margin: 0;
                flex-wrap: wrap;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                text-align: center;
            }
            li {
                @include mediaw(480px) {
                    width: 100%;
                }
                &:not(:first-child) {
                    margin-left: 25px;
                    position: relative;
                    @include mediaw(480px) {
                        margin-left: 0;
                    }
                    &::before {
                        content: "/";
                        position: absolute;
                        left: -17px;
                        @include mediaw(480px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    &__shedule {
        text-align: right;
        font-size: 16px;
        line-height: 24px;
        font-family: $fontGilroyRegular;
        color: #161915;
        background-repeat: no-repeat;
        background-image: url(../img/elements/icon-clock.png);
        background-position: left center;
        padding-left: 20px;
        @include mediaw(480px) {
            text-align: center;
        }
        span {
            font-family: $fontGilroySemibold;
        }
    }
    &__menu--overlay {
        background-color: #222321;
        font-family: $fontGilroyBold;
        font-size: 16px;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        @include mediaw(992px) {
            text-align: left;
            position: fixed;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            display: none;
            z-index: 776;
            text-align: center;
            padding-top: 40px;
        }
        &.fixed {
            position: fixed;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 4;
            @include mediaw(992px) {
                position: initial;
            }
        }
        menu {
            display: flex;
            justify-content: center;
            @include mediaw(992px) {
                flex-wrap: wrap;
            }
            @include mediaw(576px) {
                display: none;
            }
            li {
                display: block;
                @include mediaw(992px) {
                    width: 100%;
                }
                a {
                    display: block;
                    padding: 23px 31px;
                    position: relative;
                    transition: background-color .3s;
                    @include mediaw(1208px) {
                        padding: 23px 11px;
                    }
                    @include mediaw(992px) {
                        display: inline-block;
                    }
                    @include mediaw(992px) {
                        padding: 15px 22px;
                    }
                    &:hover {
                        background-color: $colorLightGreen;
                    }
                    &::before {
                        content: " ";
                        display: block;
                        width: 1px;
                        height: 100%;
                        background-color: #3e3f3e;
                        position: absolute;
                        top: 0;
                        left: 0;
                        @include mediaw(992px) {
                            display: none;
                        }
                    }
                }
                &:last-child a::after {
                    content: " ";
                    display: block;
                    width: 1px;
                    height: 100%;
                    background-color: #3e3f3e;
                    position: absolute;
                    top: 0;
                    right: 0;
                    @include mediaw(992px) {
                        display: none;
                    }
                }
            }
            &.mobile {
                display: none;
                @include mediaw(576px) {
                    display: block;
                }
                .header__shedule {
                    color: #fff;
                }
                .header__phones-code {
                    color: #fff;
                }
                .btn {
                    display: inline-block;
                    margin: 10px 0;
                }
                .header__phones-and-shedule .header__phones {
                    margin: 65px 0 10px;
                }
                .header__phones-and-shedule a {
                    padding: 4px 15px;
                }
            }
        }
    }
    &__content {
        background-image: url(../img/elements/background-header.png);
        background-size: cover;
        height: calc(100vh - 166px);
        position: relative;
        padding-top: 115px;
        @include mediaw(1100px) {
            padding-top: 25px;
        }
        @include mediaw(992px) {
            height: initial;;
            padding-top: 81px;
        }
        @include mediaw(576px) {
            padding-top: 57px;
            background-image: url(../img/elements/background-catalog.png);
            background-repeat: no-repeat;
            background-position: left top;
        }
        @include mediah(820px) {
            padding-top: 25px;
            height: initial;
            min-height: 500px;
        }
        &::after {
            content: " ";
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.2);
            position: absolute;
            top: 0;
            left: 0;
            @include mediaw(992px) {
                background-color: rgba(0,0,0,.5);
            }
            @include mediaw(576px) {
                display: none;
            }
        }
        .container {
            position: relative;
            z-index: 1;
            height: 100%;
        }
    }
    &__title {
        color: #fff;
        font-family: $fontGilroyBold;
        font-size: 48px;
        line-height: 60px;
        position: relative;
        @include mediaw(1100px) {
            font-size: 41px;
        }
        @include mediaw(992px) {
            font-size: 34px;
            line-height: 42px;
        }
        @include mediaw(576px) {
            font-size: 24px;
            line-height: 29px;
            text-align: center;
        }
        span:first-child {
            @include mediaw(576px) {
                display: block;
                margin-bottom: 5px;
            }
        }
        span:nth-child(2) {
            display: inline-block;
            background-color: $colorLightGreen;
            padding: 0 15px;
            @include mediaw(1199px) {
                display: initial;
            }
            @include mediaw(576px) {
                padding: 0 2px;
            }
        }
        &::before {
            content: " ";
            display: block;
            width: 3px;
            height: 38px;
            background-color: $colorLightGreen;
            position: absolute;
            bottom: -80px;
            right: 268px;
            z-index: 1;
            @include mediaw(1199px) {
                bottom: -60px;
            }
        }
        &::after {
            content: '*Обладатель премии "Бренд Года 2018"';
            display: block;
            max-width: 165px;
            position: absolute;
            bottom: -80px;
            right: 83px;
            font-family: $fontGilroySemibold;
            font-size: 16px;
            line-height: 20px;
            @include mediaw(1199px) {
                bottom: -60px;
            }
        }
    }
    &__bottom {
        position: absolute;
        bottom: -75px;
        left: 0;
        width: 100%;
        padding: 0 15px;
        @include mediaw(992px) {
            position: initial;
            transform: translateY(80px);
        }
        @include mediaw(576px) {
            padding: 0;
        }
        @include mediah(820px) {
            position: initial;
            padding: 0;
        }
        &-row {
            display: flex;
            align-items: center;
            @include mediaw(992px) {
                flex-wrap: wrap;
                flex-direction: column-reverse;
            }
            .left {
                width: 578px;
                max-width: 100%;
                background-color: #fff;
                padding: 65px 70px 75px;
                box-shadow: 0px 15px 150px 0px rgba(54,100,55,0.15);
                @include mediaw(768px) {
                    padding: 46px 49px 53px;
                }
                @include mediaw(576px) {
                    padding: 15px;
                }
                p {
                    font-family: $fontGilroyRegular;
                    font-size: 16px;
                    line-height: 24px;
                    color: #222321;
                    margin-bottom: 30px;
                    @include mediaw(576px) {
                        margin-bottom: 21px;
                    }
                    &:nth-child(3) {
                        margin-bottom: 0;
                    }
                    &.color {
                        background-color: $colorLightGreen;
                        color: #fff;
                        font-family: $fontGilroyBold;
                        display: inline-block;
                        margin-top: 4px;
                        padding: 0 5px;
                    }
                }
            }
            .right {
                margin-left: 45px;
                font-family: $fontGilroySemibold;
                font-size: 18px;
                color: #fff;
                @include mediaw(992px) {
                    margin-left: 0;
                }
                @include mediaw(576px) {
                    font-size: 14px;
                }
                ul {
                    @include mediaw(576px) {
                        margin-bottom: 15px;
                    }
                    li {
                        padding: 15px 0 15px 60px;
                        background-repeat: no-repeat;
                        background-position: left center;
                        @include mediaw(992px) {
                            padding: 11px 0 11px 42px;
                        }
                        &.delivery {
                            background-image: url(../img/elements/icon-delivery-truck.png);
                            background-size: 38px;
                        }
                        &.garancy {
                            background-image: url(../img/elements/icon-garancy.png);
                            background-position-x: 7px;
                        }
                        &.storage {
                            background-image: url(../img/elements/icon-warehouse.png);
                            background-position-x: 6px;
                            background-size: 29px;
                        }
                        &.installment {
                            background-image: url(../img/elements/icon-installment.png);
                            background-position-x: 7px;
                            background-size: 29px;
                        }
                        &.price {
                            background-image: url(../img/elements/icon-price.png);
                            background-position-x: 7px;
                            background-size: 33px;
                            background-color: transparent;
                        }
                    }
                }
            }
        }
        &-title {
            font-family: $fontGilroyBold !important;
            font-size: 26px !important;
            color: #222321;
        }
    }
    &__form {
        display: block;
        label {
            display: inline-block;
            position: relative;
            max-width: 100%;
            span {
                position: absolute;
                top: -50%;
                transform: translateY(calc(50% - -3px));
                left: 35px;
                display: inline-block;
                font-family: $fontGilroyRegular;
                font-size: 14px;
                line-height: 24px;
                color: #222321;
                background-color: #fff;
                padding: 0 10px;
                @include mediaw(576px) {
                    left: 25px;
                    line-height: 17px;
                    padding: 0 7px;
                    transform: initial;
                    top: -8px;
                }
            }
            input:not([type="submit"]) {
                display: block;
                width: 418px;
                max-width: 100%;
                height: 56px;
                border-radius: 28px;
                border: 1px solid #c8c8c7;
                font-family: $fontGilroyBold;
                font-size: 18px;
                line-height: 24px;
                color: #222321;
                padding: 0 40px;
                background-image: url(../img/elements/icon-telephone.png);
                background-repeat: no-repeat;
                background-position: 20px center;
                @include mediaw(576px) {
                    height: 39px;
                    padding: 0 28px;
                    background-position: 14px center;
                    font-size: 15px;
                }
            }
            input[type="submit"] {
                display: block;
            }
        }
        &-input {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 6px;
            @include mediaw(576px) {
                position: relative;
                top: initial;
                right: initial;
                left: 50%;
                transform: translateX(-50%);
                display: inline-block;
                margin: 10px auto 0;
            }
            &::after {
                content: " ";
                display: block;
                width: 10px;
                height: 10px;
                background-image: url(../img/elements/icon-arrow.png);
                position: absolute;
                right: 25px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}