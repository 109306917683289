.footer {
    background-color: #fff;
    padding: 35px 0;
    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include mediaw(1199px) {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    &__logo {
        max-width: 83px;
        img {
            max-width: 100%;
        }
    }
    &__name-and-description {
        margin-left: 20px;
        @include mediaw(1199px) {
            margin: 5px 20px;
        }
    }
    &__name {
        font-family: $fontGilroyBold;
        font-size: 18px;
        line-height: 22px;
        color: #161915;
        span {
            color: $colorGreen;
        }
    }
    &__description {
        font-size: 14px;
        color: #161915;
        font-family: $fontGilroyRegular;
        max-width: 265px;
    }
    .btn {
        @include mediaw(1199px) {
            margin: 0 15px;
        }
        &:hover {
            color: #fff;
        }
    }
    &__address {
        max-width: 200px;
        font-family: $fontGilroyRegular;
        font-size: 14px;
        color: #161915;
        @include mediaw(1199px) {
            padding: 0 15px;
            margin: 5px 0;
        }
    }
    &__politics {
        color: $colorGreen;
        font-size: 14px;
        font-family: $fontGilroyRegular;
        position: relative;
        @include mediaw(1199px) {
            padding: 0 15px;
            margin: 5px 0;
        }
        &::after {
            content: " ";
            display: block;
            width: 100%;
            height: 1px;
            border-top: 1px dotted $colorGreen;
            position: absolute;
            bottom: -5px;
            left: 0;
        }
        &:hover::after {
            border-top: 1px solid $colorGreen;
        }
    }
}