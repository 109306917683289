.advantages {
    background-color: #ffffff;
    padding-top: 205px;
    padding: 205px 0 145px;
    overflow: hidden;
    @include mediaw(768px) {
        padding-top: 140px;
    }
    @include mediaw(576px) {
        padding-top: 100px;
    }
    .section-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        @include mediaw(992px) {
            display: block;
            position: initial;
        }
        @include mediaw(576px) {
            line-height: 30px;
        }
        &::after {
            content: " ";
            display: block;
            width: 58px;
            height: 4px;
            background-color: $colorLightGreen;
            position: absolute;
            left: 375px;
            bottom: 26px;
            @include mediaw(992px) {
                display: none;
            }
        }
        span {
            @include mediaw(992px) {
                display: inline;
            }
        }
    }
    &__quote {
        font-family: $fontGilroyBold;
        font-size: 25px;
        color: #222321;
        margin: 40px 0 0 70px;
        position: relative;
        z-index: 1;
        @include mediaw(576px) {
            margin: 28px 0 0 49px;
            font-size: 18px;
        }
        &::before {
            content: " ";
            display: block;
            width: 100px;
            height: 100px;
            background-color: #e6eece;
            border-radius: 50%;
            position: absolute;
            bottom: -50px;
            left: -60px;
            z-index: -1;
            @include mediaw(576px) {
                width: 70px;
                height: 70px;
                bottom: -35px;
                left: -42px;
            }
        }
        &::after {
            content: "’’";
            position: absolute;
            bottom: -100px;
            left: -73px;
            color: #222321;
            font-family: $fontAthelasBold;
            font-size: 165px;
            line-height: 32px;
            letter-spacing: -.08em;
            @include mediaw(576px) {
                bottom: -70px;
                left: -51px;
                font-size: 116px;
                line-height: 22px;
            }
        }
    }
    &__slider--overlay {
        position: relative;
    }
    &__slider {
        margin-top: 70px;
        @include mediaw(992px) {
            margin-top: 100px;
        }
        @include mediaw(576px) {
            margin-top: 35px;
        }
        .slick-arrow {
            width: 105px;
            height: 105px;
            cursor: pointer;
            border-radius: 50%;
            background: linear-gradient(to bottom,#30825a 0%,#0a6337 100%);
            box-shadow: inset 0 -1px 5px 0 rgba(0,0,0,.23);
            z-index: 1;
            @include mediaw(576px) {
                width: 74px;
                height: 74px;
            }
            &::after {
                content: " ";
                display: block;
                width: 12px;
                height: 20px;
                background-size: cover;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
            &.slick-prev {
                left: -50px;
                &::after {
                    background-image: url(../img/elements/arrow-left-bold.png);
                    right: 32px;
                    @include mediaw(576px) {
                        right: 8px;
                    }
                }
            }
            &.slick-next {
                right: -50px;
                &::after {
                    background-image: url(../img/elements/arrow-right-bold.png);
                    left: 32px;
                    @include mediaw(576px) {
                        left: 8px;
                    }
                }
            }
            &.slick-next::before, &.slick-prev::before {
                display: none;
            }
        }
    }
    .slick-track {
        display: flex;
        align-items: flex-end;
        @include mediaw(992px) {
            align-items: center;
        }
    }
    &__slide {
        &--first {
            position: relative;
            @include mediaw(1505px) {
                transform: translateX(-150px);
            }
            @include mediaw(1399px) {
                transform: translateX(0);
            }
            @include mediaw(992px) {
                padding-bottom: 30px;
            }
            &::before {
                content: " ";
                display: block;
                width: 222px;
                height: 100%;
                background: linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                @include mediaw(992px) {
                    display: none;
                }
            }
            // &::after { // градиент слева, для первого слайдера
            //     content: " ";
            //     display: block;
            //     width: 222px;
            //     height: 100%;
            //     background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
            //     position: absolute;
            //     top: 0;
            //     right: 0;
            //     z-index: 1;
            // }
            .advantages__slide-row {
                display: flex;
                align-items: flex-end;
                @include mediaw(992px) {
                    display: block;
                }
                .right {
                    width: 700px;
                    max-width: 100%;
                    padding-left: 220px;
                    @include mediaw(1505px) {
                        padding-bottom: 30px;
                        padding-left: 170px;
                    }
                    @include mediaw(1399px) {
                        padding-right: 66px;
                    }
                    @include mediaw(992px) {
                        width: 100%;
                        margin-top: 15px;
                        padding: 0 15px;
                    }
                }
                .left {
                    min-height: 628px;
                    @include mediaw(1399px) {
                        width: 490px;
                    }
                    @include mediaw(992px) {
                        min-height: initial;
                        height: 500px;
                        width: 100%;
                        overflow: hidden;
                    }
                    @include mediaw(576px) {
                        height: 300px;
                    }
                    .image-block {
                        position: relative;
                        min-height: 628px;
                        @include mediaw(992px) {
                            min-height: initial;
                        }
                        @include mediaw(992px) {
                            max-height: 400px;
                        }
                        @include mediaw(576px) {
                            max-height: 300px;
                        }
                        img {
                            max-width: 100%;
                            min-height: 628px;
                            object-fit: cover;
                            @include mediaw(1399px) {
                                width: 100%;
                            }
                            @include mediaw(992px) {
                                min-height: initial;
                            }
                            &.cropped {
                                display: none;
                                @include mediaw(1399px) {
                                    display: block;
                                }
                                @include mediaw(992px) {
                                    display: none;
                                }
                                @include mediaw(576px) {
                                    display: block;
                                }
                            }
                            &.full {
                                @include mediaw(1399px) {
                                    display: none;
                                }
                                @include mediaw(992px) {
                                    display: block;
                                }
                                @include mediaw(576px) {
                                    display: none;
                                }
                            }
                        }
                        #video-play-btn {
                            display: block;
                            width: 296px;
                            height: 296px;
                            border-radius: 50%;
                            background-size: cover;
                            background-image: url(../img/elements/background-play-video-btn.png);
                            position: absolute;
                            right: -148px;
                            top: 0;
                            cursor: pointer;
                            transition: background-color .3s;
                            @include mediaw(992px) {
                                left: 0;
                            }
                            @include mediaw(576px) {
                                width: 100px;
                                height: 100px;
                                bottom: 0;
                                left: 0;
                                top: initial;
                            }
                            &::before {
                                content: " ";
                                display: block;
                                width: 177px;
                                height: 177px;
                                border-radius: 50%;
                                border: 10px solid $colorGreen;
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translateX(-50%) translateY(-50%);
                                transition: border-color .3s;
                                @include mediaw(576px) {
                                    width: 60px;
                                    height: 60px;
                                    border-width: 4px;
                                }
                            }
                            &::after {
                                content: " ";
                                display: block;
                                width: 0;
                                height: 0;
                                border-style: solid;
                                border-width: 16px 0 16px 24px;
                                border-color: transparent transparent transparent $colorGreen;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translateX(calc(-50% + 4px)) translateY(-50%);
                                transition: border-color .3s;
                            }
                            &:hover {
                                background-color: $colorGreen;
                                &::before {
                                    border-color: #fff;
                                }
                                &::after {
                                    border-color: transparent transparent transparent #fff;
                                }
                            }
                        }
                        .whois {
                            position: absolute;
                            right: 20px;
                            transform: translateX(100%);
                            bottom: 140px;
                            width: 175px;
                            @include mediaw(992px) {
                                right: initial;
                                left: -100px;
                                bottom: -55px;
                            }
                            @include mediaw(576px) {
                                display: flex;
                                flex-direction: column;
                                right: 0;
                                bottom: 15px;
                                text-align: center;
                                left: initial;
                                transform: initial;
                            }
                            .title {
                                font-family: $fontGilroyRegular;
                                font-size: 16px;
                                line-height: 24px;
                                color: #222321;
                                padding-left: 45px;
                                @include mediaw(576px) {
                                    font-size: 14px;
                                    line-height: 17px;
                                    padding-left: initial;
                                }
                            }
                            .name {
                                display: inline-block;
                                text-align: center;
                                position: relative;
                                left: 50%;
                                transform: translateX(-50%);
                                margin: 25px 0;
                                font-family: $fontGilroyBold;
                                font-size: 21px;
                                line-height: 42px;
                                background-color: $colorGreen;
                                color: #fff;
                                padding: 0 15px;
                                @include mediaw(576px) {
                                    margin: 10px 0;
                                    font-size: 15px;
                                    line-height: 29px;
                                    padding: 0 11px;
                                }
                            }
                            .position {
                                font-family: $fontGilroyBold;
                                font-size: 21px;
                                color: $colorGreen;
                                text-align: center;
                                @include mediaw(576px) {
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }
            }
            .advantages__slide-title {
                display: flex;
                flex-direction: column;
                align-items: baseline;
                @include mediaw(992px) {
                    align-items: center;
                }
                span {
                    display: block;
                    font-family: $fontGilroyBold;
                    font-size: 24px;
                    line-height: 45px;
                    color: #fff;
                    background-color: $colorLightGreen;
                    line-height: 40px;
                    padding: 0 10px;
                    @include mediaw(576px) {
                        font-size: 17px;
                        line-height: 32px;
                        line-height: 28px;
                    }
                    &:first-child {
                        margin-bottom: 6px;
                        @include mediaw(576px) {
                            margin-bottom: 5px;
                        }
                    }
                }
            }
            .advantages__slide-item {
                padding-left: 35px;
                @include mediaw(1199px) {
                    padding-left: initial;
                }
                p {
                    font-family: $fontGilroyRegular;
                    font-size: 17px;
                    line-height: 24px;
                    color: #222321;
                    margin-top: 25px;
                    @include mediaw(576px) {
                        font-size: 14px;
                        line-height: 17px;
                        margin-top: 18px;
                    }
                    &.number {
                        font-family: $fontGilroyBold;
                        font-size: 60px;
                        line-height: 60px;
                        color: #89ae3b;
                        text-shadow: 0px 3px 1px rgba(108, 135, 46,0.23);
                        position: relative;
                        margin-top: 85px;
                        @include mediaw(576px) {
                            font-size: 42px;
                            line-height: 42px;
                            margin-top: 60px;
                        }
                        .big {
                            font-size: 100px;
                            @include mediaw(576px) {
                                font-size: 70px;
                            }
                        }
                        .absolute {
                            color: #222321;
                            font-family: $fontGilroySemibold;
                            font-size: 21px;
                            line-height: 30px;
                            position: absolute;
                            top: -15px;
                            left: 73px;
                            text-shadow: none;
                            @include mediaw(576px) {
                                font-size: 15px;
                                line-height: 21px;
                                top: -11px;
                                left: 51px;
                            }
                        }
                    }
                }
                &--second .absolute {
                    left: 120px !important;
                    @include mediaw(576px) {
                        left: 84px !important;
                    }
                }
            }
        }
        &--second--overlay {
            text-align: center;
            display: flex;
            justify-content: center;
            padding: 75px 0 105px;
            @include mediaw(1199px) {
                transform: scale(0.80);
            }
            @include mediaw(992px) {
                transform: scale(.6);
            }
            @include mediaw(768px) {
                transform: initial;
            }
            .main-image {
                position: relative;
                @include mediaw(768px) {
                    position: initial;
                    max-width: 100%;
                }
                img {
                    display: inline-block;
                    max-width: 100%; 
                    @include mediaw(768px) {
                        max-width: 100%;
                    }
                }
            }
            .content {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @include mediaw(768px) {
                    position: initial;
                }
                .item {
                    position: absolute;
                    @include mediaw(768px) {
                        position: initial;
                        width: 100% !important;
                        text-align: left !important;
                    }
                    &::before {
                        content: " ";
                        display: block;
                        width: 72px;
                        height: 72px;
                        border-radius: 50%;
                        background-color: #d1e39e;
                        position: absolute;
                        @include mediaw(768px) {
                            display: none;
                        }
                    }
                    &::after {
                        display: block;
                        width: 44px;
                        height: 44px;
                        background-color: #8aab30;
                        border-radius: 50%;
                        position: absolute;
                        color: #fff;
                        font-family: $fontGilroyBold;
                        font-size: 24px;
                        line-height: 44px;
                        text-align: center;
                        @include mediaw(768px) {
                            display: none;
                        }
                    }
                    p {
                        font-family: $fontGilroyRegular;
                        color: #222321;
                        font-size: 18px;
                        position: relative;
                        @include mediaw(576px) {
                            font-size: 14px;
                        }
                        &::before, &::after {
                            content: " ";
                            background-color: #d1e39e;
                            position: absolute;
                            @include mediaw(768px) {
                                display: none;
                            }
                        }
                        span {
                            display: block;
                            font-family: $fontGilroyBold;
                            color: $colorGreen;
                            @include mediaw(768px) {
                                display: initial;
                            }
                        }
                    }
                    &:first-child {
                        width: 105px;
                        left: 230px;
                        top: -47px;
                        text-align: left;
                        &::after {
                            left: -139px;
                            bottom: -62px;
                            content: "1";
                        }
                        &::before {
                            left: -153px;
                            bottom: -76px;
                        }
                        p::before {
                            top: 11px;
                            left: -70px;
                            width: 50px;
                            height: 1px;
                        }
                        p::after {
                            width: 60px;
                            height: 1px;
                            top: 35px;
                            left: -118px;
                            transform: rotate(-53deg);
                        }
                    }
                    &:nth-child(2) {
                        width: 255px;
                        left: 420px;
                        top: -79px;
                        &::before {
                            left: 88px;
                            bottom: -132px;
                        }
                        &::after {
                            content: "2";
                            left: 102px;
                            bottom: -118px;
                        }
                        p::before {
                            height: 44px;
                            width: 1px;
                            left: calc(50% - 3px);
                            transform: translateX(-50%);
                            bottom: -61px;
                        }
                    }
                    &:nth-child(3) {
                        width: 170px;
                        right: -100px;
                        top: -20px;
                        text-align: right;
                        &::before {
                            left: -141px;
                            bottom: -72px;
                        }
                        &::after {
                            content: "3";
                            left: -127px;
                            bottom: -58px;
                        }
                        p::before {
                            top: 11px;
                            left: -50px;
                            width: 50px;
                            height: 1px;
                        }
                        p::after {
                            width: 60px;
                            height: 1px;
                            top: 32px;
                            left: -101px;
                            transform: rotate(-45deg);
                        }
                    }
                    &:nth-child(4) {
                        width: 215px;
                        text-align: left;
                        right: -110px;
                        bottom: -42px;
                        &::before {
                            left: 33px;
                            top: -154px;
                        }
                        &::after {
                            content: "4";
                            left: 47px;
                            top: -140px;
                        }
                        p::before {
                            top: -40px;
                            left: 5px;
                            width: 1px;
                            height: 25px;
                        }
                        p::after {
                            width: 67px;
                            height: 1px;
                            top: -65px;
                            left: -7px;
                            transform: rotate(-50deg);
                        }
                    }
                    &:nth-child(5) {
                        bottom: -22px;
                        right: 155px;
                        width: 335px;
                        &::before {
                            left: 125px;
                            top: -158px;
                        }
                        &::after {
                            content: "5";
                            left: 139px;
                            top: -144px;
                        }
                        p::before {
                            height: 80px;
                            width: 1px;
                            left: calc(50% - 5px);
                            transform: translateX(-50%);
                            top: -90px;
                        }
                    }
                    &:nth-child(6) {
                        width: 305px;
                        left: -30px;
                        bottom: -30px;
                        text-align: right;
                        &::before {
                            right: -105px;
                            top: -63px;
                        }
                        &::after {
                            content: "6";
                            right: -91px;
                            top: -49px;
                        }
                        p::before {
                            height: 1px;
                            width: 14px;
                            right: -25px;
                            top: 11px;
                        }
                        p::after {
                            width: 35px;
                            height: 1px;
                            top: -2px;
                            right: -55px;
                            transform: rotate(-45deg);
                        }
                    }
                    &:nth-child(7) {
                        width: 100px;
                        left: -95px;
                        top: 105px;
                        text-align: left;
                        &::before {
                            right: -57px;
                            bottom: -90px;
                        }
                        &::after {
                            content: "7";
                            right: -43px;
                            bottom: -76px;
                        }
                        p::before {
                            height: 1px;
                            width: 30px;
                            right: 15px;
                            bottom: -55px;
                        }
                        p::after {
                            width: 50px;
                            height: 1px;
                            bottom: -32px;
                            right: 30px;
                            transform: rotate(66deg);
                        }
                    }
                }
            }
        }
        &--third {
            padding-bottom: 205px;
            @include mediaw(768px) {
                padding-bottom: initial;
            }
            @include mediaw(576px) {
                padding-bottom: 15px;
            }
            .advantages__slide--third-row {
                display: flex;
                @include mediaw(768px) {
                    flex-wrap: wrap;
                }
            }
            .advantages__slide--third-item {
                width: 33.3334%;
                padding: 37px 15px 0;
                text-align: center;
                color: #222321;
                @include mediaw(768px) {
                    width: 100%;
                }
                &:not(:first-child) {
                    @include mediaw(768px) {
                        margin-top: 20px;
                    }
                    @include mediaw(576px) {
                        margin-top: 14px;
                    }
                }
                .image-block {
                    width: 130px;
                    height: 130px;
                    border-radius: 50%;
                    background: linear-gradient(to bottom, rgba(172,201,93,1) 0%, rgba(136,170,56,1) 100%);
                    box-shadow: inset 0px -1px 1px 0px rgba(0,0,0,0.23);
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    @include mediaw(768px) {
                        width: 90px;
                        height: 90px;
                    }
                    &::before {
                        content: " ";
                        display: block;
                        width: 203px;
                        height: 102px;
                        background-image: url(../img/elements/duga-slider.png);
                        background-repeat: no-repeat;
                        position: absolute;
                        top: -37px;
                        left: -37.5px;
                        @include mediaw(768px) {
                            width: 140px;
                            height: 70px;
                            background-size: contain;
                            top: -26px;
                            left: -26px;
                        }
                    }
                }
                .title {
                    font-size: 30px;
                    font-family: $fontGilroySemibold;
                    margin-top: 35px;
                    @include mediaw(768px) {
                        font-size: 21px;
                        margin-top: 25px;
                    }
                }
                .description {
                    margin-top: 5px;
                    font-family: $fontGilroyRegular;
                    font-size: 18px;
                    line-height: 30px;
                    @include mediaw(768px) {
                        font-size: 14px;
                        line-height: 21px;
                    }
                    span {
                        font-family: $fontGilroyBold;
                        display: block;
                    }
                }
            }
        }
        &--fifth {
            position: relative;
            padding: 40px 0 35px;
            &::before {
                content: " ";
                display: block;
                width: 100%;
                height: 123px;
                background-color: $colorLightGreen;
                position: absolute;
                bottom: 0;
                left: 0;
            }
            &-row {
                display: flex;
                justify-content: space-between;
                @include mediaw(1199px) {
                    flex-wrap: wrap;
                }
                .left {
                    width: 550px;
                    @include mediaw(1199px) {
                        width: 100%;
                    }
                    .title {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        @include mediaw(1199px) {
                            display: block;
                        }
                        span {
                            background-color: $colorLightGreen;
                            color: #fff;
                            font-family: $fontGilroyBold;
                            font-size: 24px;
                            line-height: 41px;
                            display: block;
                            padding: 0 13px;
                            @include mediaw(1199px) {
                                line-height: 29px;
                                padding: 0;
                                display: initial;
                            }
                            @include mediaw(576px) {
                                font-size: 20px;
                                line-height: 25px;
                            }
                            &:first-child {
                                margin-bottom: 6px;
                            }
                        }
                    }
                    ul {
                        margin: 25px 0;
                        font-family: $fontGilroySemibold;
                        font-size: 18px;
                        color: #222321;
                        @include mediaw(576px) {
                            margin: 18px 0;
                            font-size: 15px;
                        }
                        span {
                            color: $colorLightGreen;
                        }
                    }
                    .kits {
                        display: flex;
                        flex-wrap: wrap;
                        .image-block {
                            margin: 0 30px 30px 0;
                            width: 150px;
                            height: 178px;
                            border: 10px solid #fff;
                            box-shadow: 0px 10px 25px 0px rgba(54,100,55,0.25);
                            background-color: #e4e9e4;
                            position: relative;
                            cursor: pointer;
                            @include mediaw(1199px) {
                                margin: 0 5px 30px 0;
                            }
                            @include mediaw(576px) {
                                margin: 0 5px 10px 0;
                                border-width: 10px;
                            }
                            @include mediaw(509px) {
                                width: 100px;
                                height: 100px;
                            }
                            &::after {
                                content: " ";
                                display: block;
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                background-color: $colorGreen;
                                background-image: url(../img/elements/icon-zoom-in.png);
                                background-position: center;
                                background-repeat: no-repeat;
                                opacity: 0;
                                transition: opacity .3s;
                            }
                            &:hover::after {
                                opacity: 1;
                            }
                            &:nth-child(3n) {
                                margin-right: 0;
                                @include mediaw(1199px) {
                                    margin-right: 5px;
                                }
                            }
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
                .right {
                    width: calc(100% - 550px);
                    @include mediaw(1199px) {
                        width: 100%;
                        margin-top: 20px;
                    }
                    @include mediaw(576px) {
                        margin-top: 14px;
                    }
                    p {
                        max-width: 475px;
                        font-family: $fontGilroyBold;
                        font-size: 30px;
                        line-height: 48px;
                        @include mediaw(1199px) {
                            max-width: initial;
                            font-size: 21px;
                            line-height: 34px;
                        }
                        @include mediaw(576px) {
                            font-size: 15px;
                            line-height: 20px;
                        }
                        span {
                            color: $colorGreen;
                        }
                    }
                    .certificates {
                        display: flex;
                        margin-top: 39px;
                        @include mediaw(1199px) {
                            margin-top: 20px;
                        }
                        @include mediaw(576px) {
                            margin-top: 15px;
                        }
                        .image-block {
                            width: 300px;
                            height: 425px;
                            border: 10px solid #fff;
                            box-shadow: 0px 10px 25px 0px rgba(54,100,55,0.25);
                            background-color: #e4e9e4;
                            position: relative;
                            cursor: pointer;
                            @include mediaw(576px) {
                                height: 150px;
                                border-width: 7px;
                            }
                            &::after {
                                content: " ";
                                display: block;
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                background-color: $colorGreen;
                                background-image: url(../img/elements/icon-zoom-in.png);
                                background-position: center;
                                background-repeat: no-repeat;
                                opacity: 0;
                                transition: opacity .3s;
                            }
                            &:hover::after {
                                opacity: 1;
                            }
                            &:first-child {
                                margin-right: 15px;
                            }
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
        }
        &--sixth {
            padding-bottom: 60px;
            &__row {
                display: flex;
                @include mediaw(768px) {
                    flex-wrap: wrap;
                }
            }
            .title {
                text-align: center;
                font-family: $fontGilroyBold;
                font-size: 24px;
                color: #222321;
                margin-bottom: 40px;
                @include mediaw(576px) {
                    font-size: 18px;
                    margin-bottom: 28px;
                }
            }
            .video {
                display: block;
                cursor: pointer;
                position: relative;
                width: 420px;
                height: 500px;
                @include mediaw(1199px) {
                    width: 300px;
                }
                @include mediaw(992px) {
                    width: 250px;
                }
                @include mediaw(768px) {
                    width: 500px;
                    max-width: 100%;
                    margin: 0 auto;
                    height: 350px;
                }
                @include mediaw(576px) {
                    height: 250px;
                }
                &::before {
                    content: " ";
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: $colorGreen;
                    opacity: 0.4;
                    transition: opacity .3s;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                &:hover::before {
                    opacity: 0.75;
                }
                &::after {
                    content: " ";
                    display: block;
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    background-image: url(../img/elements/icon-play-button.png);
                    background-size: cover;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .photos {
                width: calc(70% - 30px);
                display: flex;
                flex-wrap: wrap;
                margin-left: 30px;
                @include mediaw(1199px) {
                    width: calc(100% - 320px);
                    margin-left: 20px;
                }
                @include mediaw(992px) {
                    width: calc(100% - 270px);
                }
                @include mediaw(768px) {
                    width: 100%;
                    margin-left: initial;
                    margin-top: 20px;
                }
                .image-block {
                    width: calc(33.3334% - 6.6667px);
                    margin-bottom: 10px;
                    height: 245px;
                    position: relative;
                    cursor: pointer;
                    @include mediaw(576px) {
                        height: 150px;
                    }
                    @include mediaw(350px) {
                        height: 100px;
                    }
                    &::after {
                        content: " ";
                        display: block;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: $colorGreen;
                        background-image: url(../img/elements/icon-zoom-in.png);
                        background-position: center;
                        background-repeat: no-repeat;
                        opacity: 0;
                        transition: opacity .3s;
                    }
                    &:hover::after {
                        opacity: 1;
                    }
                    &:first-child, &:nth-child(2), &:nth-child(4), &:nth-child(5) {
                        margin-right: 10px;
                    }
                    &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                        margin-bottom: 0;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
    .slick-dots {
        background-color: $colorGreen;
        bottom: -175px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mediaw(992px) {
            display: block;
        }
        li {
            width: initial;
            height: initial;
            max-width: 190px;
            height: 175px;
            margin: initial;
            padding: 55px 25px;
            position: relative;
            display: flex;
            @include mediaw(992px) {
                width: 100%;
                max-width: initial;
                padding: 5px 15px;
                height: initial;
                display: flex;
                justify-content: center;
            }
            &::before {
                content: " ";
                display: block;
                height: 100%;
                width: 1px;
                background-color: #477149;
                position: absolute;
                top: 0;
                right: 0;
                @include mediaw(992px) {
                    display: none;
                }
            }
            &:first-child {
                &::after {
                    content: " ";
                    display: block;
                    height: 100%;
                    width: 1px;
                    background-color: #477149;
                    position: absolute;
                    top: 0;
                    left: 0;
                    @include mediaw(992px) {
                        display: none;
                    }
                }
            }
            button {
                width: initial;
                height: initial;
                padding: initial;
                &::before {
                    text-align: center;
                    position: static;
                    width: initial;
                    height: initial;
                    font-family: $fontGilroyBold;
                    font-size: 18px;
                    color: #fff;
                    @include mediaw(576px) {
                        font-size: 17px;
                    }
                }
            }
            &.slick-active button::before {
                opacity: 1;
            }
            &:first-child button::before {
                content: "Расширенная гарантия качества";
            }
            &:nth-child(2) button::before {
                content: "Лучшие комплектующие";
            }
            &:nth-child(3) button::before {
                content: "Честные цены и сезонные скидки";
            }
            &:nth-child(4) button::before {
                content: "Доставка, установка, хранение";
            }
            &:nth-child(5) button::before {
                content: "Все официальные документы";
            }
            &:nth-child(6) button::before {
                content: "Собственное производство";
            }
        }
    }
}

.price {
    padding: 140px 0 0;
    background-color: #f3f8f0;
    overflow: hidden;
    .section-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        span {
            position: relative;
            &::after {
                content: " ";
                display: block;
                width: 58px;
                height: 4px;
                background-color: $colorLightGreen;
                position: absolute;
                right: -88px;
                bottom: 28px;
            }
        }
    }
    .discount {
        color: #fff;
        line-height: 30px;
        position: relative;
        padding: 85px 110px 140px 0;
        max-width: 470px;
        @include mediaw(992px) {
            width: 100%;
            padding: 30px 0 30px 0;
            color: #222321;
        }
        @include mediaw(576px) {
            padding: 21px 0;
        }
        &::before {
            content: " ";
            display: block;
            width: 5469px;
            height: 100%;
            background-image: url(../img/elements/background-price-smoke.png);
            position: absolute;
            left: -5000px;
            top: 0;
            background-color: #222321;
            background-position-x: right;
            @include mediaw(1199px) {
                width: 5385px;
            }
            @include mediaw(992px) {
                display: none;
            }
            // @include mediaw(992px) {
            //     left: initial;
            //     right: -500px;
            // }
        }
        &__value {
            @include mediaw(992px) {
                display: none;
            }
            &.mobile {
                display: none;
                margin: 15px 0;
                padding: 15px 0;
                width: 100%;
                position: relative;
                color: #fff;
                &::before {
                    content: " ";
                    display: block;
                    width: 10000px;
                    height: 100%;
                    position: absolute;
                    left: -5000px;
                    top: 0;
                    background-color: #222321;
                }
                @include mediaw(992px) {
                    display: block;
                }
                .title {
                    font-family: $fontGilroySemibold;
                    font-size: 18px;
                    margin-top: 105px;
                    position: relative;
                    display: inline-block;
                    @include mediaw(992px) {
                        margin-top: initial;
                    }
                    @include mediaw(576px) {
                        font-size: 14px;
                        // margin-top: 74px;
                    }
                    &::before {
                        content: " ";
                        display: block;
                        width: 100px;
                        height: 1px;
                        background-color: #5b5d59;
                        position: absolute;
                        top: -50px;
                        left: 0;
                        @include mediaw(992px) {
                            display: none;
                        }
                    }
                }
                .value {
                    display: inline-block;
                    font-family: $fontGilroyBold;
                    font-size: 55px;
                    line-height: 42px;
                    color: #89ae3b;
                    position: relative;
                    text-shadow: 0 0 15px rgba(154,189,58,1);
                    @include mediaw(576px) {
                        font-size: 39px;
                        line-height: 29px;
                    }
                    span {
                        font-size: 42px;
                        @include mediaw(576px) {
                            font-size: 29px;
                        }
                    }
                }
            }
        }
        &__title {
            font-family: $fontGilroyBold;
            font-size: 30px;
            line-height: 60px;
            position: relative;
            @include mediaw(576px) {
                font-size: 21px;
                line-height: 42px;
            }
        }
        &__description {
            font-family: $fontGilroyRegular;
            font-size: 18px;
            position: relative;
            @include mediaw(576px) {
                font-size: 14px;
            }
        }
        .title {
            font-family: $fontGilroySemibold;
            font-size: 18px;
            margin-top: 105px;
            position: relative;
            @include mediaw(992px) {
                margin-top: initial;
            }
            @include mediaw(576px) {
                font-size: 14px;
                margin-top: 74px;
            }
            &::before {
                content: " ";
                display: block;
                width: 100px;
                height: 1px;
                background-color: #5b5d59;
                position: absolute;
                top: -50px;
                left: 0;
                @include mediaw(992px) {
                    display: none;
                }
                // @include mediaw(576px) {
                //     top: -35px;
                //     width: 70px;
                // }
            }
        }
        .value {
            margin-top: 40px;
            font-family: $fontGilroyBold;
            font-size: 100px;
            line-height: 60px;
            color: #89ae3b;
            position: relative;
            text-shadow: 0 0 15px rgba(154,189,58,1);
            @include mediaw(576px) {
                margin-top: 28px;
                font-size: 70px;
                line-height: 42px;
            }
            span {
                font-size: 60px;
                @include mediaw(576px) {
                    font-size: 42px;
                }
            }
        }
    }
    &__row {
        margin-top: 100px;
        display: flex;
        @include mediaw(992px) {
            flex-wrap: wrap;
        }
        .quiz {
            width: 700px;
            padding: 50px 0px 250px 50px;
            position: relative;
            @include mediaw(992px) {
                width: 100%;
                padding: 35px 0 250px;
            }
            @include mediaw(576px) {
                padding: 25px 0 175px;
            }
            &__description {
                font-family: $fontGilroyRegular;
                font-size: 21px;
                line-height: 30px;
                @include mediaw(576px) {
                    font-size: 14px;
                    line-height: 21px;
                }
                span {
                    display: block;
                }
                &.bold {
                    margin-top: 15px;
                    font-family: $fontGilroyBold;
                    @include mediaw(576px) {
                        margin-top: 11px;
                    }
                }
            }
            &__question {
                margin-top: 20px;
                display: none;
                @include mediaw(576px) {
                    margin-top: 14px;
                }
                &.active {
                    display: block;
                }
                span {
                    display: block;
                }
                &-text {
                    font-size: 26px;
                    line-height: 30px;
                    font-family: $fontGilroyBold;
                    color: $colorGreen;
                    @include mediaw(576px) {
                        font-size: 18px;
                        line-height: 21px;
                    }
                }
            }
            &__answers {
                font-family: $fontGilroyRegular;
                font-size: 18px;
                color: #222321;
                display: flex;
                flex-wrap: wrap;
                margin-top: 40px;
                @include mediaw(576px) {
                    font-size: 14px;
                    margin-top: 28px;
                }
                &.multiple {
                    position: relative;
                    width: calc(100% + 80px);
                    li {
                        width: 355px;
                    }
                }
                li {
                    padding: 5px 20px 5px 40px;
                    min-width: 143px;
                    margin: 0 10px 10px 0;
                    cursor: pointer;
                    position: relative;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    @include mediaw(576px) {
                        padding: 5px 14px 5px 28px;
                    }
                    &::before, &::after {
                        content: "";
                        display: block;
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        @include mediaw(576px) {
                            width: 17px;
                            height: 17px;
                        }
                    }
                    &::before {
                        border: 2px solid #bdccbd;
                        transition: border-color .3s;
                    }
                    &::after {
                        background-repeat: no-repeat;
                        background-image: url(../img/elements/icon-check.png);
                        background-position: center;
                        opacity: 0;
                        transition: opacity .3s;
                    }
                    &.active {
                        &::before {
                            border-color: $colorGreen;
                        }
                        &::after {
                            opacity: 1;
                        }
                    }   
                    &:hover {
                        &::before {
                            border-color: $colorGreen;
                        }
                    }
                    .what {
                        background-color: #a5b6a5;
                        font-family: $fontAthelasBold;
                        font-size: 18px;
                        line-height: 22px;
                        width: 22px;
                        text-align: center;
                        border-radius: 50%;
                        display: inline-block;
                        color: #fff;
                        margin-left: 5px;
                        @include mediaw(576px) {
                            font-size: 14px;
                            line-height: 15px;
                            width: 15px;
                        }
                    }
                }
            }
            &__next {
                display: inline-block;
                padding-left: 30px;
                text-align: left;
                position: relative;
                font-size: 16px;
                border-radius: 25px;
                @include mediaw(576px) {
                    padding-left: 21px;
                    font-size: 14px;
                    border-radius: 18px;
                }
                &::after {
                    content: " ";
                    display: block;
                    width: 10px;
                    height: 10px;
                    background-image: url(../img/elements/icon-arrow.png);
                    position: absolute;
                    right: 25px;
                    top: 50%;
                    transform: translateY(-50%);
                    @include mediaw(576px) {
                        width: 10px;
                        height: 10px;
                        right: 18px;
                    }
                }
            }
            &__bottom {
                padding: 20px 15px 20px 60px;
                width: 100%;
                position: absolute;
                bottom: 145px;
                left: 0;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                @include mediaw(992px) {
                    padding: 14px 11px 14px 42px;
                    bottom: 80px;
                }
                @include mediaw(576px) {
                    padding: 11px 11px 11px 29px;
                    bottom: 30px;
                }
            }
            //
            &__current {
                display: flex;
                align-items: center;
                span {
                    display: block;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #bdccbd;
                    margin: 0 9px;
                    &.current {
                        width: 50px;
                        height: 50px;
                        background-size: cover;
                        background-color: transparent;
                        position: relative;
                        @include mediaw(576px) {
                            width: 35px;
                            height: 35px;
                        }
                        &::after {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translateX(-50%) translateY(-50%);
                            font-family: $fontGilroyBold;
                            font-size: 18px;
                            line-height: 24px;
                            color: $colorGreen;
                            @include mediaw(576px) {
                                font-size: 14px;
                                line-height: 17px;
                            }
                        }
                    }
                    &.current:first-child {
                        background-image: url(../img/elements/quiz-progress1.png);
                        &::after {
                            content: "01";
                        }
                    }
                    &.current:nth-child(2) {
                        background-image: url(../img/elements/quiz-progress2.png);
                        &::after {
                            content: "02";
                        }
                    }
                    &.current:nth-child(3) {
                        background-image: url(../img/elements/quiz-progress3.png);
                        &::after {
                            content: "03";
                        }
                    }
                }
            }
            &__form-text {
                font-family: $fontGilroyRegular;
                font-size: 21px;
                line-height: 30px;
                margin: 45px 0;
            }
            //
            .quiz__form--overlay {
                display: flex;
                @include mediaw(576px) {
                    flex-wrap: wrap;
                }
                label {
                    display: inline-block;
                    position: relative;
                    @include mediaw(1199px) {
                        width: 50% !important;
                    }
                    @include mediaw(576px) {
                        width: 100% !important;
                    }
                    span {
                        position: absolute;
                        top: -50%;
                        transform: translateY(calc(50% - -6px));
                        left: 35px;
                        display: inline-block;
                        font-family: $fontGilroyRegular;
                        font-size: 14px;
                        line-height: 24px;
                        color: #222321;
                        background-color: #f3f8f0;
                        padding: 0 10px;
                        @include mediaw(576px) {
                            transform: translateY(50%);
                        }
                    }
                    input:not([type="submit"]) {
                        display: block;
                        width: 418px;
                        max-width: 100%;
                        height: 64px;
                        border-radius: 32px;
                        border: 1px solid #c8c8c7;
                        font-family: $fontGilroyBold;
                        font-size: 18px;
                        line-height: 24px;
                        color: #222321;
                        padding: 0 40px;
                        background-repeat: no-repeat;
                        background-position: 20px center;
                        background-color: #f3f8f0;
                        @include mediaw(576px) {
                            height: 45px;
                            width: 100%;
                            font-size: 15px;
                            line-height: 17px;
                            padding: 0 33px;
                            background-position: 14px center;
                        }
                    }
                    &:first-child {
                        width: 285px;
                        max-width: 100%;
                        input {
                            background-image: url(../img/elements/icon-user.png);
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                            border-right: none;
                            @include mediaw(576px) {
                                border-radius: 22.5px;
                            }
                        }
                    }
                    &:nth-child(2) {
                        width: 355px;
                        @include mediaw(576px) {
                            margin-top: 20px;
                        }
                        input:not([type="submit"]) {
                            background-image: url(../img/elements/icon-telephone.png);
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                            width: 100%;
                            @include mediaw(576px) {
                                border-radius: 22.5px;
                            }
                        }
                    }
                    input[type="submit"] {
                        display: block;
                    }
                }
            }
            .quiz__form-input {
                display: inline-block;
                position: relative;
                margin-top: 33px;
                @include mediaw(576px) {
                    margin-top: 23px;
                }
                &::after {
                    content: " ";
                    display: block;
                    width: 10px;
                    height: 10px;
                    background-image: url(../img/elements/icon-arrow.png);
                    position: absolute;
                    right: 25px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                input {
                    display: block;
                    font-size: 16px;
                    height: 48px;
                    width: 268px;
                    border-radius: 24px;
                    text-align: left;
                    padding-left: 40px;
                }
            }
        }
    }
}

.catalog {
    background-image: url(../img/elements/background-catalog.png);
    background-repeat: no-repeat;
    background-position: center top;
    position: relative;
    padding: 135px 0 150px;
    @include mediaw(992px) {
        padding: 95px 0 105px;
    }
    @include mediaw(576px) {
        padding: 30px 0 50px;
    }
    &::before {
        content: " ";
        display: block;
        width: 100%;
        height: 633px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(97, 133, 98,1) 0%, rgba(56, 95, 57,1) 100%);
        top: 0;
        left: 0;
        z-index: -1;
    }
    &__title--overlay {
        display: flex;
        justify-content: center;
    }
    &__title {
        font-family: $fontGilroyBold;
        font-size: 36px;
        line-height: 48px;
        color: #fff;
        position: relative;
            &::before {
                content: " ";
                display: block;
                width: 58px;
                height: 4px;
                background-color: #fff;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -85px;
                @include mediaw(576px) {
                    display: none;
                }
            }
            &::after {
                content: " ";
                display: block;
                width: 58px;
                height: 4px;
                background-color: #fff;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -85px;
                @include mediaw(576px) {
                    display: none;
                }
            }
        @include mediaw(576px) {
            font-size: 25px;
            text-align: center;
        }
        span {
            padding: 0 9px;
            background-color: #fff;
            color: $colorGreen;
        }
    }
    &__row {
        display: flex;
        flex-wrap: wrap;
        margin-top: 56px;
        @include mediaw(992px) {
            flex-wrap: wrap;
        }
        @include mediaw(576px) {
            margin-top: initial;
        }
    }
    &__item {
        width: calc(50% - 15px);
        background-color: #fff;
        box-shadow: 0px 15px 125px 0px rgba(30,31,34,0.1);
        font-family: $fontGilroyBold;
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 40px;
        margin-top: 30px;
        @include mediaw(992px) {
            width: 100%;
        }
        @include mediaw(576px) {
            font-size: 14px;
            line-height: 17px;
            padding-bottom: 28px;
            margin-top: 21px;
        }
        &:nth-child(2n) {
            margin-left: 30px;
            @include mediaw(992px) {
                margin-left: initial;
            }
        }
        &.top-sale {
            position: relative;
            &::before {
                content: "Топ продаж";
                position: absolute;
                top: 15px;
                left: 15px;
                padding: 0 20px;
                font-size: 24px;
                line-height: 43px;
                font-family: $fontGilroyBold;
                background-color: $colorLightGreen;
                color: #fff;
                z-index: 1;
                @include mediaw(576px) {
                    top: 11px;
                    left: 11px;
                    padding: 0 14px;
                    font-size: 17px;
                    line-height: 30px;
                }
            }
        }
        &.brand_of_year {
            position: relative;
            &::after {
                content: " ";
                display: block;
                width: 79px;
                height: 79px;
                background-color: #fff;
                border-radius: 0 0 3px 39px;
                position: absolute;
                top: 0;
                right: 0;
                background-image: url(../img/elements/brand_year.png);
                background-size: 60px;
                background-repeat: no-repeat;
                background-position: center center;
                @include mediaw(576px) {
                    width: 55px;
                    height: 55px;
                    background-size: 42px;
                    border-radius: 0 0 3px 27px;
                }
            }
        }
        &-slider {
            margin: 15px 15px 0 15px !important;
            height: 350px;
            overflow: hidden;
            cursor: pointer;
            @include mediaw(576px) {
                margin: 11px 11px 0 11px !important;
                height: 245px;
            }
            .slick-list, .slick-track {
                height: 100%;
            }
        }
        &-slide {
            display: block;
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .slick-arrow {
            background-size: cover;
            width: 15px;
            height: 28px;
            z-index: 1;
            opacity: 0.75;
            transition: opacity .3s;
            @include mediaw(576px) {
                width: 11px;
                height: 20px;
            }
            &::before {
                content: " ";
                display: block;
                width: 117px;
                height: 180px;
                border-radius: 50%;
                background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
                position: absolute;
                top: -80px;
                left: -30px;
                @include mediaw(576px) {
                    width: 82px;
                    height: 126px;
                    top: -56px;
                    left: -21px;
                }
            }
            &:hover {
                opacity: 1;
                &::before {
                    opacity: 0.75;
                }
            }
            &.slick-prev {
                left: 20px;
                background-image: url(../img/elements/slider-prev.png);
                @include mediaw(576px) {
                    left: 14px;
                }
                &::before {
                    left: initial;
                    right: -30px;
                    background: linear-gradient(to right, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
                    @include mediaw(576px) {
                        right: -21px;
                    }
                }
            }
            &:focus::before {
                opacity: 0.75;
            }
            &.slick-next {
                right: 20px;
                background-image: url(../img/elements/slider-next.png);
                @include mediaw(576px) {
                    right: 14px;
                }
            }
        }
        .slick-dots {
            bottom: 13px;
            @include mediaw(576px) {
                bottom: 11px;
            }
            &::before {
                content: " ";
                display: block;
                height: 117px;
                width: 209px;
                background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
                border-radius: 50%;
                position: absolute;
                top: -15px;
                left: 50%;
                transform: translateX(-50%);
                @include mediaw(576px) {
                    height: 82px;
                    width: 146px;
                    top: -11px;
                }
            }
            li {
                margin: 0 1px;
                button {
                    &::before {
                        color: #fff;
                        opacity: 1;
                    }
                }
                &.slick-active button::before {
                    opacity: 1;
                    color: #48916f;
                }
            }
        }
        &-title {
            font-size: 26px;
            line-height: 33px;
            padding: 0 40px;
            margin-top: 20px;
            @include mediaw(576px) {
                font-size: 18px;
                line-height: 23px;
                padding: 0 28px;
                margin-top: 14px;
            }
            span {
                color: $colorGreen;
            }
        }
        &-characteristics {
            margin-top: 15px;
            display: flex;
            @include mediaw(576px) {
                margin-top: 11px;
                display: block;
            }
            span {
                color: $colorGreen;
            }
            .left {
                width: 215px;
                padding-left: 70px;
                background-repeat: no-repeat;
                background-image: url(../img/elements/background-sizes.png);
                background-position: 25px 5px;
                @include mediaw(576px) {
                    width: 151px;
                    padding-left: 49px;
                    background-position: 15px 5px;
                }
            }
            .right {
                margin-left: 30px;
                @include mediaw(576px) {
                    margin-left: initial;
                    padding-left: 49px;
                }
            }
        }
        ul.catalog__item-characteristics {
            margin-top: 20px;
            display: block;
            padding: 0 35px 0 70px;
            background-repeat: no-repeat;
            background-image: url(../img/elements/background-sizes.png);
            background-position: 25px 5px;
            @include mediaw(576px) {
                margin-top: 14px;
                padding: 0 25px 0 49px;
                background-position: 15px 5px;
            }
            span {
                color: $colorGreen;
            }
        }
        &-delivery-and-installment {
            margin-top: 30px;
            display: flex;
            color: #fff;
            font-family: $fontGilroySemibold;
            font-size: 16px;
            @include mediaw(576px) {
                margin-top: 21px;
                font-size: 14px;
                flex-wrap: wrap;
            }
        }
        &-delivery, &-installment {
            padding: 20px 85px 20px 100px;
            background-repeat: no-repeat;
            position: relative;
            width: 50%;
            @include mediaw(1199px) {
                padding: 20px 36px 20px 100px;
            }
            @include mediaw(576px) {
                padding: 14px 60px 14px 70px;
                width: 100%;
            }
            &::after {
                content: " ";
                display: block;
                position: absolute;
                top: 50%;
                left: 40px;
                transform: translateY(-50%);
                background-size: cover;
                @include mediaw(576px) {
                    left: 28px;
                }
            }
        }
        &-delivery {
            background: linear-gradient(to bottom, rgba(48,130,90,1) 0%, rgba(10,99,55,1) 100%);
            box-shadow: inset 0px -1px 5px 0px rgba(0,0,0,0.23);
            &::after {
                background-image: url(../img/elements/icon-delivery-truck.png);
                width: 41px;
                height: 28px;
                @include mediaw(576px) {
                    width: 29px;
                    height: 20px;
                }
            }
        }
        &-installment {
            background-color: $colorLightGreen;
            &::after {
                background-image: url(../img/elements/icon-installment.png);
                width: 30px;
                height: 29px;
                @include mediaw(576px) {
                    width: 21px;
                    height: 20px;
                }
            }
        }
        &-form {
            .title {
                display: inline-block;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                background-color: $colorLightGreen;
                color: #fff;
                font-family: $fontGilroyBold;
                font-size: 18px;
                line-height: 30px;
                padding: 0 8px;
                margin: 40px 0 20px;
                @include mediaw(576px) {
                    font-size: 14px;
                    line-height: 21px;
                    margin: 28px 0 14px;
                    text-align: center;
                }
            }
            form {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                ul {
                    display: flex;
                    margin-top: 15px;
                    @include mediaw(576px) {
                        margin-top: 11px;
                    }
                    li {
                        cursor: pointer;
                        width: 32px;
                        height: 32px;
                        border: 2px solid #b8cab8;
                        text-align: center;
                        line-height: 28px;
                        border-radius: 50%;
                        margin: 0 6px;
                        @include mediaw(576px) {
                            width: 22px;
                            height: 22px;
                            line-height: 20px;
                        }
                        &.active {
                            color: #fff;
                            background: linear-gradient(to bottom, rgba(94,130,95,1) 0%, rgba(50,91,51,1) 100%);
                            border: 2px solid transparent;
                        }
                        &:hover {
                            border: 2px solid $colorGreen;
                        }
                    }
                }
                label {
                    margin-top: 45px;
                    display: inline-block;
                    position: relative;
                    @include mediaw(576px) {
                        width: 100%;
                        padding: 0 15px;
                        margin: 20px 0 0;
                    }
                    span {
                        position: absolute;
                        top: -50%;
                        transform: translateY(calc(50% - -3px));
                        left: 35px;
                        display: inline-block;
                        font-family: $fontGilroyRegular;
                        font-size: 14px;
                        line-height: 24px;
                        color: #222321;
                        background-color: #fff;
                        padding: 0 10px;
                        @include mediaw(576px) {
                            left: 25px;
                            line-height: 17px;
                            padding: 0 7px;
                            transform: translateY(calc(50% - -30px));
                        }
                    }
                    input:not([type="submit"]) {
                        display: block;
                        width: 418px;
                        max-width: 100%;
                        height: 56px;
                        border-radius: 28px;
                        border: 1px solid #c8c8c7;
                        font-family: $fontGilroyBold;
                        font-size: 18px;
                        line-height: 24px;
                        color: #222321;
                        padding: 0 40px;
                        background-image: url(../img/elements/icon-telephone.png);
                        background-repeat: no-repeat;
                        background-position: 20px center;
                        @include mediaw(576px) {
                            height: 39px;
                            font-size: 14px;
                            line-height: 17px;
                            padding: 0 28px;
                            background-position: 14px center;
                        }
                    }
                    .catalog__item-input {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 6px;
                        @include mediaw(576px) {
                            position: relative;
                            top: initial;
                            right: initial;
                            margin-top: 15px;
                            display: inline-block;
                            transform: initial;
                        }
                        &::after {
                            content: " ";
                            display: block;
                            width: 10px;
                            height: 10px;
                            background-image: url(../img/elements/icon-arrow.png);
                            position: absolute;
                            right: 25px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        input {
                            display: block;
                            font-size: 14px;
                            height: 42px;
                            width: 176px;
                            border-radius: 22px;
                            text-align: left;
                            padding: 0 0 0 25px;
                            @include mediaw(576px) {
                                margin: 0 auto;
                            }
                        }
                    }
                }
            }
        }
    }
}

.consultation {
    background-color: #f3f8f0;
    padding: 120px 0 150px;
    position: relative;
    @include mediaw(576px) {
        padding: 50px 0;
    }
    &::after {
        content: " ";
        display: block;
        width: 616px;
        height: 630px;
        position: absolute;
        background-image: url(../img/elements/background-consultation.png);
        background-size: cover;
        position: absolute;
        right: 0;
        bottom: 0;
        @include mediaw(1699px) {
            right: -100px;
        }
        @include mediaw(1599px) {
            right: -150px;
        }
        @include mediaw(1499px) {
            right: -200px;
        }
        @include mediaw(1399px) {
            right: -222px;
        }
        @include mediaw(1199px) {
            display: none;
        }
    }
    .section-title {
        max-width: 470px;
        line-height: 48px;
        position: relative;
        @include mediaw(576px) {
            line-height: 34px;
        }
        &::after {
            content: " ";
            display: block;
            width: 58px;
            height: 4px;
            background-color: $colorLightGreen;
            position: absolute;
            left: 367px;
            bottom: 22px;
            @include mediaw(576px) {
                display: none;
            }
        }
    }
    .container {
        position: relative;
        .quote {
            position: absolute;
            right: 98px;
            top: 0;
            text-align: right;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            z-index: 1;
            @include mediaw(1350px) {
                right: 150px;
            }
            @include mediaw(1250px) {
                right: 200px;
            }
            @include mediaw(1199px) {
                right: 0;
                position: initial;
                margin-bottom: 40px;
            }
            @include mediaw(576px) {
                display: block;
            }
            span {
                display: block;
                font-family: $fontGilroyBold;
                font-size: 18px;
                line-height: 36px;
                color: #121621;
                padding: 0 15px;
                background-color: #fff;
                @include mediaw(576px) {
                    font-size: 14px;
                    line-height: 21px;
                    text-align: left;
                    background-color: transparent;
                    padding: 0;
                }
                &:first-child {
                    margin-bottom: 5px;
                    @include mediaw(576px) {
                        margin-bottom: initial;
                    }
                }
            }
        }
    }
    &__description {
        font-size: 21px;
        line-height: 30px;
        font-family: $fontGilroyRegular;
        color: #222321;
        margin: 50px 0;
        @include mediaw(576px) {
            font-size: 14px;
            line-height: 21px;
            margin: 35px 0;
        }
    }
    &__form {
        display: flex;
        @include mediaw(768px) {
            flex-wrap: wrap;
        }
        label {
            display: inline-block;
            position: relative;
            @include mediaw(768px) {
                width: 100%;
            }
            span {
                position: absolute;
                top: -50%;
                transform: translateY(calc(50% - -3px));
                left: 35px;
                display: inline-block;
                font-family: $fontGilroyRegular;
                font-size: 14px;
                line-height: 24px;
                color: #222321;
                background-color: #f3f8f0;
                padding: 0 10px;
            }
            input:not([type="submit"]) {
                display: block;
                width: 418px;
                max-width: 100%;
                height: 64px;
                border-radius: 32px;
                border: 1px solid #c8c8c7;
                font-family: $fontGilroyBold;
                font-size: 18px;
                line-height: 24px;
                color: #222321;
                padding: 0 40px;
                background-repeat: no-repeat;
                background-position: 20px center;
                background-color: #f3f8f0;
                @include mediaw(768px) {
                    height: 45px;
                    font-size: 15px;
                    line-height: 17px;
                    background-position: 14px center;
                    border-radius: 22.5px;
                    width: 100%;
                }
            }
            &:first-child {
                width: 225px;
                max-width: 100%;
                @include mediaw(768px) {
                    width: 100%;
                }
                input {
                    background-image: url(../img/elements/icon-user.png);
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-right: none;
                    @include mediaw(768px) {
                        border-right: 1px solid #c8c8c7;
                        border-radius: 22.5px;
                    }
                }
                span {
                    @include mediaw(768px) {
                        transform: translateY(calc(50% - 2px));
                    }
                }
            }
            &:nth-child(2) {
                width: 570px;
                max-width: 100%;
                @include mediaw(768px) {
                    margin-top: 15px;
                }
                input:not([type="submit"]) {
                    background-image: url(../img/elements/icon-telephone.png);
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    width: 100%;
                    @include mediaw(768px) {
                        border-radius: 22.5px;
                    }
                }
                span {
                    @include mediaw(768px) {
                        top: -12px;
                        transform: initial;
                    }
                }
            }
            input[type="submit"] {
                display: block;
            }
        }
        &-input {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 8px;
            @include mediaw(768px) {
                position: relative;
                top: initial;
                right: initial;
                left: 50%;
                transform: translateX(-50%);
                margin-top: 15px;
                display: inline-block;
            }
            &::after {
                content: " ";
                display: block;
                width: 10px;
                height: 10px;
                background-image: url(../img/elements/icon-arrow.png);
                position: absolute;
                right: 25px;
                top: 50%;
                transform: translateY(-50%);
            }
            input {
                display: block;
                font-size: 16px;
                height: 48px;
                width: 268px;
                border-radius: 24px;
                text-align: left;
                padding: 0 0 0 40px;
            }
        }
    }
}

.reviews {
    padding: 120px 0 150px;
    background-image: url(../img/elements/background-reviews.png);
    background-size: cover;
    background-color: #fdfdfd;
    overflow: hidden;
    @include mediaw(576px) {
        padding: 60px 0 75px;
    }
    .section-title {
        max-width: 320px;
        line-height: 48px;
        position: relative;
        @include mediaw(576px) {
            line-height: 34px;
        }
        &::after {
            content: " ";
            display: block;
            width: 58px;
            height: 4px;
            background-color: $colorLightGreen;
            position: relative;
            bottom: -39px;
            left: 0;
        }
    }
    &__slider--overlay {
        margin-top: 105px;
        width: 470px;
        margin-left: auto;
        position: relative;
        display: flex;
        align-items: flex-end;
        @include mediaw(1199px) {
            margin-top: 275px;
        }
        @include mediaw(992px) {
            transform: translateX(32px);
            margin-top: 325px;
        }
        @include mediaw(768px) {
            margin-top: 75px;
            transform: initial;
            display: block;
            width: initial;
            margin-left: 40px;
        }
        .reviews__slider-content {
            position: absolute;
            width: 670px;
            left: -302px;
            top: -260px;
            @include mediaw(992px) {
                left: -282px;
            }
            @include mediaw(768px) {
                position: static;
                width: 100%;
            }
            .slick-list {
                @include mediaw(768px) {
                    overflow: unset;
                }
            }
            &-slide {
                @include mediaw(768px) {
                    box-shadow: 0px 10px 150px 0px rgba(54,100,55,0.05);
                }
            }
            .text {
                background-color: #fff;
                font-family: $fontGilroySemibold;
                font-size: 16px;
                line-height: 27px;
                color: #222321;
                box-shadow: 0px 10px 150px 0px rgba(54,100,55,0.05);
                padding: 45px 60px 60px;
                height: 240px;
                @include mediaw(992px) {
                    padding: 30px;
                }
                @include mediaw(768px) {
                    height: initial;
                    width: 100%;
                    box-shadow: none;
                }
                @include mediaw(576px) {
                    font-size: 14px;
                    line-height: 19px;
                    padding: 21px;
                }
            }
            .author {
                background-color: #fff;
                line-height: 26px;
                display: flex;
                align-items: center;
                box-shadow: 0px 10px 150px 0px rgba(54,100,55,0.05);
                position: relative;
                padding: 0 0 60px 60px;
                max-width: 337px;
                @include mediaw(992px) {
                    padding: 0 0 30px 30px;
                    max-width: 315px;
                }
                @include mediaw(768px) {
                    max-width: initial;
                    box-shadow: none;
                }
                @include mediaw(576px) {
                    line-height: 18px;
                    padding: 0 0 21px 21px;
                }
                &::after {
                    content: " ";
                    display: block;
                    width: 100%;
                    height: 40px;
                    background-color: #fff;
                    position: absolute;
                    top: -40px;
                    left: 0;
                    @include mediaw(768px) {
                        display: none;
                    }
                }
                .image-block {
                    width: 63px;
                    height: 63px;
                    border-radius: 50%;
                    border: 1px solid #ccd8cc;
                    padding: 8px;
                    background-color: #fff;
                    margin-right: 20px;
                    img {
                        border-radius: 50%;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .name {
                    font-family: $fontGilroyBold;
                    font-size: 14px;
                    color: #9abc3a;
                    text-transform: uppercase;
                }
                .where {
                    font-family: $fontGilroySemibold;
                    font-size: 14px;
                    color: #b7c4b7;
                }
            }
        }
    }
    &__slider {
        position: relative;
        width: 415px;
        height: 245px;
        z-index: 1;
    }
    &__slider-buttons {
        position: relative;
        @include mediaw(768px) {
            position: absolute;
            bottom: 0;
            left: -55px;
        }
        &::after {
            content: " ";
            display: block;
            width: 35px;
            height: 1px;
            background-color: #595a59;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%); 
            transition: opacity .3s;
        }
        &:hover::after {
            opacity: 0;
        }
        .reviews__slider-prev, .reviews__slider-next {
            background-position: center;
            background-repeat: no-repeat;
            background-size: 7px 11px;
            width: 55px;
            height: 60px;
            background-color: #222321;
            cursor: pointer;
            transition: background-color .3s;
            display: block;
            &:hover {
                background-color: $colorLightGreen;
            }
        }
        .reviews__slider-prev {
            background-image: url(../img/elements/slider-prev.png);
        }
        .reviews__slider-next {
            background-image: url(../img/elements/slider-next.png);
        }
    }
    &__slide {
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
        position: relative;
        &::before {
            content: " ";
            display: block;
            width: 100%;
            height: 100%;
            background-color: $colorGreen;
            opacity: 0.4;
            transition: opacity .3s;
            position: absolute;
            top: 0;
            left: 0;
        }
        &:hover::before {
            opacity: 0.75;
        }
        &::after {
            content: " ";
            display: block;
            width: 50px;
            height: 50px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            background-image: url(../img/elements/icon-play-button.png);
            background-size: cover;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .left {
        position: relative;
        @include mediaw(992px) {
            padding-right: 75px;
        }
        &::after {
            content: "’’";
            position: absolute;
            bottom: -265px;
            left: -7px;
            color: #222321;
            font-family: $fontAthelasBold;
            font-size: 165px;
            line-height: 32px;
            letter-spacing: -.08em;
            @include mediaw(992px) {
                bottom: 50%;
                right: 29px;
                left: initial;
                transform: translateY(calc(50% + 60px));
            }
        }
        &::before {
            content: " ";
            display: block;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: #9abc3a;
            position: absolute;
            bottom: -215px;
            left: 10px;
            @include mediaw(992px) {
                bottom: 50%;
                left: initial;
                right: 0;
                transform: translateY(50%);
            }
        }
    }
    .slick-dots {
        bottom: -24px;
        display: flex;
        align-items: center;
        @include mediaw(768px) {
            bottom: 250px;
        }
        li {
            margin: 0 1px;
            button {
                &::before {
                    color: #a7b8a7;
                    opacity: 1;
                }
            }
            &.slick-active button::before {
                opacity: 1;
                color: $colorGreen;
                font-size: 10px;
                position: relative;
                top: -4px;
            }
        }
    }
}

.photo {
    background-color: #fff;
    padding: 120px 0 215px;
    overflow: hidden;
    @include mediaw(576px) {
        padding: 60px 0 100px;
    }
    .section-title {
        line-height: 48px;
        max-width: 405px;
        text-align: center;
        margin: 0 auto;
        position: relative;
        @include mediaw(576px) {
            line-height: 34px;
        }
        &::after {
            content: " ";
            display: block;
            width: 58px;
            height: 4px;
            background-color: $colorLightGreen;
            position: relative;
            bottom: -35px;
            left: 50%;
            transform: translateX(-50%);
            @include mediaw(576px) {
                bottom: -25px;
            }
        }
    }
    &__slider--overlay {
        position: relative;
        &::before, &::after {
            content: " ";
            display: block;
            width: 275px;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 1;
            @include mediaw(1199px) {
                display: none;
            }
        }
        &::before {
            left: 0;
            background: linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
        }
        &::after {
            right: 0;
            background: linear-gradient(to left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
        }
    }
    &__slider-buttons {
        position: absolute;
        top: 50%;
        left: -50px;
        transform: translateY(-50%);
        z-index: 2;
        display: flex;
        justify-content: space-between;
        width: calc(100% + 100px);
        .photo__slider-prev, .photo__slider-next {
            width: 105px;
            height: 105px;
            cursor: pointer;
            border-radius: 50%;
            background: linear-gradient(to bottom,#30825a 0%,#0a6337 100%);
            box-shadow: inset 0 -1px 5px 0 rgba(0,0,0,.23);
            position: relative;
            @include mediaw(576px) {
                width: 74px;
                height: 74px;
            }
            &::after {
                content: " ";
                display: block;
                width: 12px;
                height: 20px;
                background-size: cover;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .photo__slider-prev::after {
            background-image: url(../img/elements/arrow-left-bold.png);
            right: 32px;
            @include mediaw(576px) {
                right: 8px;
            }
        }
        .photo__slider-next::after {
            background-image: url(../img/elements/arrow-right-bold.png);
            left: 32px;
            @include mediaw(576px) {
                left: 8px;
            }
        }
    }
    &__slider {
        margin: 135px auto 0;
        max-width: 970px;
        @include mediaw(1199px) {
            margin-left: 15px;
            margin-right: 15px;
        }
        @include mediaw(576px) {
            margin: 95px auto 0;
        }
        .slick-list {
            margin: 0 -14px;
            overflow: unset;
            @include mediaw(576px) {
                margin: 0 -10px;

            }
        }
    }
    &__slide {
        display: block;
        margin: 0 14px;
        height: 325px;
        @include mediaw(576px) {
            height: 250px;
            margin: 0 10px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .slick-dots {
        bottom: -72px;
        li {
            margin: 0 1px;
            button {
                &::before {
                    color: #a7b8a7;
                    opacity: 1;
                }
            }
            &.slick-active button::before {
                opacity: 1;
                color: $colorGreen;
                font-size: 10px;
                position: relative;
                top: 5px;
            }
        }
    }
}

.map {
    position: relative;
    background-color: #fff;
    @include mediaw(1800px) {
        display: flex;
    }
    @include mediaw(768px) {
        flex-direction: column-reverse;
        flex-wrap: wrap;
    }
    &__block {
        position: absolute;
        top: 0;
        left: 100px;
        width: 870px;
        height: 100%;
        @include mediaw(1800px) {
            left: 40px;
            position: static;
            width: 50%;
        }
        @include mediaw(768px) {
            width: 100%;
        }
        &::before, &::after {
            content: " ";
            display: block;
            width: 50%;
            height: 100%;
            position: absolute;
            top: 0;
            @include mediaw(1800px) {
                display: none;
            }
        }
        &::before {
            left: 0;
            background-color: #fff;
            @include mediaw(1800px) {
                display: none;
            }
        }
        &::after {
            right: 0;
            background: linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            @include mediaw(1800px) {
                display: none;
            }
        }
        &-content {
            position: relative;
            z-index: 1;
            max-width: 625px;
            padding: 145px 65px 150px;
            @include mediaw(992px) {
                padding: 102px 65px 105px;
            }
            @include mediaw(768px) {
                padding: 71px 46px 74px 75px;
            }
            @include mediaw(576px) {
                padding: 25px 15px 35px 45px;
            }
        }
    }
    iframe {
        width: 100%;
        height: 1040px;
        @include mediaw(1800px) {
            width: 50%;
        }
        @include mediaw(768px) {
            width: 100%;
            height: 400px;
        }
    }
    &__title {
        font-family: $fontGilroyBold;
        font-size: 36px;
        line-height: 60px;
        color: #222321;
        @include mediaw(576px) {
            font-size: 25px;
            line-height: 42px;
        }
        span {
            position: relative;
            &::after {
                content: " ";
                display: block;
                width: 58px;
                height: 4px;
                background-color: $colorLightGreen;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -80px;
                @include mediaw(576px) {
                    display: none;
                }
            }
        }
    }
    &__description {
        margin-top: 25px;
        font-family: $fontGilroySemibold;
        color: #1e1f22;
        font-size: 25px;
        @include mediaw(576px) {
            margin-top: 18px;
            font-size: 18px;
        }
    }
    &__item {
        &-title {
            font-family: $fontGilroyBold;
            font-size: 24px;
            line-height: 48px;
            color: #222;
            margin-top: 50px;
            position: relative;
            @include mediaw(576px) {
                font-size: 17px;
                line-height: 34px;
                margin-top: 35px;
            }
            &::before, &::after {
                content: " ";
                display: block;
                width: 65px;
                height: 65px;
                border-radius: 50%;
                background: linear-gradient(to bottom,#30825a 0%,#0a6337 100%);
                box-shadow: 0px 0px 119px 0px rgba(54,100,55,0.25);
                position: absolute;
                top: -8px;
                left: -97px;
                background-repeat: no-repeat;
                background-position: center center;
                @include mediaw(768px) {
                    width: 46px;
                    height: 46px;
                    top: -11px;
                    left: -68px;
                }
                @include mediaw(576px) {
                    width: 32px;
                    height: 32px;
                    left: -40px;
                    top: 0;
                }
            }
            &::after {
                @include mediaw(576px) {
                    background-size: 12px;
                }
            }
            &.locate::after {
                background-image: url(../img/elements/icon-locate.png);
            }
            &.phone::after {
                background-image: url(../img/elements/icon-phone-white.png);
            }
            span {
                display: block;
                font-family: $fontGilroySemibold;
                font-size: 21px;
                @include mediaw(576px) {
                    font-size: 15px;
                }
            }
        }
    }
    &__phones {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 18px;
        font-family: $fontGilroySemibold;
        @include mediaw(576px) {
            font-size: 15px;
            flex-wrap: wrap;
            justify-content: center;
        }
        &-code {
            color: $colorGreen;
        }
        ul {
            display: flex;
            margin-left: 15px;
            @include mediaw(576px) {
                margin-left: initial;
                display: block;
                width: 100%;
                text-align: center;
            }
            li {
                &:not(:first-child) {
                    margin-left: 25px;
                    position: relative;
                    @include mediaw(576px) {
                        margin-left: initial;
                    }
                    &::before {
                        content: "/";
                        position: absolute;
                        left: -17px;
                        @include mediaw(576px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    &__shedule {
        font-size: 18px;
        line-height: 24px;
        font-family: $fontGilroyRegular;
        color: #161915;
        background-repeat: no-repeat;
        background-image: url(../img/elements/icon-clock.png);
        background-position: left center;
        padding-left: 20px;
        @include mediaw(576px) {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            display: inline-block;
        }
        span {
            font-family: $fontGilroySemibold;
            display: inline;
        }
    }
    .btn {
        display: inline-block;
        margin-top: 45px;
        @include mediaw(576px) {
            margin-top: 32px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}